const countries = {
    "AF": {"name": "Afghanistan", "tel_code": "+93", "code": "AF"},
    "AX": {"name": "Åland Islands", "tel_code": "+358", "code": "AX"},
    "AL": {"name": "Albania", "tel_code": "+355", "code": "AL"},
    "DZ": {"name": "Algeria", "tel_code": "+213", "code": "DZ"},
    "AS": {"name": "American Samoa", "tel_code": "+1684", "code": "AS"},
    "AD": {"name": "Andorra", "tel_code": "+376", "code": "AD"},
    "AO": {"name": "Angola", "tel_code": "+244", "code": "AO"},
    "AI": {"name": "Anguilla", "tel_code": "+1264", "code": "AI"},
    "AQ": {"name": "Antarctica", "tel_code": "+672", "code": "AQ"},
    "AG": {"name": "Antigua and Barbuda", "tel_code": "+1268", "code": "AG"},
    "AR": {"name": "Argentina", "tel_code": "+54", "code": "AR"},
    "AM": {"name": "Armenia", "tel_code": "+374", "code": "AM"},
    "AW": {"name": "Aruba", "tel_code": "+297", "code": "AW"},
    "AU": {"name": "Australia", "tel_code": "+61", "code": "AU"},
    "AT": {"name": "Austria", "tel_code": "+43", "code": "AT"},
    "AZ": {"name": "Azerbaijan", "tel_code": "+994", "code": "AZ"},
    "BS": {"name": "Bahamas", "tel_code": "+1242", "code": "BS"},
    "BH": {"name": "Bahrain", "tel_code": "+973", "code": "BH"},
    "BD": {"name": "Bangladesh", "tel_code": "+880", "code": "BD"},
    "BB": {"name": "Barbados", "tel_code": "+1246", "code": "BB"},
    "BY": {"name": "Belarus", "tel_code": "+375", "code": "BY"},
    "BE": {"name": "Belgium", "tel_code": "+32", "code": "BE"},
    "BZ": {"name": "Belize", "tel_code": "+501", "code": "BZ"},
    "BJ": {"name": "Benin", "tel_code": "+229", "code": "BJ"},
    "BM": {"name": "Bermuda", "tel_code": "+1441", "code": "BM"},
    "BT": {"name": "Bhutan", "tel_code": "+975", "code": "BT"},
    "BO": {"name": "Bolivia, Plurinational State of bolivia", "tel_code": "+591", "code": "BO"},
    "BA": {"name": "Bosnia and Herzegovina", "tel_code": "+387", "code": "BA"},
    "BW": {"name": "Botswana", "tel_code": "+267", "code": "BW"},
    "BV": {"name": "Bouvet Island", "tel_code": "+47", "code": "BV"},
    "BR": {"name": "Brazil", "tel_code": "+55", "code": "BR"},
    "IO": {"name": "British Indian Ocean Territory", "tel_code": "+246", "code": "IO"},
    "BN": {"name": "Brunei Darussalam", "tel_code": "+673", "code": "BN"},
    "BG": {"name": "Bulgaria", "tel_code": "+359", "code": "BG"},
    "BF": {"name": "Burkina Faso", "tel_code": "+226", "code": "BF"},
    "BI": {"name": "Burundi", "tel_code": "+257", "code": "BI"},
    "KH": {"name": "Cambodia", "tel_code": "+855", "code": "KH"},
    "CM": {"name": "Cameroon", "tel_code": "+237", "code": "CM"},
    "CA": {"name": "Canada", "tel_code": "+1", "code": "CA"},
    "CV": {"name": "Cape Verde", "tel_code": "+238", "code": "CV"},
    "KY": {"name": "Cayman Islands", "tel_code": "+ 345", "code": "KY"},
    "CF": {"name": "Central African Republic", "tel_code": "+236", "code": "CF"},
    "TD": {"name": "Chad", "tel_code": "+235", "code": "TD"},
    "CL": {"name": "Chile", "tel_code": "+56", "code": "CL"},
    "CN": {"name": "China", "tel_code": "+86", "code": "CN"},
    "CX": {"name": "Christmas Island", "tel_code": "+61", "code": "CX"},
    "CC": {"name": "Cocos (Keeling) Islands", "tel_code": "+61", "code": "CC"},
    "CO": {"name": "Colombia", "tel_code": "+57", "code": "CO"},
    "KM": {"name": "Comoros", "tel_code": "+269", "code": "KM"},
    "CG": {"name": "Congo", "tel_code": "+242", "code": "CG"},
    "CD": {"name": "Congo, The Democratic Republic of the Congo", "tel_code": "+243", "code": "CD"},
    "CK": {"name": "Cook Islands", "tel_code": "+682", "code": "CK"},
    "CR": {"name": "Costa Rica", "tel_code": "+506", "code": "CR"},
    "CI": {"name": "Cote d'Ivoire", "tel_code": "+225", "code": "CI"},
    "HR": {"name": "Croatia", "tel_code": "+385", "code": "HR"},
    "CU": {"name": "Cuba", "tel_code": "+53", "code": "CU"},
    "CY": {"name": "Cyprus", "tel_code": "+357", "code": "CY"},
    "CZ": {"name": "Czech Republic", "tel_code": "+420", "code": "CZ"},
    "DK": {"name": "Denmark", "tel_code": "+45", "code": "DK"},
    "DJ": {"name": "Djibouti", "tel_code": "+253", "code": "DJ"},
    "DM": {"name": "Dominica", "tel_code": "+1767", "code": "DM"},
    "DO": {"name": "Dominican Republic", "tel_code": "+1849", "code": "DO"},
    "EC": {"name": "Ecuador", "tel_code": "+593", "code": "EC"},
    "EG": {"name": "Egypt", "tel_code": "+20", "code": "EG"},
    "SV": {"name": "El Salvador", "tel_code": "+503", "code": "SV"},
    "GQ": {"name": "Equatorial Guinea", "tel_code": "+240", "code": "GQ"},
    "ER": {"name": "Eritrea", "tel_code": "+291", "code": "ER"},
    "EE": {"name": "Estonia", "tel_code": "+372", "code": "EE"},
    "ET": {"name": "Ethiopia", "tel_code": "+251", "code": "ET"},
    "FK": {"name": "Falkland Islands (Malvinas)", "tel_code": "+500", "code": "FK"},
    "FO": {"name": "Faroe Islands", "tel_code": "+298", "code": "FO"},
    "FJ": {"name": "Fiji", "tel_code": "+679", "code": "FJ"},
    "FI": {"name": "Finland", "tel_code": "+358", "code": "FI"},
    "FR": {"name": "France", "tel_code": "+33", "code": "FR"},
    "GF": {"name": "French Guiana", "tel_code": "+594", "code": "GF"},
    "PF": {"name": "French Polynesia", "tel_code": "+689", "code": "PF"},
    "TF": {"name": "French Southern Territories", "tel_code": "+262", "code": "TF"},
    "GA": {"name": "Gabon", "tel_code": "+241", "code": "GA"},
    "GM": {"name": "Gambia", "tel_code": "+220", "code": "GM"},
    "GE": {"name": "Georgia", "tel_code": "+995", "code": "GE"},
    "DE": {"name": "Germany", "tel_code": "+49", "code": "DE"},
    "GH": {"name": "Ghana", "tel_code": "+233", "code": "GH"},
    "GI": {"name": "Gibraltar", "tel_code": "+350", "code": "GI"},
    "GR": {"name": "Greece", "tel_code": "+30", "code": "GR"},
    "GL": {"name": "Greenland", "tel_code": "+299", "code": "GL"},
    "GD": {"name": "Grenada", "tel_code": "+1473", "code": "GD"},
    "GP": {"name": "Guadeloupe", "tel_code": "+590", "code": "GP"},
    "GU": {"name": "Guam", "tel_code": "+1671", "code": "GU"},
    "GT": {"name": "Guatemala", "tel_code": "+502", "code": "GT"},
    "GG": {"name": "Guernsey", "tel_code": "+44", "code": "GG"},
    "GN": {"name": "Guinea", "tel_code": "+224", "code": "GN"},
    "GW": {"name": "Guinea-Bissau", "tel_code": "+245", "code": "GW"},
    "GY": {"name": "Guyana", "tel_code": "+592", "code": "GY"},
    "HT": {"name": "Haiti", "tel_code": "+509", "code": "HT"},
    "HM": {"name": "Heard Island and Mcdonald Islands", "tel_code": "+0", "code": "HM"},
    "VA": {"name": "Holy See (Vatican City State)", "tel_code": "+379", "code": "VA"},
    "HN": {"name": "Honduras", "tel_code": "+504", "code": "HN"},
    "HK": {"name": "Hong Kong", "tel_code": "+852", "code": "HK"},
    "HU": {"name": "Hungary", "tel_code": "+36", "code": "HU"},
    "IS": {"name": "Iceland", "tel_code": "+354", "code": "IS"},
    "IN": {"name": "India", "tel_code": "+91", "code": "IN"},
    "ID": {"name": "Indonesia", "tel_code": "+62", "code": "ID"},
    "IR": {"name": "Iran, Islamic Republic of Persian Gulf", "tel_code": "+98", "code": "IR"},
    "IQ": {"name": "Iraq", "tel_code": "+964", "code": "IQ"},
    "IE": {"name": "Ireland", "tel_code": "+353", "code": "IE"},
    "IM": {"name": "Isle of Man", "tel_code": "+44", "code": "IM"},
    "IL": {"name": "Israel", "tel_code": "+972", "code": "IL"},
    "IT": {"name": "Italy", "tel_code": "+39", "code": "IT"},
    "JM": {"name": "Jamaica", "tel_code": "+1876", "code": "JM"},
    "JP": {"name": "Japan", "tel_code": "+81", "code": "JP"},
    "JE": {"name": "Jersey", "tel_code": "+44", "code": "JE"},
    "JO": {"name": "Jordan", "tel_code": "+962", "code": "JO"},
    "KZ": {"name": "Kazakhstan", "tel_code": "+7", "code": "KZ"},
    "KE": {"name": "Kenya", "tel_code": "+254", "code": "KE"},
    "KI": {"name": "Kiribati", "tel_code": "+686", "code": "KI"},
    "KP": {"name": "Korea, Democratic People's Republic of Korea", "tel_code": "+850", "code": "KP"},
    "KR": {"name": "Korea, Republic of South Korea", "tel_code": "+82", "code": "KR"},
    "XK": {"name": "Kosovo", "tel_code": "+383", "code": "XK"},
    "KW": {"name": "Kuwait", "tel_code": "+965", "code": "KW"},
    "KG": {"name": "Kyrgyzstan", "tel_code": "+996", "code": "KG"},
    "LA": {"name": "Laos", "tel_code": "+856", "code": "LA"},
    "LV": {"name": "Latvia", "tel_code": "+371", "code": "LV"},
    "LB": {"name": "Lebanon", "tel_code": "+961", "code": "LB"},
    "LS": {"name": "Lesotho", "tel_code": "+266", "code": "LS"},
    "LR": {"name": "Liberia", "tel_code": "+231", "code": "LR"},
    "LY": {"name": "Libyan Arab Jamahiriya", "tel_code": "+218", "code": "LY"},
    "LI": {"name": "Liechtenstein", "tel_code": "+423", "code": "LI"},
    "LT": {"name": "Lithuania", "tel_code": "+370", "code": "LT"},
    "LU": {"name": "Luxembourg", "tel_code": "+352", "code": "LU"},
    "MO": {"name": "Macao", "tel_code": "+853", "code": "MO"},
    "MK": {"name": "Macedonia", "tel_code": "+389", "code": "MK"},
    "MG": {"name": "Madagascar", "tel_code": "+261", "code": "MG"},
    "MW": {"name": "Malawi", "tel_code": "+265", "code": "MW"},
    "MY": {"name": "Malaysia", "tel_code": "+60", "code": "MY"},
    "MV": {"name": "Maldives", "tel_code": "+960", "code": "MV"},
    "ML": {"name": "Mali", "tel_code": "+223", "code": "ML"},
    "MT": {"name": "Malta", "tel_code": "+356", "code": "MT"},
    "MH": {"name": "Marshall Islands", "tel_code": "+692", "code": "MH"},
    "MQ": {"name": "Martinique", "tel_code": "+596", "code": "MQ"},
    "MR": {"name": "Mauritania", "tel_code": "+222", "code": "MR"},
    "MU": {"name": "Mauritius", "tel_code": "+230", "code": "MU"},
    "YT": {"name": "Mayotte", "tel_code": "+262", "code": "YT"},
    "MX": {"name": "Mexico", "tel_code": "+52", "code": "MX"},
    "FM": {"name": "Micronesia, Federated States of Micronesia", "tel_code": "+691", "code": "FM"},
    "MD": {"name": "Moldova", "tel_code": "+373", "code": "MD"},
    "MC": {"name": "Monaco", "tel_code": "+377", "code": "MC"},
    "MN": {"name": "Mongolia", "tel_code": "+976", "code": "MN"},
    "ME": {"name": "Montenegro", "tel_code": "+382", "code": "ME"},
    "MS": {"name": "Montserrat", "tel_code": "+1664", "code": "MS"},
    "MA": {"name": "Morocco", "tel_code": "+212", "code": "MA"},
    "MZ": {"name": "Mozambique", "tel_code": "+258", "code": "MZ"},
    "MM": {"name": "Myanmar", "tel_code": "+95", "code": "MM"},
    "NA": {"name": "Namibia", "tel_code": "+264", "code": "NA"},
    "NR": {"name": "Nauru", "tel_code": "+674", "code": "NR"},
    "NP": {"name": "Nepal", "tel_code": "+977", "code": "NP"},
    "NL": {"name": "Netherlands", "tel_code": "+31", "code": "NL"},
    "AN": {"name": "Netherlands Antilles", "tel_code": "+599", "code": "AN"},
    "NC": {"name": "New Caledonia", "tel_code": "+687", "code": "NC"},
    "NZ": {"name": "New Zealand", "tel_code": "+64", "code": "NZ"},
    "NI": {"name": "Nicaragua", "tel_code": "+505", "code": "NI"},
    "NE": {"name": "Niger", "tel_code": "+227", "code": "NE"},
    "NG": {"name": "Nigeria", "tel_code": "+234", "code": "NG"},
    "NU": {"name": "Niue", "tel_code": "+683", "code": "NU"},
    "NF": {"name": "Norfolk Island", "tel_code": "+672", "code": "NF"},
    "MP": {"name": "Northern Mariana Islands", "tel_code": "+1670", "code": "MP"},
    "NO": {"name": "Norway", "tel_code": "+47", "code": "NO"},
    "OM": {"name": "Oman", "tel_code": "+968", "code": "OM"},
    "PK": {"name": "Pakistan", "tel_code": "+92", "code": "PK"},
    "PW": {"name": "Palau", "tel_code": "+680", "code": "PW"},
    "PS": {"name": "Palestinian Territory, Occupied", "tel_code": "+970", "code": "PS"},
    "PA": {"name": "Panama", "tel_code": "+507", "code": "PA"},
    "PG": {"name": "Papua New Guinea", "tel_code": "+675", "code": "PG"},
    "PY": {"name": "Paraguay", "tel_code": "+595", "code": "PY"},
    "PE": {"name": "Peru", "tel_code": "+51", "code": "PE"},
    "PH": {"name": "Philippines", "tel_code": "+63", "code": "PH"},
    "PN": {"name": "Pitcairn", "tel_code": "+64", "code": "PN"},
    "PL": {"name": "Poland", "tel_code": "+48", "code": "PL"},
    "PT": {"name": "Portugal", "tel_code": "+351", "code": "PT"},
    "PR": {"name": "Puerto Rico", "tel_code": "+1939", "code": "PR"},
    "QA": {"name": "Qatar", "tel_code": "+974", "code": "QA"},
    "RO": {"name": "Romania", "tel_code": "+40", "code": "RO"},
    "RU": {"name": "Russia", "tel_code": "+7", "code": "RU"},
    "RW": {"name": "Rwanda", "tel_code": "+250", "code": "RW"},
    "RE": {"name": "Reunion", "tel_code": "+262", "code": "RE"},
    "BL": {"name": "Saint Barthelemy", "tel_code": "+590", "code": "BL"},
    "SH": {"name": "Saint Helena, Ascension and Tristan Da Cunha", "tel_code": "+290", "code": "SH"},
    "KN": {"name": "Saint Kitts and Nevis", "tel_code": "+1869", "code": "KN"},
    "LC": {"name": "Saint Lucia", "tel_code": "+1758", "code": "LC"},
    "MF": {"name": "Saint Martin", "tel_code": "+590", "code": "MF"},
    "PM": {"name": "Saint Pierre and Miquelon", "tel_code": "+508", "code": "PM"},
    "VC": {"name": "Saint Vincent and the Grenadines", "tel_code": "+1784", "code": "VC"},
    "WS": {"name": "Samoa", "tel_code": "+685", "code": "WS"},
    "SM": {"name": "San Marino", "tel_code": "+378", "code": "SM"},
    "ST": {"name": "Sao Tome and Principe", "tel_code": "+239", "code": "ST"},
    "SA": {"name": "Saudi Arabia", "tel_code": "+966", "code": "SA"},
    "SN": {"name": "Senegal", "tel_code": "+221", "code": "SN"},
    "RS": {"name": "Serbia", "tel_code": "+381", "code": "RS"},
    "SC": {"name": "Seychelles", "tel_code": "+248", "code": "SC"},
    "SL": {"name": "Sierra Leone", "tel_code": "+232", "code": "SL"},
    "SG": {"name": "Singapore", "tel_code": "+65", "code": "SG"},
    "SK": {"name": "Slovakia", "tel_code": "+421", "code": "SK"},
    "SI": {"name": "Slovenija", "tel_code": "+386", "code": "SI", "vat_regex": "^(SI)?[0-9]{8}$", "enabled": true},
    "SB": {"name": "Solomon Islands", "tel_code": "+677", "code": "SB"},
    "SO": {"name": "Somalia", "tel_code": "+252", "code": "SO"},
    "ZA": {"name": "South Africa", "tel_code": "+27", "code": "ZA"},
    "SS": {"name": "South Sudan", "tel_code": "+211", "code": "SS"},
    "GS": {"name": "South Georgia and the South Sandwich Islands", "tel_code": "+500", "code": "GS"},
    "ES": {"name": "Spain", "tel_code": "+34", "code": "ES"},
    "LK": {"name": "Sri Lanka", "tel_code": "+94", "code": "LK"},
    "SD": {"name": "Sudan", "tel_code": "+249", "code": "SD"},
    "SR": {"name": "Suriname", "tel_code": "+597", "code": "SR"},
    "SJ": {"name": "Svalbard and Jan Mayen", "tel_code": "+47", "code": "SJ"},
    "SZ": {"name": "Swaziland", "tel_code": "+268", "code": "SZ"},
    "SE": {"name": "Sweden", "tel_code": "+46", "code": "SE"},
    "CH": {"name": "Switzerland", "tel_code": "+41", "code": "CH"},
    "SY": {"name": "Syrian Arab Republic", "tel_code": "+963", "code": "SY"},
    "TW": {"name": "Taiwan", "tel_code": "+886", "code": "TW"},
    "TJ": {"name": "Tajikistan", "tel_code": "+992", "code": "TJ"},
    "TZ": {"name": "Tanzania, United Republic of Tanzania", "tel_code": "+255", "code": "TZ"},
    "TH": {"name": "Thailand", "tel_code": "+66", "code": "TH"},
    "TL": {"name": "Timor-Leste", "tel_code": "+670", "code": "TL"},
    "TG": {"name": "Togo", "tel_code": "+228", "code": "TG"},
    "TK": {"name": "Tokelau", "tel_code": "+690", "code": "TK"},
    "TO": {"name": "Tonga", "tel_code": "+676", "code": "TO"},
    "TT": {"name": "Trinidad and Tobago", "tel_code": "+1868", "code": "TT"},
    "TN": {"name": "Tunisia", "tel_code": "+216", "code": "TN"},
    "TR": {"name": "Turkey", "tel_code": "+90", "code": "TR"},
    "TM": {"name": "Turkmenistan", "tel_code": "+993", "code": "TM"},
    "TC": {"name": "Turks and Caicos Islands", "tel_code": "+1649", "code": "TC"},
    "TV": {"name": "Tuvalu", "tel_code": "+688", "code": "TV"},
    "UG": {"name": "Uganda", "tel_code": "+256", "code": "UG"},
    "UA": {"name": "Ukraine", "tel_code": "+380", "code": "UA"},
    "AE": {"name": "United Arab Emirates", "tel_code": "+971", "code": "AE"},
    "GB": {"name": "United Kingdom", "tel_code": "+44", "code": "GB"},
    "US": {"name": "United States", "tel_code": "+1", "code": "US"},
    "UY": {"name": "Uruguay", "tel_code": "+598", "code": "UY"},
    "UZ": {"name": "Uzbekistan", "tel_code": "+998", "code": "UZ"},
    "VU": {"name": "Vanuatu", "tel_code": "+678", "code": "VU"},
    "VE": {"name": "Venezuela, Bolivarian Republic of Venezuela", "tel_code": "+58", "code": "VE"},
    "VN": {"name": "Vietnam", "tel_code": "+84", "code": "VN"},
    "VG": {"name": "Virgin Islands, British", "tel_code": "+1284", "code": "VG"},
    "VI": {"name": "Virgin Islands, U.S.", "tel_code": "+1340", "code": "VI"},
    "WF": {"name": "Wallis and Futuna", "tel_code": "+681", "code": "WF"},
    "YE": {"name": "Yemen", "tel_code": "+967", "code": "YE"},
    "ZM": {"name": "Zambia", "tel_code": "+260", "code": "ZM"},
    "ZW": {"name": "Zimbabwe", "tel_code": "+263", "code": "ZW"}
};

export default countries;