import React, {Component} from "react";
import {ReactComponent as Linkedin} from "../images/Linkedin.svg";
import {ReactComponent as Instagram} from "../images/Instagram.svg";
import {ReactComponent as Facebook} from "../images/Facebook.svg";
import Tutorial from "../components/Tutorial";
import {formatMoney} from "../components/utils";
import {connect} from "react-redux";
import {getLatestInvestments, getLatestUserInvestments} from "../actions/feeds";
import LineGraph from "../components/LineGraph";
import Chart from "react-google-charts";
import {getAccountBalance} from "../actions/accountBalance";
import {withTranslation} from "react-i18next";

const mapStateToProps = state => {
    return {
        earnedInterests: state.accountBalance.accountBalance.earnedInterests,
        availableFunds: state.accountBalance.accountBalance.availableFunds,
        totalBalance: state.accountBalance.accountBalance.totalBalance,
        earningsByInvestmentType: state.accountBalance.accountBalance.earningsByInvestmentType,
        roiByInvestmentType: state.accountBalance.accountBalance.roiByInvestmentType,
        netDeposits: state.accountBalance.accountBalance.netDeposits,
        deploymentRate: state.accountBalance.accountBalance.deploymentRate,
        roi: state.accountBalance.accountBalance.roi,
        investments: state.feeds.investments,
        userInvestments: state.feeds.userInvestments,
    };
};

class AppHome extends Component {
    state = {
        graph: "earnings"
    };

    render() {
        const {investments, userInvestments, earnedInterests, availableFunds, totalBalance, roi, t} = this.props,
            {graph} = this.state,
            {graphTitle, lineText, pieText, pieData} = this.handleSwitchGraph(graph);
        return (
            <div>
                <div className="graph-switch-wrapper">
                    <div className="graph-switch">
                        <div onClick={() => this.setState({graph: "earnings"})}
                             className={"graph-switch-item" + (graph === "earnings" ? " graph-switch-item--active" : "")}>
                            <div className="graph-switch-item__content">
                                <h6 className="graph-switch-item__title">
                                    {t('earningsTitle')}
                                </h6>
                                <span className="graph-switch-item__text">{formatMoney(earnedInterests)} €</span>
                            </div>
                        </div>
                        <div onClick={() => this.setState({graph: "roi"})}
                             className={"graph-switch-item" + (graph === "roi" ? " graph-switch-item--active" : "")}>
                            <div className="graph-switch-item__content">
                                <h6 className="graph-switch-item__title">
                                    {t('roiTitle')}
                                </h6>
                                <span className="graph-switch-item__text">{formatMoney(roi)} %</span>
                            </div>
                        </div>
                        <div onClick={() => this.setState({graph: "balance"})}
                             className={"graph-switch-item" + (graph === "balance" ? " graph-switch-item--active" : "")}>
                            <div className="graph-switch-item__content">
                                <h6 className="graph-switch-item__title">
                                    {t('totalBalanceTitle')}
                                </h6>
                                <span className="graph-switch-item__text">{formatMoney(totalBalance)}</span>
                            </div>
                        </div>
                        <div onClick={() => this.setState({graph: "availableFunds"})}
                             className={"graph-switch-item" + (graph === "availableFunds" ? " graph-switch-item--active" : "")}>
                            <div className="graph-switch-item__content">
                                <h6 className="graph-switch-item__title">
                                    {t('availableFundsTitle')}
                                </h6>
                                <span className="graph-switch-item__text">{formatMoney(availableFunds)}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mdc-layout-grid mdc-layout-grid--big-spacing">
                    <div className="mdc-layout-grid__inner">
                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone"
                            ref={"tut1"}>
                            <div className="headline">
                                <h2 className="headline__title">
                                    {t('dashboardMainGraphTitle')}
                                </h2>
                            </div>

                            <span className="line-graph__title">{graphTitle}</span>
                            <LineGraph graphType={graph} active/>
                            <div className="line-graph__info">
                                <span>{lineText}</span>
                            </div>
                        </div>

                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                            <div ref="content" className="pie-graph">
                                {/*<div ref="chartIcon" className="chart__protection">
                                    <div className="chart__protection-icon">

                                    </div>
                                </div>*/}

                                {pieData && <Chart
                                    width={315}
                                    height={315}
                                    chartType="PieChart"
                                    loader={<div/>}
                                    data={pieData}
                                    options={{
                                        pieHole: 0.5,
                                        legend: 'none',
                                        colors: ['#057E9D', '#a6ce38', '#91D8F7'],
                                        chartArea: {left: 16, top: 16, right: 16, bottom: 16},
                                        backgroundColor: {
                                            fill: 'transparent',
                                        },
                                        pieSliceBorderColor: 'transparent',
                                        pieStartAngle: 180,
                                        animation: {
                                            startup: true,
                                            duration: 500,
                                        },
                                    }}
                                />}

                                {/*{pieData ? (

                                ) : <div>...</div>}*/}
                            </div>
                            <div className="line-graph__info line-graph__info--centered">
                                <span>{pieText}</span>
                            </div>
                        </div>

                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 hide-on-mobile">
                            <h2 className="headline__title">{t('feedLastAdded')}</h2>
                            <div className="table-responsive table-responsive--adjusted">
                                <table ref="sortableTable" className="sortable sort table feed-table">
                                    <thead>
                                    <tr className="mdc-typography--body2 table__head-row">
                                        <th className="no-sort">{t('date')}</th>
                                        <th className="no-sort">{t('totalAmount')}</th>
                                        <th className="no-sort">{t('availableInvestmentAmount')}</th>
                                        <th className="no-sort">{t('roiPAInvestment')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(investments || []).map((obj, id) => {
                                        //const obj = investments[id];
                                        return (
                                            <tr className="mdc-typography--body1" key={id}>
                                                <td>{obj.dateStartFormatted}</td>
                                                <td>{formatMoney(obj.totalAmount)} €</td>
                                                <td>{formatMoney(obj.availableAmount)} €</td>
                                                <td>{formatMoney(obj.interestRate)} %</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 hide-on-mobile">
                            <h2 className="headline__title">{t('feedLastInvested')}</h2>

                            <div className="table-responsive table-responsive--adjusted">
                                <table ref="sortableTable" className="sortable sort table feed-table">
                                    <thead>
                                    <tr className="mdc-typography--body2 table__head-row">
                                        <th className="no-sort">{t('date')}</th>
                                        <th className="no-sort">{t('investedAmount')}</th>
                                        <th className="no-sort">{t('totalAmount')}</th>
                                        <th className="no-sort">{t('roiPA')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(userInvestments || []).map((obj, id) => {
                                        return (
                                            <tr className="mdc-typography--body1" key={id}>
                                                <td>{obj.dateStartFormatted}</td>
                                                <td>{formatMoney(obj.amount)} €</td>
                                                <td>{formatMoney(obj.investment.totalAmount)} €</td>
                                                <td>{formatMoney(obj.investment.interestRate)} %</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 show-on-mobile">
                            <div className="connect">
                                <div className="connect__heading">
                                    <span className="mdc-typography--body1 connect__title">{t('connectNow')}</span>
                                    <span
                                        className="mdc-typography--caption connect__subtitle">{t('visitUsOnSocialNetworks')}</span>
                                </div>
                                <div className="connect__actions">
                                    <a className="icon-button icon-button--dense icon-button--outlined icon-button--shaped-circle"
                                       href="https://www.linkedin.com/company/nekster/?viewAsMember=true"
                                       target="_blank"><Linkedin/></a>
                                    <a className="icon-button icon-button--dense icon-button--outlined icon-button--shaped-circle"
                                       href="https://www.instagram.com/nekster.eu/?hl=en"
                                       target="_blank"><Instagram/></a>
                                    <a className="icon-button icon-button--dense icon-button--outlined icon-button--shaped-circle"
                                       href="https://www.facebook.com/NeksterSmartInvestment"
                                       target="_blank"><Facebook/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tutorial ref={"tut"}/>
                </div>
            </div>
        )
    }

    componentDidMount() {
        /*this.refs.tut.start([
            {
                el: this.refs.tut1,
                title: "Total balance",
                text: "Some text about total balance graph",
            },
            {
                el: this.refs.tut2,
                title: "Available funds",
                text: "Some text about available funds graph",
            }
        ])*/

        /*const tablesort = require('tablesort');
        tablesort(this.refs.sortableTable, {});*/
        const {t} = this.props;

        this.props.dispatch(getAccountBalance());
        this.props.dispatch(getLatestInvestments());
        this.props.dispatch(getLatestUserInvestments());

        //this.handleSwitchGraph("earnings")()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /* if (this.props.earningsByInvestmentType) {
             // init pie graph
             if (!this.state.pieData) {
                 this.handleSwitchGraph("earnings")
             }
         }*/
    }

    handleSwitchGraph(graph) {
        const {earningsByInvestmentType, roiByInvestmentType = {}, deploymentRate = {}, earnedInterests, netDeposits, t} = this.props;

        let graphTitle, lineText, pieText, pieData;
        switch (graph) {
            case "earnings":
                graphTitle = t("earningsTitle");
                lineText = t("earningsLineText");
                pieText = t("earningsPieText");
                pieData = earningsByInvestmentType ? [
                    [
                        {type: 'string', label: ""},
                        {type: 'number', label: ""},
                        {type: 'string', role: 'tooltip'},
                    ],
                    [t("invoiceFactoring"), earningsByInvestmentType.invoiceFactoring, t("invoiceFactoring") + '\n' + formatMoney(earningsByInvestmentType.invoiceFactoring) + ' €'],
                    [t("reverseFactoring"), earningsByInvestmentType.reverseFactoring, t("reverseFactoring") + '\n' + formatMoney(earningsByInvestmentType.reverseFactoring) + ' €'],
                    [t("internationalFactoring"), earningsByInvestmentType.internationalFactoring, t("internationalFactoring") + '\n' + formatMoney(earningsByInvestmentType.internationalFactoring) + ' €'],
                ] : null;
                break;
            case "roi":
                graphTitle = t("roiTitle");
                lineText = t("roiLineText");
                pieText = t("roiPieText");
                pieData = roiByInvestmentType ? [
                    [
                        {type: 'string', label: ""},
                        {type: 'number', label: ""},
                        {type: 'string', role: 'tooltip'},
                    ],
                    [t("invoiceFactoring"), roiByInvestmentType.invoiceFactoring, t("invoiceFactoring") + '\n' + formatMoney(roiByInvestmentType.invoiceFactoring) + ' €'],
                    [t("reverseFactoring"), roiByInvestmentType.reverseFactoring, t("reverseFactoring") + '\n' + formatMoney(roiByInvestmentType.reverseFactoring) + ' €'],
                    [t("internationalFactoring"), roiByInvestmentType.internationalFactoring, t("internationalFactoring") + '\n' + formatMoney(roiByInvestmentType.internationalFactoring) + ' €'],
                ] : null;
                break;
            case "balance":
                graphTitle = t("totalBalanceTitle");
                lineText = t("totalBalanceLineText");
                pieText = t("totalBalancePieText");
                pieData = netDeposits && earnedInterests ? [
                    [
                        {type: 'string', label: ""},
                        {type: 'number', label: ""},
                        {type: 'string', role: 'tooltip'},
                    ],
                    [t("deposits"), netDeposits, t("deposits") + '\n' + formatMoney(netDeposits) + ' €'],
                    [t("earnings"), earnedInterests, t("earnings") + '\n' + formatMoney(earnedInterests) + ' €'],
                ] : null;
                break;
            case "availableFunds":
                graphTitle = t("availableFundsTitle");
                lineText = t("availableFundsLineText");
                pieText = t("availableFundsPieText");
                pieData = deploymentRate ? [
                    [
                        {type: 'string', label: ""},
                        {type: 'number', label: ""},
                        {type: 'string', role: 'tooltip'},
                    ],
                    [t("fundsAvailable"), deploymentRate.fundsAvailable, t("fundsAvailable") + '\n' + formatMoney(deploymentRate.fundsAvailable) + ' €'],
                    [t("fundsDeployed"), deploymentRate.fundsDeployed, t("fundsDeployed") + '\n' + formatMoney(deploymentRate.fundsDeployed) + ' €'],
                ] : null;
                break;
        }

        return {graph, graphTitle, lineText, pieText, pieData}
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppHome));
