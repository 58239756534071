import {
    CANCEL_USER_INVESTMENT,
    GET_ALL_INVESTMENTS,
    GET_ALL_USER_INVESTMENTS,
    NEW_INVESTMENT,
    SET_ACTIVE_INVESTMENT,
    SET_ACTIVE_USER_INVESTMENT,
    SET_INVESTMENT,
    SET_USER_INVESTMENT,
    GET_INVESTMENTS_STATS
} from '../actions/investment';
import {createSelector} from 'reselect';
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    investments: {},
    investmentIds: [], // for displaying results in order
    sort: "",
    direction: "",
    limit: 20,
    offset: 0,
    atEnd: false,
    userInvestments: {},
    userInvestmentIds: [], // for displaying results in order
    activeInvestmentId: '',
    processingNewInvestment: false,
    investmentsStats: {},

    success: true,
    error: null
};

const investment = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NEW_INVESTMENT:
            return {
                ...state,
                processingNewInvestment: action.processingNewInvestment,
                success: action.success,
                error: action.error,
            };
        case GET_ALL_INVESTMENTS:
            return {
                ...state,
                investments: {...state.investments, ...action.investments},
                investmentIds: action.investmentIds,
                atEnd: action.atEnd,
                sort: action.sort,
                direction: action.direction,
                limit: action.limit,
                offset: action.offset,
            };
        case GET_ALL_USER_INVESTMENTS:
            return {
                ...state,
                userInvestments: {...state.userInvestments, ...action.userInvestments},
                userInvestmentIds: action.userInvestmentIds,
                atEnd: action.atEnd,
                sort: action.sort,
                direction: action.direction,
                limit: action.limit,
                offset: action.offset,
            };
        case SET_ACTIVE_INVESTMENT:
        case SET_ACTIVE_USER_INVESTMENT:
            return {
                ...state,
                activeInvestmentId: action.investmentId
            };
        case SET_INVESTMENT:
            return {
                ...state,
                investments: investments(state.investments, action)
            };
        case CANCEL_USER_INVESTMENT:
        case SET_USER_INVESTMENT:
            return {
                ...state,
                userInvestments: investments(state.userInvestments, action)
            };
        case GET_INVESTMENTS_STATS:
            return {
                ...state,
                investmentsStats: action.investmentsStats
            }
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};

const investments = (state, action) => {
    const investmentId = action.investmentId;
    switch (action.type) {
        case SET_USER_INVESTMENT:
        case SET_INVESTMENT:
            return {
                ...state,
                [investmentId]: action.investment
            };
        case CANCEL_USER_INVESTMENT:
            if(investmentId) {
                delete state[investmentId]
            }

            return {
                ...state
            };
        default:
            return state;
    }
};

export default investment;

const activeIvestmentIdSelector = state => state.investment.activeInvestmentId;
const investmentsSelector = state => state.investment.investments;
const userInvestmentsSelector = state => state.investment.userInvestments;
export const activeInvestmentSelector = createSelector(
    activeIvestmentIdSelector,
    investmentsSelector,
    (activeInvestmentId, investments) => {
        return investments && investments.hasOwnProperty(activeInvestmentId) ? investments[activeInvestmentId] : null;
    }
);

export const activeUserInvestmentSelector = createSelector(
    activeIvestmentIdSelector,
    userInvestmentsSelector,
    (activeInvestmentId, investments) => {
        return investments && investments.hasOwnProperty(activeInvestmentId) ? investments[activeInvestmentId] : null;
    }
);
