import {
    GET_AVAILABLE_FUNDS_HISTORY,
    GET_BALANCE_HISTORY,
    GET_EARNINGS_HISTORY,
    GET_ROI_HISTORY
} from '../actions/graph';
import {createSelector} from "reselect";
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    balanceHistoryType: 'alltime',
    availableFundsHistoryType: 'alltime',
    earningsHistoryType: 'alltime',
    roiHistoryType: 'alltime',

    balanceHistory: {},
    availableFundsHistory: {},
    earningsHistory: {},
    roiHistory: {},
};

const graph = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_BALANCE_HISTORY:
            return {
                ...state,
                balanceHistoryType: action.balanceHistoryType,
                balanceHistory: _graph(state.balanceHistory, action)

            };
        case GET_AVAILABLE_FUNDS_HISTORY:
            return {
                ...state,
                availableFundsHistoryType: action.availableFundsHistoryType,
                availableFundsHistory: _graph(state.availableFundsHistory, action)

            };
        case GET_EARNINGS_HISTORY:
            return {
                ...state,
                earningsHistoryType: action.earningsHistoryType,
                earningsHistory: _graph(state.earningsHistory, action)

            };
        case GET_ROI_HISTORY:
            return {
                ...state,
                roiHistoryType: action.roiHistoryType,
                roiHistory: _graph(state.roiHistory, action)

            };
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};

const _graph = (state, action) => {
    switch (action.type) {
        case GET_BALANCE_HISTORY:
            return {
                ...state,
                [action.balanceHistoryType]: action.balanceHistory
            };
        case GET_AVAILABLE_FUNDS_HISTORY:
            return {
                ...state,
                [action.availableFundsHistoryType]: action.availableFundsHistory
            };
        case GET_EARNINGS_HISTORY:
            return {
                ...state,
                [action.earningsHistoryType]: action.earningsHistory
            };
        case GET_ROI_HISTORY:
            return {
                ...state,
                [action.roiHistoryType]: action.roiHistory
            };
        default:
            return state;
    }
};

export default graph;

const activeBalanceHistoryTypeSelector = state => state.graph.balanceHistoryType;
const balanceHistorySelector = state => state.graph.balanceHistory;
export const activeBalanceHistorySelector = createSelector(
    activeBalanceHistoryTypeSelector,
    balanceHistorySelector,
    (type, history) => {
        return history && history.hasOwnProperty(type) ? history[type] : [];
    }
);

const activeAvailableFundsHistoryTypeSelector = state => state.graph.availableFundsHistoryType;
const availableFundsHistorySelector = state => state.graph.availableFundsHistory;
export const activeAvailableFundsHistorySelector = createSelector(
    activeAvailableFundsHistoryTypeSelector,
    availableFundsHistorySelector,
    (type, history) => {
        return history && history.hasOwnProperty(type) ? history[type] : [];
    }
);

const activeEarningsHistoryTypeSelector = state => state.graph.earningsHistoryType;
const earningsHistorySelector = state => state.graph.earningsHistory;
export const activeEarningsHistorySelector = createSelector(
    activeEarningsHistoryTypeSelector,
    earningsHistorySelector,
    (type, history) => {
        return history && history.hasOwnProperty(type) ? history[type] : [];
    }
);

const activeROIHistoryTypeSelector = state => state.graph.roiHistoryType;
const roiHistorySelector = state => state.graph.roiHistory;
export const activeROIHistorySelector = createSelector(
    activeROIHistoryTypeSelector,
    roiHistorySelector,
    (type, history) => {
        return history && history.hasOwnProperty(type) ? history[type] : [];
    }
);
