import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";

export const GET_AUTO_INVESTS = 'GET_AUTO_INVESTS';

export const getAutoInvests = () => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/userAutoInvests/' + userId, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(userAutoInvest => {
        //console.log("userAutoInvest", userAutoInvest);
        dispatch({
            type: GET_AUTO_INVESTS,
            userAutoInvest: userAutoInvest.autoInvest
        })
    }).catch(error => {
        console.log(error);

        dispatch({
            type: GET_AUTO_INVESTS,
            userAutoInvest: {}
        })

        /*dispatch({
            type: GET_AUTO_INVESTS,
            userInvestments: {},
            error
        })*/
    })
};

export const selectAutoInvest = (type, cb) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/userAutoInvests/' + userId, {
        method: 'post',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "Content-Type": "application/json",
            "userId": userId
        },
        body: JSON.stringify({
            "type": type
        })
    }).then(authMiddleware(dispatch)).then(res => {
        if(cb) {
            cb(true)
        }
    }).catch(error => {
        console.log(error);
        if(cb) {
            cb(false)
        }
    })
};

export const cancelAutoInvest = (cb) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/userAutoInvests/' + userId, {
        method: 'delete',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "Content-Type": "application/json"
        }
    }).then(authMiddleware(dispatch)).then(res => {
        if(cb) {
            cb(true)
        }
    }).catch(error => {
        console.log(error);
        if(cb) {
            cb(false)
        }
    })
};

export const selectCustomAutoInvest = (data, cb) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/userAutoInvests/' + userId, {
        method: 'post',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    }).then(authMiddleware(dispatch)).then(res => {
        if (cb) {
            cb(true)
        }

    }).catch(error => {
        if (cb) {
            cb(false)
        }
        console.log(error);
    })
};
