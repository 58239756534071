import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Chart from "react-google-charts";
import {withTranslation} from "react-i18next";
import {fetchFunds, fetchInterests, fetchRatios, getFunds, getInterests, getRatios} from "../store/stats";
import {formatMoney} from "../components/utils";

const lineGraphRatioOptions = {
    legend: 'none',
    tooltip: {isHtml: true},
    curveType: 'function',
    colors: ['#057E9D'],
    chartArea: {left: 0, top: 0, bottom: 0, right: 0, width: '100%', height: '100%'},
    axes: {
        x: {
            0: {side: 'top'}
        },
        y: {
            all: {
                range: {
                    max: 100,
                    min: 0
                }
            }
        }
    },
    backgroundColor: {
        fill: 'transparent',
    },
    vAxis: {
        baseline: 0,
        viewWindowMode: 'explicit',
        viewWindow: {
            max:100,
            min:0
        },
        gridlines: {
            //count : 12,
            //count: 10,
            color: '#41b2ce',
            //interval: [1, 5],
            //multiple: 10,
            minSpacing: 20
        },
        minorGridlines: {
            //count: 0,
            color: '#41b2ce',
        },
        textPosition: 'none',
        baselineColor: 'transparent',
        //format: 'long',
    },
    hAxis: {
        gridlines: {
            //ticks: [0, 100, 200 ,500 ,1000,5000],
            //count : 12,
            //count: 0,
            //count: 10,
            color: '#41b2ce',
            multiple: 10,
            interval: [1, 5],
            minSpacing: 20
        },
        minorGridlines: {
            //count: 0,
            color: '#41b2ce',
        },
        textPosition: 'none',
    },
    animation: {
        startup: true,
        duration: 500,
    },
};
const fundsGraphOptions = {
    legend: 'none',
    tooltip: {isHtml: true},
    curveType: 'function',
    colors: ['#057E9D'],
    chartArea: { width: '90%', height: '90%'},
    axes: {
        x: {
            0: {side: 'top'}
        }
    },
    backgroundColor: {
        fill: 'transparent',
    },
    vAxis: {
        //title: 'Invested funds ratio',
        baseline: 0,
        viewWindowMode: 'maximized',
        gridlines: {
            //count : 12,
            //count: 10,
            color: '#41b2ce',
            //interval: [1, 5],
            //multiple: 10,
            minSpacing: 20
        },
        minorGridlines: {
            //count: 0,
            color: '#41b2ce',
        },
        minValue: 0,
        maxValue: 100,
        //textPosition: 'none',
        baselineColor: 'transparent',
        //titleTextStyle: {color: '#41b2ce'},
        textStyle: {
            color: '#ffffff'
        },
        //format: 'long',
    },
    hAxis: {
        //title: 'Date',
        gridlines: {
            //ticks: [0, 100, 200 ,500 ,1000,5000],
            //count : 12,
            //count: 0,
            //count: 10,
            color: '#41b2ce',
            multiple: 10,
            interval: [1, 5],
            minSpacing: 20
        },
        minorGridlines: {
            //count: 0,
            color: '#41b2ce',
        },
        textPosition: 'none',
    },
    animation: {
        startup: true,
        duration: 500,
    },
},
    interestsGraphOptions = {
        legend: 'none',
        tooltip: {isHtml: true},
        curveType: 'function',
        colors: ['#057E9D'],
        chartArea: { width: '90%', height: '90%'},
        axes: {
            x: {
                0: {side: 'top'}
            }
        },
        backgroundColor: {
            fill: 'transparent',
        },
        vAxis: {
            //title: 'Invested funds ratio',
            baseline: 0,
            viewWindowMode: 'maximized',
            gridlines: {
                //count : 12,
                //count: 10,
                color: '#41b2ce',
                //interval: [1, 5],
                //multiple: 10,
                minSpacing: 20
            },
            minorGridlines: {
                //count: 0,
                color: '#41b2ce',
            },
            minValue: 0,
            //maxValue: 100,
            //textPosition: 'none',
            baselineColor: 'transparent',
            //titleTextStyle: {color: '#41b2ce'},
            textStyle: {
                color: '#ffffff'
            },
            //format: 'long',
        },
        hAxis: {
            //title: 'Date',
            gridlines: {
                //ticks: [0, 100, 200 ,500 ,1000,5000],
                //count : 12,
                //count: 0,
                //count: 10,
                color: '#41b2ce',
                multiple: 10,
                interval: [1, 5],
                minSpacing: 20
            },
            minorGridlines: {
                //count: 0,
                color: '#41b2ce',
            },
            textPosition: 'none',
        },
        animation: {
            startup: true,
            duration: 500,
        },
    }

const AppStats = () => {
    const linearRef = useRef(null);
    const [linearChartWidth, setLinearChartWidth] = useState();
    const [fundsData, setFundsData] = useState();
    const [interestsData, setInterestsData] = useState();
    const [pieData, setPieData] = useState(); // ratios

    const dispatch = useDispatch()
    const funds = useSelector(getFunds);
    const interests = useSelector(getInterests);
    const ratios = useSelector(getRatios);

    useEffect(() => {
        dispatch(fetchFunds())
        dispatch(fetchInterests())
        dispatch(fetchRatios())
    }, [])

    useEffect(() => {
        if (linearRef.current) setLinearChartWidth(linearRef.current.offsetWidth)
    }, [linearRef])

    useEffect(() => {
        if (funds && funds.daily) {
            setFundsData([
                [
                    {type: 'string', label: "Timestamp"},
                    {type: 'number', label: "Ratio"},
                    {type: 'string', role: 'tooltip', 'p': {'html': true}},
                ],
                ...funds.daily.map(({id, investedFundsRatio}) => [id, Math.floor(investedFundsRatio * 100), Math.floor(investedFundsRatio * 100) + " %"])
            ])
        }
    }, [funds])

    useEffect(() => {
        if (interests && interests.data) {
            setInterestsData([
                [
                    {type: 'string', label: "Timestamp"},
                    {type: 'number', label: "Interests"},
                    {type: 'string', role: 'tooltip', 'p': {'html': true}},
                ],
                ...interests.data.map(({id, interests}) => [id, interests, formatMoney(interests) + " €"])
            ])
        }
    }, [interests])

    useEffect(() => {
        if (ratios && ratios.types) {
            setPieData([
                [
                    {type: 'string', label: ""},
                    {type: 'number', label: ""},
                    {type: 'string', role: 'tooltip'},
                ],
                ...ratios.types.map(({id, ratio}) => [id, Math.floor(ratio * 100), id])
            ])
        }
    }, [ratios])

    /*console.log("funds", funds)
    console.log("interests", interests)
    console.log("ratios", ratios)*/

    return (
        <div>
            <div className="mdc-layout-grid mdc-layout-grid--big-spacing">
                <div className="mdc-layout-grid__inner">
                    <div
                        className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                        <div className="headline">
                            <h2 className="headline__title">
                                Funds
                            </h2>
                        </div>
                        <span className="line-graph__title"/>
                        <div ref={linearRef} className="line-graph">
                            <div className="line-graph__chart">
                                {fundsData && <Chart
                                    width={linearChartWidth}
                                    height={180}
                                    chartType="ColumnChart"
                                    loader={<div/>}
                                    data={fundsData}
                                    options={fundsGraphOptions}
                                    legendToggle
                                />}
                            </div>
                        </div>
                        <div className="line-graph__info">
                            <span>% of total funds on platform currently invested (daily statistics)</span>
                        </div>
                    </div>
                    <div
                        className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                        <div className="headline">
                            <h2 className="headline__title">
                                Interests
                            </h2>
                        </div>

                        <span className="line-graph__title"/>
                        <div ref={linearRef} className="line-graph">
                            <div className="line-graph__chart">
                                {interestsData && <Chart
                                    width={linearChartWidth}
                                    height={180}
                                    chartType="ColumnChart"
                                    loader={<div/>}
                                    data={interestsData}
                                    options={interestsGraphOptions}
                                />}
                            </div>
                        </div>
                        <div className="line-graph__info">
                            <span>Interest that are added to investor daily (daily statistics) </span>
                        </div>
                    </div>
                    <div
                        className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                        <div className="headline">
                            <h2 className="headline__title">
                                Investment type ratios
                            </h2>
                        </div>

                        <span className="line-graph__title"/>
                        <div className="pie-graph pie-graph-second">
                            {pieData && <Chart
                                width={264}
                                height={264}
                                chartType="PieChart"
                                loader={<div/>}
                                data={pieData}
                                options={{
                                    pieHole: 0.5,
                                    legend: 'none',
                                    colors: ['#057E9D', '#a6ce38', '#91D8F7'],
                                    chartArea: {left: 16, top: 16, right: 16, bottom: 16},
                                    backgroundColor: {
                                        fill: 'transparent',
                                    },
                                    pieSliceBorderColor: 'transparent',
                                    pieStartAngle: 180,
                                    animation: {
                                        startup: true,
                                        duration: 500,
                                    },
                                }}
                            />}
                        </div>
                        <div className="line-graph__info line-graph__info--centered">
                            <span>Type of investment ratio in % (daily statistics)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation("app")(AppStats);
