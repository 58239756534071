import React, {Component} from 'react';
import {connect} from "react-redux";
import memoize from "memoize-one";
import {getAvailableFundsHistory, getBalanceHistory, getEarningsHistory, getROIHistory} from "../actions/graph";
import {
    activeAvailableFundsHistorySelector,
    activeBalanceHistorySelector,
    activeEarningsHistorySelector,
    activeROIHistorySelector
} from "../reducers/graph";
import Chart from "react-google-charts";
import {formatMoney} from "./utils";
import {withTranslation} from "react-i18next";


const mapStateToProps = (state, props) => {
    const {graphType} = props;

    switch (graphType) {
        case "balance":
            return {
                history: activeBalanceHistorySelector(state) || [],
                historyType: state.graph.balanceHistoryType,
                unit: "€"
            };
        case "availableFunds":
            return {
                history: activeAvailableFundsHistorySelector(state) || [],
                historyType: state.graph.availableFundsHistoryType,
                unit: "€"
            };
        case "earnings":
            return {
                history: activeEarningsHistorySelector(state) || [],
                historyType: state.graph.earningsHistoryType,
                unit: "€"
            };
        case "roi":
            return {
                history: activeROIHistorySelector(state) || [],
                historyType: state.graph.roiHistoryType,
                unit: "%"
            };
        default:
            return {}
    }
};


class LineGraph extends Component {
    state = {
        chartWidth: 0
    };

    parseGraphData = memoize(
        (history = []) => {
            if (history.length === 0) {
                return null
                //history = [{dateFormatted: "", amount: 0}, {dateFormatted: "", amount: 0}]
                //history = [{date: 0, amount: 0}, {date: 0, amount: 0}]
            } else if (history.length === 1) {
                //history.push(history[0])
            }

            const data = [];
            for (let i = 0; i < history.length; i++) {
                const d = history[i];
                data.push([d.date, d.amount, `<div class="chart-tooltip"><span class="chart-tooltip__value">${formatMoney(d.amount) + ' ' + this.props.unit}</span><span class="chart-tooltip__label">${d.dateFormatted}</span></div>`]);
                if (i === history.length - 1) {
                    //this.refs.dataEntry.textContent = formatMoney(d.amount) + " €"
                }
            }

            return data
        }
    );

    render() {
        const graphData = this.parseGraphData(this.props.history), {t} = this.props;
        return (
            <div className={"line-graph"}>
                <div className="line-graph__chart" ref={"content"}>
                    {graphData && <Chart
                        width={this.state.chartWidth}
                        height={180}
                        chartType="LineChart"
                        loader={<div/>}
                        data={[
                            [
                                {type: 'number', label: "Date"},
                                {type: 'number', label: "Money"},
                                {type: 'string', role: 'tooltip', 'p': {'html': true}},
                            ],
                            ...graphData
                        ]}
                        options={{
                            legend: 'none',
                            tooltip: {isHtml: true},
                            curveType: 'function',
                            colors: ['#057E9D'],
                            chartArea: {left: 0, top: 0, bottom: 0, right: 0, width: '100%', height: '100%'},
                            axes: {
                                x: {
                                    0: {side: 'top'}
                                }
                            },
                            backgroundColor: {
                                fill: 'transparent',
                            },
                            vAxis: {
                                baseline: 0,
                                viewWindowMode: 'maximized',
                                gridlines: {
                                    //count : 12,
                                    //count: 10,
                                    color: '#41b2ce',
                                    //interval: [1, 5],
                                    //multiple: 10,
                                    minSpacing: 20
                                },
                                minorGridlines: {
                                    //count: 0,
                                    color: '#41b2ce',
                                },
                                textPosition: 'none',
                                baselineColor: 'transparent',
                                //format: 'long',
                            },
                            hAxis: {
                                gridlines: {
                                    //ticks: [0, 100, 200 ,500 ,1000,5000],
                                    //count : 12,
                                    //count: 0,
                                    //count: 10,
                                    color: '#41b2ce',
                                    multiple: 10,
                                    interval: [1, 5],
                                    minSpacing: 20
                                },
                                minorGridlines: {
                                    //count: 0,
                                    color: '#41b2ce',
                                },
                                textPosition: 'none',
                            },
                            animation: {
                                startup: true,
                                duration: 500,
                            },
                        }}
                    />}
                </div>
                <div className={"button-switch hide-on-mobile"}>
                    <button onClick={this._showAllTime.bind(this)}
                            className={this.props.historyType === "alltime" ? "button button--active button--unelevated" : "button button--unelevated"}>
                        {t('allTime')}
                    </button>
                    <button onClick={this._showYearly.bind(this)}
                            className={this.props.historyType === "yearly" ? "button button--active button--unelevated" : "button button--unelevated"}>
                        {t('yearly')}
                    </button>
                    <button onClick={this._showQuarterly.bind(this)}
                            className={this.props.historyType === "quarterly" ? "button button--active button--unelevated" : "button button--unelevated"}>
                        {t('quarterly')}
                    </button>
                </div>
            </div>
        );
    }

    _showQuarterly() {
        switch (this.props.graphType) {
            case "balance":
                this.props.dispatch(getBalanceHistory("quarterly"));
                break;
            case "availableFunds":
                this.props.dispatch(getAvailableFundsHistory("quarterly"));
                break;
            case "roi":
                this.props.dispatch(getROIHistory("quarterly"));
                break;
            default:
                this.props.dispatch(getEarningsHistory("quarterly"));
        }
    }

    _showYearly() {
        switch (this.props.graphType) {
            case "balance":
                this.props.dispatch(getBalanceHistory("yearly"));
                break;
            case "availableFunds":
                this.props.dispatch(getAvailableFundsHistory("yearly"));
                break;
            case "roi":
                this.props.dispatch(getROIHistory("yearly"));
                break;
            default:
                this.props.dispatch(getEarningsHistory("yearly"));
        }
    }

    _showAllTime() {
        switch (this.props.graphType) {
            case "balance":
                this.props.dispatch(getBalanceHistory("alltime"));
                break;
            case "availableFunds":
                this.props.dispatch(getAvailableFundsHistory("alltime"));
                break;
            case "roi":
                this.props.dispatch(getROIHistory("alltime"));
                break;
            default:
                this.props.dispatch(getEarningsHistory("alltime"));
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.setState({chartWidth: this.refs.content.clientWidth})
        this._updateGraph();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.graphType !== this.props.graphType) {
            this._updateGraph()
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    _updateGraph() {
        if (!this._ismounted) return;
        switch (this.props.graphType) {
            case "balance":
                this.props.dispatch(getBalanceHistory());
                break;
            case "availableFunds":
                this.props.dispatch(getAvailableFundsHistory());
                break;
            case "roi":
                this.props.dispatch(getROIHistory());
                break;
            default:
                this.props.dispatch(getEarningsHistory());
        }
    }
}


export default connect(mapStateToProps)(withTranslation("app")(LineGraph));
