import {GET_ACCOUNT_BALANCE} from '../actions/accountBalance';
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    accountBalance: {}
};

const accountBalance = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ACCOUNT_BALANCE:
            return {
                ...state,
                accountBalance: action.accountBalance
            };
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default accountBalance;
