import {resetState} from "./actions/root";

export const SESSION_EXPIRED = "SESSION_EXPIRED";

export const authMiddleware = dispatch => {
    return res => {
        if (res.status === 401) {
            console.log("session expired");
            dispatch({
                type: SESSION_EXPIRED,
                redirectOnLoginUri: window.location.pathname
            })

            dispatch(resetState());
        } else {
            return res
        }
    }
};
