import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {changePassword} from "../actions/user";
import {API_URL} from "../config";
import {withTranslation} from "react-i18next";


const mapStateToProps = state => {
    return {
        user: state.user.currentUser,
        _activeTab: state.tabs.tab
    };
};

class AppUserChangePassword extends Component {
    state = {};

    render() {
        //const {user} = this.props, {editingTaxResidence, editingTaxNo, editingIncome} = this.state;
        const {fetching, error, redirect, twoFactorAuthEnabled} = this.state, {t} = this.props;
        if (redirect) return <Redirect to="/"/>;
        return (
            <form onSubmit={this._handleSubmit.bind(this)}>
                <div className="Register-Content-Header">

                    <div className="Register-Content-Body">
                        <div className="Register-Row">
                            <div className="Register-FormField">
                                <input ref="code" type="text" name="code" className="Register-Input"
                                       placeholder={t('smsConfirmationCode')} required
                                       autoComplete="one-time-code"/>
                            </div>
                        </div>
                        <div className="Register-Row">
                            <div className="Register-FormField">
                                <input ref="password" type="password" name="newPassword" className="Register-Input"
                                       placeholder={t('newPassword')} required
                                       autoComplete="new-password"/>
                            </div>
                        </div>
                        <div className="Register-Row">
                            <div className="Register-FormField Register-FormField--Next">
                                <input ref="password2" type="password" name="newPassword2" className="Register-Input"
                                       placeholder={t('repeatNewPassword')} required
                                       autoComplete="new-password"/>

                                <button disabled={fetching} type="submit" data-loading={fetching}
                                        className="Button Button--Next">
                                    <div className="Button__Loader"/>
                                </button>
                            </div>
                        </div>
                        <div className="Register-Row">
                            <span className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                        </div>
                        {/*<Trans i18nKey='resetPasswordHere'>
                            <span className="Register-Subtitle">Lost password? <Link className="link" to={"/recover"}>Reset password</Link></span>
                        </Trans>*/}
                    </div>
                </div>
            </form>
        )
    }

    _handleSubmit(e) {
        e.preventDefault();

        const {verificationId, twoFactorAuthEnabled} = this.state, code = this.refs.code.value,
            newPassword = this.refs.password.value,
            newPassword2 = this.refs.password2.value,
            data = {code, newPassword, verificationId};

        if (newPassword !== newPassword2) {
            this.setState({error: window.trans("error-passwords-do-not-match"), fetching: false});
            return
        }

        this.setState({fetching: true});

        fetch(API_URL + "/passwordReset", {
            method: 'post',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then(res => {
            if (res && res.message) {
                this.setState({error: window.trans(res.message), fetching: false});
                console.error(res.message)
            } else {
                this.setState({error: '', redirect: true, fetching: false});
            }
        }).catch(err => {
            this.setState({error: window.trans("error-loggin-in"), fetching: false});
            console.error(err)
        });
    }

    componentDidMount() {
        if (!this.hasDone) {
            this.hasDone = true;

            this.setState({twoFactorAuthEnabled: this.props.user.status.twoFactorAuthEnabled === true})

            if (this.props.user.status.twoFactorAuthEnabled === true) {
                // IF 2FA ENABLED
                this.props.dispatch(changePassword(res => {
                    if (res) {
                        this.setState({verificationId: res.Id})
                    }
                }))
            } else {
                // IF 2FA DISABLED
                this.props.dispatch(changePassword(res => {
                    console.log(res);
                    if (res) {
                        this.setState({verificationId: res.Id})
                    }
                }))
            }
        }
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppUserChangePassword));