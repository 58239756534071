import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";


class DepositBanner extends Component {
    render() {
      const {t} = this.props;
      return (
        <div className={this.props.light ? 'banner banner--custom-light hide-on-mobile' : 'banner'}>
            <div className="banner__row">
                <h3 className="mdc-typography--headline6 banner__title">{t('depositBannerTitle')}</h3>
                <span className="mdc-typography--body1 banner__content">{t('depositBannerText')}</span>
                <Link to="/u/deposits" className="button button--unelevated button--white">{t('depositBannerCTA')}</Link>
            </div>
        </div>
      );
    }
}

export default (withTranslation('app')(DepositBanner));
