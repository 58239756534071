import React, {Component} from 'react';

class Tutorial extends Component {
    state = {active: false};

    render() {
        const {active, title, text} = this.state;

        return (
            <div className={"tutorial-overlay" + (active ? " tutorial-overlay--active" : "")}>
                <div className={"tutorial"}>
                    <h2 className="mdc-typography--headline5">{title}</h2>
                    <p className="mdc-typography--body2">{text}</p>
                    <div className="tutorial__actions">
                        <button onClick={this.close.bind(this)} className="button">Skip</button>
                        <button onClick={this.next.bind(this)} className="button button--unelevated button--primary">Next</button>
                    </div>
                </div>
            </div>
        );
    }

    start(tuts) {
        this.at = -1;
        this.tuts = tuts;
        this.next()
    }

    next() {
        this.at++;
        const tut = this.tuts[this.at];
        if(this.prevEl) {
            this.prevEl.classList.remove("tutorial-exposed-element");
        }
        if(tut) {
            const {el, title, text} = tut;
            if(el) {
                el.classList.add("tutorial-exposed-element");
                this.prevEl = el;
            }
            this.setState({active: true, title, text})
        } else {
            this.close()
        }
    }

    close() {
        if(this.prevEl) {
            this.prevEl.classList.remove("tutorial-exposed-element");
            this.prevEl = null;
        }
        this.setState({active: false})
    }
}

// tutorial-exposed-element

export default Tutorial;
