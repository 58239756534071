import {GET_ACCOUNT_HISTORY} from '../actions/accountHistory';
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    content: [],
    last: false,
    page: 0,
};

const accountHistory = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ACCOUNT_HISTORY:
            return {
                ...state,
                content: action.replace ? action.content : [...state.content, ...action.content],
                last: action.last,
                page: action.page
            };
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default accountHistory;
