import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";
import {download} from "../components/utils";

export const GET_ALL_INVESTMENTS = 'GET_ALL_INVESTMENTS';
export const SET_ACTIVE_INVESTMENT = 'SET_ACTIVE_INVESTMENT';
export const SET_ACTIVE_USER_INVESTMENT = 'SET_ACTIVE_USER_INVESTMENT';
export const SET_INVESTMENT = 'SET_INVESTMENT';
export const SET_USER_INVESTMENT = 'SET_USER_INVESTMENT';
export const NEW_INVESTMENT = 'NEW_INVESTMENT';
export const GET_ALL_USER_INVESTMENTS = 'GET_ALL_USER_INVESTMENTS';
export const CANCEL_USER_INVESTMENT = 'CANCEL_USER_INVESTMENT';
export const GET_INVESTMENTS_STATS = 'GET_INVESTMENTS_STATS';

const limit = 20;

export const getAllInvestments = ({offset = 0, sort = "date", direction = "desc"} = {}) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        oldInvestmentIds = state.investment.investmentIds || [];
    sort = sort || state.investment.sort;
    direction = direction || state.investment.direction;

    fetch(API_URL + '/investments?sort=' + sort + '&direction=' + direction + '&limit=' + limit + '&offset=' + offset, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then((_investments = []) => {

        let investmentIds = [], newCount = 0;
        let investments = _investments.reduce((obj, investment) => {
            obj[investment.Id] = investment;
            investmentIds.push(investment.Id);
            newCount++
            return obj
        }, {});
        // remove key from oldUserInvestmentIds if request returned same entry in result
        for (let i = oldInvestmentIds.length - 1; i >= 0; i--) {
            if (investments.hasOwnProperty(oldInvestmentIds[i])) {
                oldInvestmentIds.splice(i, 1);
                newCount--
            }
        }
        if (offset > 0) {
            investmentIds = [...oldInvestmentIds, ...investmentIds]
        }
        dispatch({
            type: GET_ALL_INVESTMENTS,
            investments, investmentIds, sort, direction, limit, offset, atEnd: newCount === 0
        })
    }).catch(err => {
        console.error(err);
    })
};

export const setActiveInvestment = investmentId => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader;

    fetch(API_URL + '/investments/' + encodeURIComponent(investmentId), {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(investment => {
        console.log("inv", investment);

        dispatch({
            type: SET_INVESTMENT,
            investmentId, investment
        });

        dispatch({
            type: SET_ACTIVE_INVESTMENT,
            investmentId
        })

        /*const investments = data.reduce((obj, investment) => {
            obj[investment.Id] = investment;
            return obj
        }, {});

        dispatch({
            type: GET_ALL_INVESTMENTS,
            investments
        })*/
    }).catch(err => {
        console.log(err);

        dispatch({
            type: SET_ACTIVE_INVESTMENT,
            investmentId
        })
    })
};

export const newInvestment = (investmentId, amount, cb) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    dispatch({
        type: NEW_INVESTMENT,
        processingNewInvestment: true,
    });

    fetch(API_URL + '/user_investments', {
        method: 'post',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId,
        },
        body: JSON.stringify({
            investmentId, amount
        })
    }).then(authMiddleware(dispatch)).then(() => {
        if (cb) {
            cb(true)
        }

        dispatch({
            type: NEW_INVESTMENT,
            processingNewInvestment: false,
            success: true
        })
    }).catch(error => {
        if (cb) {
            cb(false)
        }

        dispatch({
            type: NEW_INVESTMENT,
            processingNewInvestment: false,
            success: false,
            error
        })
    })

};

export const getAllUserInvestments = ({
                                          offset = 0,
                                          sort = "issued",
                                          direction = "desc"
                                      } = {}) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id,
        oldUserInvestmentIds = state.investment.userInvestmentIds || [];
    sort = sort || state.investment.sort;
    direction = direction || state.investment.direction;

    fetch(API_URL + '/user_investments?sort=' + sort + '&direction=' + direction + '&limit=' + limit + '&offset=' + offset, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then((_userInvestments = []) => {
        let userInvestmentIds = [], newCount = 0;
        let userInvestments = _userInvestments.reduce((obj, investment) => {
            obj[investment.Id] = investment;
            userInvestmentIds.push(investment.Id);
            newCount++
            return obj
        }, {});
        // remove key from oldUserInvestmentIds if request returned same entry in result
        for (let i = oldUserInvestmentIds.length - 1; i >= 0; i--) {
            if (userInvestments.hasOwnProperty(oldUserInvestmentIds[i])) {
                oldUserInvestmentIds.splice(i, 1);
                newCount--
            }
        }
        if (offset > 0) {
            userInvestmentIds = [...oldUserInvestmentIds, ...userInvestmentIds]
        }
        dispatch({
            type: GET_ALL_USER_INVESTMENTS,
            userInvestments, userInvestmentIds, sort, direction, limit, offset, atEnd: newCount === 0
        })
    }).catch(error => {
        console.error(error);
    })
};

export const cancelUserInvestment = (investmentId) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/user_investments/' + investmentId, {
        method: 'delete',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId
        },
    }).then(authMiddleware(dispatch)).then(res => {
        if (res.status !== 200) {
            res.json().then(t => window.alert(t.message));
            return;
        }
        dispatch({
            type: CANCEL_USER_INVESTMENT,
            investmentId
        })
    }).catch(error => {

        dispatch({
            type: CANCEL_USER_INVESTMENT,
            error
        })
    })
};

export const downloadUserInvestmentContract = (investmentId) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/user_investments/' + investmentId + '/contract', {
        method: 'get',
        headers: {
            "Accept": "application/json, application/pdf",
            "Authorization": authenticationHeader,
            "userId": userId
        },
    }).then(authMiddleware(dispatch)).then(res => {
        //content-disposition: inline;filename=income_statement_xxx_xxxxxxxxxxxx.pdf
        let contentDisposition = (res.headers.get("content-disposition") || "").split("filename="),
            filename = investmentId + ".pdf"
        if (contentDisposition[1]) filename = contentDisposition[1]
        return res.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            download(filename, url)
        })
    }).catch(error => {
        console.error(error)
    })
};

export const setActiveUserInvestment = investmentId => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/user_investments/' + encodeURIComponent(investmentId), {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId,
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(investment => {
        dispatch({
            type: SET_USER_INVESTMENT,
            investmentId, investment
        });

        dispatch({
            type: SET_ACTIVE_USER_INVESTMENT,
            investmentId
        })
    }).catch(err => {
        console.log(err);

        dispatch({
            type: SET_ACTIVE_USER_INVESTMENT,
            investmentId
        })
    })
};

export const getInvestmentStats = () => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader;

    fetch(API_URL + '/investments/stats', {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(investmentsStats => {
        console.log("investmentsStats", investmentsStats)
        dispatch({
            type: GET_INVESTMENTS_STATS,
            investmentsStats
        })
    }).catch(error => {
        console.log(error);

        dispatch({
            type: GET_INVESTMENTS_STATS,
            investmentsStats: {}
        })
    })
};
