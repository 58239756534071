import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {ReactComponent as WarningIcon} from "../images/baseline-warning-24px.svg";
import {withTranslation} from "react-i18next";

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser,
  };
};

class InvestorProfileBanner extends Component {
    render() {
      const {user, t} = this.props;
      return (
        <div className={this.props.light ? 'banner banner--custom-light' : 'banner'}>

          {/* We are either waiting on investor profile completion or account confirmation. */}
          {(user.investorType === "Personal" && user.investorProfile && user.investorProfile.completed) || (user.investorType === "Business" && user.companyInvestorProfile && user.companyInvestorProfile.completed) ?
            <div className="banner__row">
                <h3 className="mdc-typography--headline6 banner__title">{t('ipBannerTitle')}</h3>
                <span className="mdc-typography--body1 banner__content">{t('ipBannerPending')}</span>
            </div>
            :
            <div className="banner__row">
                <h3 className="mdc-typography--headline6 banner__title">{t('ipBannerTitle')}</h3>
                  <span className="mdc-typography--body1 banner__content">{t('ipBannerText')}</span>
                  <Link to="/u/account/financial-information/1" className="button button--with-icon button--warning button--action">
                      <span className="button__icon"><WarningIcon/></span>
                      {t('ipBannerCTA')}
                  </Link>
            </div>
          }
        </div>
      );
    }

}

export default connect(mapStateToProps) (withTranslation("app")(InvestorProfileBanner));
