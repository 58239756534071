import React, {Component} from "react";
import {ReactComponent as ArrowLeft} from "../images/arrow-left.svg";
import {connect} from "react-redux";
import {setActiveTab} from "../actions/tabs";
import {withTranslation} from "react-i18next";
import {downloadIncomeStatement, getAccountHistory} from "../actions/accountHistory";
import {formatMoney} from "../components/utils";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {GetApp} from "@material-ui/icons";

const mapStateToProps = state => {
    return {
        _activeTab: state.tabs.tab,
        content: state.accountHistory.content,
        last: state.accountHistory.last,
        page: state.accountHistory.page,
    };
};

class AppSummary extends Component {
    constructor() {
        super();

        const defaultTo = new Date(), defaultFrom = new Date();
        defaultTo.setFullYear(defaultTo.getFullYear(), 0, 0)
        defaultFrom.setFullYear(defaultFrom.getFullYear() - 1, 0, 1)

        this.state = {
            isIncomeStatementDialogOpen: false,
            from: defaultFrom,
            to: defaultTo,
        }
    }

    render() {
        const {t, last, content = []} = this.props;
        return (
            <section className={this.props._activeTab === 1 ? "page page--next active" : "page page--next not-active"}>
                <div className="page__inner custom-page-dark-background">
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-tablet">
                                <div className="table-header">
                                    <div className="table-header__title">
                                        <h2 className="mdc-typography--headline5 title title--border table-header__title-text">
                                            {t("accountSummaryTitle")}
                                        </h2>
                                    </div>
                                    <div className="table-header__sort">
                                        <Button onClick={() => this.setState({isIncomeStatementDialogOpen: true})}
                                                variant={"text"} color={"inherit"}
                                                startIcon={<GetApp/>}>{t('incomeStatement')}</Button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table id="sortable-table" className="sortable table table--no-wrap">
                                        <thead>
                                        <tr className="mdc-typography--body2 table__head-row">
                                            <th className="no-sort">#</th>
                                            <th className="no-sort">{t("date")}</th>
                                            <th className="no-sort">{t("type")}</th>
                                            <th className="no-sort">{t("value")}</th>
                                            <th className="no-sort hide-on-mobile">{t("accountValue")}</th>
                                            <th className="no-sort hide-on-mobile">{t("availableFunds")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {content.map((key, index) => {
                                            let it = content[index];
                                            if (!it || !it.Id) return null;
                                            return (
                                                <tr className="mdc-typography--body1" key={index}>
                                                    <td>{it.Id}</td>
                                                    <td>{it.dateFormatted}</td>
                                                    <td>{t(it.type)}</td>
                                                    <td>{formatMoney(Math.abs(it.value || 0))} €</td>
                                                    <td>{formatMoney(it.accountValue)} €</td>
                                                    <td>{formatMoney(it.availableFunds)} €</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 flex flex--justify-center">
                                <button
                                    type="button"
                                    onClick={this._loadMore.bind(this)}
                                    className="button button--unelevated"
                                    hidden={last}>
                                    {t("loadMore")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="custom-navigation-wrapper" ref="navBtn">
                        <div className="custom-navigation">
                            <div className="custom-navigation__text">
                                <span>{t('myInvestmentsTitle')}</span>
                            </div>
                            <div className="custom-navigation__icon">
                                <ArrowLeft/>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog open={this.state.isIncomeStatementDialogOpen}>
                    <DialogTitle>{t('incomeStatement')}</DialogTitle>
                    <DialogContent>
                        <p>{t('incomeStatementText')}</p>
                        <div className="text-field-form-row">
                            <TextField
                                id="date"
                                label={t('from')}
                                onChange={e => this.setState({from: e.target.valueAsDate})}
                                variant={"outlined"}
                                type="date"
                                value={this.state.from.toISOString().split("T")[0]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="date"
                                label={t('to')}
                                onChange={e => this.setState({to: e.target.valueAsDate})}
                                variant={"outlined"}
                                type="date"
                                value={this.state.to.toISOString().split("T")[0]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.setState({isIncomeStatementDialogOpen: false})}>{t('cancel')}</Button>
                        <Button onClick={this._downloadStatement}>{t('download')}</Button>
                    </DialogActions>
                </Dialog>
            </section>
        )
    }

    _downloadStatement = () => {
        const {from, to} = this.state
        this.props.dispatch(downloadIncomeStatement({
            from: from.toISOString().split("T")[0],
            to: to.toISOString().split("T")[0]
        }))
        this.setState({isIncomeStatementDialogOpen: false})
    }

    _loadMore() {
        this.props.dispatch(getAccountHistory({
            page: this.props.page + 1
        }));
    }

    componentDidMount() {
        this.refs.navBtn.addEventListener('click', () => {
            this.props.dispatch(setActiveTab(0))
        });

        this.props.dispatch(getAccountHistory({page: 0, replace: true}));
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppSummary));
