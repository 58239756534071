import {GET_AUTO_INVESTS} from '../actions/autoinvest.js';
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    userAutoInvest: {}
};

const autoinvest = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_AUTO_INVESTS:
            return {
                ...state,
                userAutoInvest: action.userAutoInvest,
            };
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default autoinvest;
