import {GET_NOTIFICATIONS} from '../actions/notifications';
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    notifications: []
};

const notifications = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications
            };
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default notifications;
