import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";

export const GET_ALL_WITHDRAWLS = 'GET_ALL_WITHDRAWLS';

export const getAllWithdrawals = ({
                                      offset = 0,
                                      sort = "date",
                                      direction = "desc"
                                  } = {}) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id,
        oldIds = state.withdrawals.withdrawalIds || [],
        limit = state.withdrawals.limit;

    fetch(API_URL + '/withdrawals/' + userId + '?sort=' + sort + '&direction=' + direction + '&limit=' + limit + '&offset=' + offset, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then((_data = []) => {

        let ids = [], newCount = 0;
        let data = _data.reduce((obj, it) => {
            obj[it.Id] = it;
            ids.push(it.Id);
            newCount++
            return obj
        }, {});
        // removes key from oldIds if request returned same entry in result
        for (let i = oldIds.length - 1; i >= 0; i--) {
            if (data.hasOwnProperty(oldIds[i])) {
                oldIds.splice(i, 1);
                newCount--
            }
        }
        if (offset > 0) {
            ids = [...oldIds, ...ids]
        }
        dispatch({
            type: GET_ALL_WITHDRAWLS,
            withdrawals: data,
            withdrawalIds: ids,
            sort,
            direction,
            limit,
            offset,
            atEnd: newCount === 0 || newCount < limit
        })
    }).catch(err => {
        console.log(err);
    })
};

export const withdrawMoney = (amount, verificationId, smsCode) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/withdrawals/' + userId, {
        method: 'post',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "amount": amount,
            "verificationId": verificationId,
            "code": smsCode,
        })
    }).then(authMiddleware(dispatch)).then(res => {
        if (res.status !== 200) {
            res.json().then(t => window.alert(t.message));
        }

        dispatch(getAllWithdrawals())
    }).catch(err => {
        console.error(err);
    })
};
