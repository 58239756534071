import React, {Component} from 'react';
import i18next from "i18next";

class Terms extends Component {
    render = () => (
        i18next.language.toLowerCase() === 'sl' ? (
            <TermsSlo />
        ) : (
            <TermsEng />
        )
    );
}

class TermsSlo extends Component {
    render = () => (
        <div className="Register-Up-Content-Body-Section">
            <p>SPLOŠNI POGOJI ZA UPORABO SPLETNI STRANI NEKSTER</p>
            <p>1. UPORABLJENI IZRAZI IN NJIHOV POMEN</p>
            <p>Nekster – podjetje NEKSTER, d.o.o., Dunajska cesta 151, Ljubljana, 1000 Ljubljana, matična številka: 6098371000, ki ga zastopa direktor Nejc Apšner in direktor Aleksandar Zečevič.</p>
            <p>Odstop terjatve s pogodbo – pravni posel s katerim cedent odstopi svojo terjatev cesionarju.</p>
            <p>Cesionar - oseba, ki je prevzela terjatev od cedenta na podlagi Odstopa terjatve s pogodbo.</p>
            <p>Cedent – oseba, ki je z cesionarjem sklenila Odstop terjatve s pogodbo.</p>
            <p>Aplikacija - aplikacija NEKSTER, s katero NEKSTER zagotavlja pravice dostopa investitorjem do njihovih investitorskih profilov ali individualno funkcijo investitorskega profila preko mobilne naprave.</p>
            <p>Asignant (odstopnik terjatve oz. nakazovalec) – oseba, ki je skladno z Asignacijsko pogodbo od Asignanta pooblaščena da v svojem imenu sprejme izpolnitev obveznosti iz Odstopa terjatve s pogodbo od Asignata.</p>
            <p>Asignacijska pogodba - pogodba, ki jo sklenejo Asignant, Asignat in Asignatar in vsebuje dva enostranska posla in sicer pooblastilo Asignanta Asignatu, da naj za njegov račun poplača terjatev Asignatarju in pooblastilo Asignanta Asignatarju, da v svojem imenu sprejme to izpolnitev.</p>
            <p>Bančni račun Asignatarja - tekoči (transakcijski) račun pri kateri koli kreditni instituciji, registrirani v Republiki Sloveniji, podružnici tuje kreditne institucije v Sloveniji ali kreditne institucije, registrirane v drugi državi članici Evropske unije, odprt na ime asignatarja.</p>
            <p>Asignatar (prejemnik nakazila) - fizična oseba ali pravna oseba, ki je pooblaščena, da sprejme poplačilo terjatve od Asignata.</p>
            <p>Cenik – denarni zneski, navedeni na spletni strani, ki jih mora investitor plačati NEKSTER-ju za zagotovitev storitev v povezavi s terjatvijo in drugih storitev NEKSTER.</p>
            <p>Investitor - fizična oseba ali pravna oseba, ki se je registrirala na spletni strani kot investitor in želi Asignanta odkupiti terjatev oz. del nje, ki so ponujene na spletni strani, vključno s fizično ali pravno osebo, ki je odkupila terjatev..</p>
            <p>Identifikacijska številka investitorja - številka, ki jo investitorju dodeli NEKSTER in je potrebna za nadaljnjo identifikacijo investitorja ter jo je treba navesti pri prenosu sredstev, namenjenih za odkup terjatve - stanje profila investitorja.</p>
            <p>Bančni račun investitorja - tekoči (transakcijski) račun pri katerikoli kreditni instituciji, registrirani v Republiki Sloveniji, podružnici tuje kreditne institucije v Sloveniji ali kreditne institucije, registrirane v drugi državi članici Evropske unije, ki je odprt v imenu investitorja.</p>
            <p>Profil investitorja - Operativna platforma omogočena na spletni strani, ki je na voljo investitorju po registraciji na spletni strani (z elektronskim naslovom in geslom). Profil investitorja med drugim vsebuje informacije o transakcijah, ki jih izvaja investitor, in o stanju profila investitorja.</p>
            <p>Stanje profila investitorja - znesek sredstev, ki se šteje kot predujem sredstva za izpolnitev mandata Investitorja za odkup za plačilo cene terjatve, tj. nakupa terjatve. Informacije o stanju profila investitorja se navedejo v profilu investitorja na spletni strani. Stanje profila investitorja se lahko poveča, zmanjša, lahko se odtegnejo kakršni koli odbitki, tudi pri izplačilu investitorju skladno z Asignacijsko pogoodbo, z njim pa se lahko sprejmejo tudi drugi ukrepi v skladu s postopkom, določenim v pogodbi in / ali zakonu. Občasno ima lahko profil investitorja negativno stanje, ki pa se šteje za dolg investitorja do NEKSTER-ja, ki ga mora investitor poravnati nemudoma po vzpostavitvi negativnega stanja profila investitorja.</p>
            <p>Pogoji uporabe spletne strani – ti pogoji in določila uporabniške pogodbe NEKSTER na spletni strani in pogoji uporabe spletne strani, opisani v drugih pogodbah med NEKSTER-jem in investitorjem, ter drugi pogoji, ki so določeni na spletni strani, ki zavezujejo vsakega investitorja.</p>
            <p>Uporabniška pogodba (v nadaljnjem besedilu: Pogodba) – pogodba na daljavo med NEKSTER-jem in investitorjem o načinu uporabe spletne strani in profila investitorja ter mandatu skladna s pogoji uporabe spletne strani.</p>
            <p>Spletna stran - Spletna stran z naslovom www.nekster.eu, kjer se lahko fizične in pravne osebe registrirajo kot investitorji, ustvarijo profil investitorja in pridobijo informacije o odkupu terjatev. Aplikacija se za potrebe pogodbe šteje za sestavni del spletne strani.</p>
            <p>Servisiranje (podpora) terjatev - dejavnosti, ki jih NEKSTER izvaja v skladu in v povezavi s Pogodbo in  Asignacijsko pogodbo, prodaji terjatev, prenosu sredstev na Asignatarja po pooblastilu Asignanta in drugih dejavnosti, navedenih na spletni strani.</p>
            <p>Cena terjatve - plačilo, ki ga Asignatar plača Aasignantu za terjatev. Cena terjatve je določena v glavnih pogojih Asignacijske pogodbe.</p>
            <p>Terjatev - terjatev proti dolžniku, ki jo ima Cedent in kot jo določajo glavni pogoji Asignacijske pogodbe v celoti ali v kateremkoli njenem delu. Znesek terjatve je določen v glavnih pogojih Asignacijske pogodbe.</p>
            <p>Vloga za registracijo - Vloga, ki jo fizična oseba ali pravna oseba na spletni strani izpolni za pridobitev statusa investitorja.</p>
            <p>Tretja oseba - vsaka fizična oseba ali pravna oseba, ki ni stranka pogodbe.</p>
            <p>2. INVESTITORJEVA REGISTRACIJA NA SPLETNI STRANI</p>
            <p>2.1. Za registracijo na spletni strani in oblikovanje profila investitorja mora investitor na spletni strani izpolniti prijavo za registracijo in sprejeti pogoje pogodbe in pogoje uporabe spletne strani.</p>
            <p>2.2. Investitor potrjuje ter jamči, da v trenutku predložitve prijave za registracijo, tekom registracije na spletni strani in med celotnim časom uporabe profila investitorja, izpolnjuje naslednje pogoje:</p>
            <p>2.2.1. investitor je fizična oseba ali pravna oseba;</p>
            <p>2.2.2. če je investitor fizična oseba, mora biti star vsaj 18 (osemnajst) let;</p>
            <p>2.2.3. odprt tekoči (transakcijski) račun v kreditni instituciji, registrirani v Republiki Sloveniji, podružnici tuje kreditne institucije v Sloveniji ali katere koli kreditne institucije, registrirane v kateri koli drugi državi članici Evropske unije;</p>
            <p>2.2.4. ima pravno in poslovno sposobnost ter njegova pravna ali poslovna sposobnost ni bila omejena;</p>
            <p>2.2.5. ni pod vplivom alkoholnih, narkotičnih, psihotropnih ali drugih opojnih snovi;</p>
            <p>2.2.6. zoper njega ni bil sprožen stečajni postopek ali kakršenkoli podoben postopek;</p>
            <p>2.2.7. ima potrebna dovoljenja in soglasja, če je to potrebno, da se lahko registrira na spletni strani, sklene Asignacijsko pogodbo in deluje skladno s Pogodbo in Asignacijsko pogodbo;</p>
            <p>2.2.8. predložene informacije v vlogi za registracijo in tudi v času veljavnosti pogodbe, so resnične in točne, posredovani osebni podatki se skladajo s tistimi na osebnem dokumentu</p>
            <p>2.2.9. je upravičenec transakcij, ki jih izvaja na spletni strani.</p>
            <p>2.3. NEKSTER po prejemu prijave investitorja za registracijo in njeni potrditvi, oblikuje profil investitorja na spletni strani in investitorju dodeli identifikacijsko številko investitorja.</p>
            <p>2.4. Storitve, navedene na spletni strani, lahko prejmejo registrirani investitorji le v skladu s pogoji  Pogodbe, profil investitorja pa sme uporabiti le registrirani investitor sam, ki se pred uporabo profila investitorja identificira z navedbo svojega e-poštnega naslova in gesla.</p>
            <p>2.5. NEKSTER ni dolžan registrirati investitorja in lahko zavrne registracijo investitorja brez obrazložitve.</p>
            <p>2.6. NEKSTER ima pravico zahtevati verifikacijo posredovana telefonske številke na način, da na Investitorju pošlje SMS potrditveno kodo, ki jo Investitor vpiše v ustrezen obrazec ob registraciji.</p>
            <p>3. IDENTIFIKACIJA INVESTITORJA IN SKLENITEV POGODBE</p>
            <p>3.1. NEKSTER za skladnost poslovanja opravlja pregled stranke oz. investitorja (skrbni pregled)  ob registraciji na spletni strani. V primeru neznatnega tveganja pa si NEKSTER pridržuje pravico, da tekom sklepanja poslovnega razmerja oz. na podlagi prvega prenosa za povečanje stanja profila investitorja opravi še preverjanje istovetnosti stranke (investitorja) skladno z zakonsko dopustnim razlogom, ker je to potrebno da se ne prekine običajen način sklepanja poslovnih razmerij. NEKSTER si pridržuje pravico preklica zagotavljanja storitev v kolikor se podatki na plačilnem nalogu za povečanje stanja profila investitorja ne ujemajo s podatki, ki jih je investitor predložil ob registraciji.</p>
            <p>3.2. Investitor mora NEKSTER-ju z elektronskimi sredstvi omogočiti vpogled v veljaven osebni dokumenta. NEKSTER pri tem preverja veljavnost osebnega dokumenta. NEKSTER ima pravico prepovedati investitorju, da odkupuje terjatve, dokler investitor NEKSTER-ju na vpogled ne predloži svojega veljavnega osebnega dokumenta in dokler NEKSTER  ne preveri njegove veljavnosti.</p>
            <p>3.3. Za namene identifikacije si NEKSTER pridržuje pravico, da od investitorja kadarkoli zahteva dodatno identifikacijsko dokumentacijo ali dodatne informacije, ki bi potrdile istovetnost investitorja oz. druge informacije o investitorju.</p>
            <p>3.4. NEKSTER ima pravico enostransko spreminjati pravila identifikacije investitorja in postopek identifikacije ter določiti dodatne zahteve za identifikacijo investitorja.</p>
            <p>3.5. S tem ko investitor odda vlogo za registracijo in nakaže sredstva iz svojega bančnega računa na NEKSTER-jev bančni račun, sklepa Pogodbo, uporablja spletno stran in druge storitve, ki so ponujene skladno s Pogodbo in pogoji uporabe spletne strani.</p>
            <p>3.6. Pogodba med NEKSTER-jem in investitorjem začne veljati od trenutka, ko je investitor na spletni strani sprejel pogoje uporabe spletne strani, NEKSTER pa je identificiral investitorja.</p>
            <p>3.7. Investitor je obveščen o sklenitvi pogodbe s prejemom elektronskega sporočila. Profil investitorja vsebuje označbo, da je bila pogodba sklenjena.</p>
            <p>3.8. Investitor se lahko seznani s sklenjeno pogodbo, ter z vsemi opravljenimi in odobrenimi dejavnostmi na spletni strani, vključno s sklenjenimi pogodbami, v svojem profilu investitorja.</p>
            <p>3.9. Overitev investitorja v profilu investitorja izvede investitor sam z vnosom svojega e-poštnega naslova in gesla.</p>
            <p>3.10. Investitor ne sme razkriti svojega gesla za profil investitorja tretjim osebam. V primeru, da je bilo geslo razkrito tretji osebi, ga je investitor dolžan takoj spremeniti in o tem nemudoma obvesti NEKSTER z zahtevo po blokadi profila do spremembe gesla.</p>
            <p>3.11. Če je investitor pravna oseba, ki je vložila vlogo za registracijo in je iz lastnega bančnega računa prenesla sredstva na NEKSTER-jev bančni račun, ima NEKSTER pravico identificirati zakonitega zastopnika investitorja oz. drugo fizično osebo, ki je upravičenec investitorja, in identificirati ustrezno pravno osebo kot investitorja.</p>
            <p>4. STANJE PROFILA INVESTITORJA</p>
            <p>4.1. S pogodbo skladen prenos sredstev na NEKSTER-jev bančni račun se skladno z določbami XVII. poglavja Obligacijskega zakonika republike Slovenije - Pogodba o naročilu (mandat), šteje kot sredstva potrebna za izpolnitev naročila, to je odkupa terjatev od Asignanta. Relevantni znesek denarnih sredstev je terjatev (nasproti banki), ki jo je NEKSTER pridobil v imenu in za račun Investitorja  zgolj in le za izpolnitev mandata pooblaščeni osebi. NEKSTER po prejemu sredstev poveča stanje profila investitorja za vsoto zneska prejetih sredstev. Investitor potrjuje, da pri prenosu sredstev na NEKSTER-jev bančni račun ne bo v celoti položil omenjenih sredstev kot del sredstev NEKSTER-ja, ampak bo namesto tega obdržal potrebne pravice zahtevka nasproti NEKSTER-ju za znesek naveden v Investitorjevem profilu. Prenesena sredstva ne bodo obrestovana.</p>
            <p>4.2. Pozitivno stanje profila investitorja se šteje kot predplačilo investitorja NEKSTER-ju za namen nakupa terjatev s postopkom predpisanim v pogodbi in na podlagi mandata. Negativno stanje profila investitorja je dolg do NEKSTER-ja, ki ga mora investitor na zahtevo NEKSTER-ja plačati nemudoma po vzpostavitvi negativnega stanja profila investitorja.</p>
            <p>4.3. Investitor lahko nakaže sredstva na profil investitorja le v svojem imenu in le z lastnega bančnega računa. Od drugega nakazila sredstev pa lahko nakazilo izvede tudi s strani drugih ponudnikov plačilnih storitev.</p>
            <p>4.4. Za nakazilo sredstev na profil investitorja mora investitor v nalogu navesti identifikacijsko številko investitorja.</p>
            <p>4.5. Če NEKSTER od investitorja prejme nakazilo, ki ga ni mogoče identificirati kot tistega, ki ga je izvedel investitor za nakazilo sredstev na profil investitorja, se takšno plačilo ne bo štelo za prejeto in nadaljnja nakazila sredstev na profil investitorja ne bodo možna, dokler NEKSTER ne identificira tega plačila. NEKSTER ima pravico do vračila nakazila, ki ga ne more identificirati.</p>
            <p>4.6. Stanje profila investitorja se lahko poveča tudi v vrstnem redu, ki ga določajo Asignacijske pogodbe, ko se stanje profila investitorja poveča za prejeta nakazila v skladu z Asignacijsko pogodbo.</p>
            <p>4.7. Investitor ne sme zahtevati nobenih plačil obresti od NEKSTER-ja, vključno z zakonitimi obrestmi ali kakršnimkoli drugim obrestovanjem stanja profila investitorja.</p>
            <p>4.8. Investitor pooblašča NEKSTER-ju, da uporabi stanje profila investitorja tako, da ga zmanjša v mejah razpoložljivega stanja v skladu s pogoji in določili Pogodbe ter v skladu z naročili, ki jih investitor izvede v Profilu investitorja.</p>
            <p>4.9. Investitor lahko kadarkoli od NEKSTER-ja zahteva, da mu vrne znesek sredstev, ki izhaja iz stanja profila investitorja, in sicer na bančni račun, iz katerega je investitor prej opravljal transakcije na bančni račun NEKSTER-ja. V primeru bistveno spremenjenih okoliščin, ki so posledica višje sile, kot dogodka,  ki ga ni bilo mogoče pričakovati, se mu izogniti ali ga odvrniti in je bil povzročen od zunaj in ni neposredna posledica ravnanja katere izmed strank, kar med drugim vključuje ni pa omejeno na izredne razmere, naravne nesreče, pandemije in druge ukrepe državnih ali javnih organov, ki imajo posredne ali neposredne posledice na poslovanje NEKSTER-ja, bo NEKSTER pristopil k investitorju s predlogom sprememb pravic in obveznosti, kot izhajajo iz predmetnih pogojev uporabe spletne strani, kar med drugim vključuje ni pa omejeno na roke.</p>
            <p>4.10. Ko NEKSTER prejme zahtevo investitorja glede izplačila zneska sredstev iz stanja profila investitorja in začne z njegovo obdelavo, ima pravico zavrniti kakršnokoli uporabo zahtevanega zneska sredstev iz stanja za investiranje ali sprejemanje drugih ukrepov s tem zneskom.</p>
            <p>4.11. NEKSTER mora v roku 2 (dveh) delovnih dni po dnevu, ko se šteje, da je bila investitorjeva zahteva prejeta, zagotoviti izplačilo zahtevanega zneska sredstev iz stanja profila investitorja ali njegovega dela v mejah razpoložljivega stanja. Če ni mogoče izplačati zahtevanega zneska sredstev iz stanja v zgoraj navedenem roku zaradi napake investitorja ali katerega koli drugega razloga izven nadzora NEKSTER-ja, se šteje, da je omenjeni rok podaljšan, dokler niso odpravljene vse ovire, in NEKSTER ne odgovarja za zamudo pri plačilu zaradi teh razlogov.</p>
            <p>4.12. Stanje profila investitorja se zmanjša za znesek, ki je bil izplačan investitorju.</p>
            <p>4.13. Za zagotovitev izpolnjevanja Pogodbe, Asignacijske pogodbe ali katerih koli drugih ukrepov lahko NEKSTER brez predhodnega usklajevanja ali odobritve investitorja zmanjša stanje profila investitorja, tako da preusmeri sredstva na bančni račun NEKSTER-ja ali katere koli druge osebe skladno z dejanji investitorja na spletni strani in na profilu investitorja.</p>
            <p>4.14. Investitor zagotavlja, da so vsa nakazana sredstva na profil investitorja zakonitega izvora, ki ga lahko investitor kadarkoli dokaže.</p>
            <p>4.15. Investitor je seznanjen s prepovedjo nakazovanja sredstev nezakonitega izvora na svoj profil investitorja. V primeru sumljivih aktivnosti lahko NEKSTER obvesti pristojne organe in blokira dostop investitorja do profila investitorja.</p>
            <p>4.16. Če je profil investitorja zaprt in / ali izbrisan na pobudo NEKSTER-ja, lahko NEKSTER brez predhodnega opozorila ali koordinacije z investitorjem vrne znesek celotnega stanja na katerikoli investitorjev bančni račun, iz katerega je investitor predhodno opravil prenos sredstev na NEKSTER.</p>
            <p>4.17. NEKSTER izpolni svoje obveznosti glede vračila sredstev investitorju, ko po lastni presoji izplača sredstva kateregakoli bančnega računa NEKSTER-ja. NEKSTER ne prevzema odgovornosti za dobroimetje teh sredstev na bančnem račun investitorja. Če so sredstva po nakazilu investitorju vrnjena NEKSTER-ju iz kakršnegakoli razloga, NEKSTER ni dolžan obvestiti investitorja o vračilu sredstev. V vsakem primeru mora investitor sam preveriti podatke o prejemu sredstev in zagotoviti, da lahko sprejme vračilo sredstev, ki ga je opravil NEKSTER na bančni račun, s katerega je NEKSTER predhodno prejel sredstva od investitorja.</p>
            <p>4.18. NEKSTER je upravičen, ne pa zavezan vrniti sredstva na drug bančni račun investitorja (razen tistega, ki je naveden), le če investitor potrdi, da so bili vsi bančni računi investitorja, iz katerih je bil prej opravljen prenos sredstev na bančni račun NEKSTER zaprti in se zato vračila sredstev ne morejo izvesti.</p>
            <p>4.19. NEKSTER ima pravico zadržati del sredstev, predvidena za izplačilo v primeru dolga investitorja do NEKSTER-ja.</p>
            <p>4.20. NEKSTER je upravičen, da zadrži iz stanja profila investitorja in / ali sredstva namenjena za nakazilo investitorju v primeru, da je NEKSTER zavezan k zadržanju na podlagi zakona ali drugega pravnega sredstva.</p>
            <p>5. NAKUP TERJATEV</p>
            <p>5.1. Ko NEKSTER identificira investitorja, lahko investitor odkupi terjatve, ponujene na spletni strani, pod pogojem, da je stanje profila investitorja zadostno za nakup izbrane terjatve.</p>
            <p>5.2. Pri odkupu terjatev mora investitor upoštevati naslednje pogoje:</p>
            <p>5.2.1. Investitor ima pravico izbrati eno ali več terjatev iz sklopa vseh terjatev, ki so na voljo na spletni strani;</p>
            <p>5.2.2. Če investitor želi odkupiti le del terjatve, mora navesti znesek, ki ga je  pripravljen plačati za del te terjatve;</p>
            <p>5.2.3. Investitor ima pravico odkupiti neomejeno število terjatev zoper cedenta tako v celoti kot v kakršnemkoli deležu;</p>
            <p>5.2.4. Investitor lahko odkupi terjatve za znesek sredstev, ki ne presega razpoložljivega stanja profila investitorja.</p>
            <p>5.3. NEKSTER beleži investitorjeve terjatve za odkup terjatev v kronološkem vrstnem redu, začenši z najstarejšimi. NEKSTER ima pravico spremeniti vrstni red odkupa terjatev.</p>
            <p>5.4. Z vložitvijo zahteve za odkup terjatve investitor nepreklicno pooblasti NEKSTER-ja, da uporabi razpoložljivo stanje profila investitorja, da plača ceno terjatve asignantu.</p>
            <p>5.5. Po izbiri terjatve in navedbi cene (s katero investitor soglaša) po kateri bo kupljena terjatev, bo NEKSTER obvestil investitorja na profilu investitorja pri izbranih terjatvah in investitorju predložil Asignacijsko pogodbo v pregled in odobritev. Če se investitor strinja s sklenitvijo Asignacijske pogodbe, to potrdi v svojem profilu investitorja.</p>
            <p>5.6. Asignacijska pogodba se šteje za sklenjeno v trenutku, ko  investitor potrdi pogoje določene v Asignacijski pogodbi, NEKSTER pa po pooblastilu Investitorja nameni potreben del sredstev iz stanja profila investitorja Asignantu za plačilo terjatve.</p>
            <p>5.7. Po sklenitvi Asignacijske pogodbe je sklenjena Asignacijska pogodbe na voljo na profilu investitorja za celotno obdobje njene veljavnosti.</p>
            <p>5.8. NEKSTER zagotovi informacije o terjatvi, ki je predmet Asignacijske pogodbe na profilu investitorja, ter tudi informacije glede poplačila terjatve.</p>
            <p>5.9. Vse dokumente v zvezi s terjatvijo bo hranil asignant ali druga oseba, navedena v Asignacijske pogodbe, ti dokumenti pa ne bodo prenešeni na Asignatarja.</p>
            <p>5.10. V primeru, da je Asignatar odkupil le del terjatve, je Asignatar seznanjen in se strinja, da terjatev ne vključuje vseh terjatev Asignanta do dolžnika, in je NEKSTER upravičen opravljati storitve v zvezi s terjatvijo samo v korist Asignatarja za tisti del terjatve do dolžnika, ki jo je pridobil Asignatar.</p>
            <p>5.11. NEKSTER izvaja plačila po pooblastilu Asignanta ter Asignatarjem nakaže vsa prejeta sredstva, ki jih je prejel za izpolnitev obveznosti iz Asignacijske pogodbe, s čimer se poveča Asignatarjevo stanje profila investitorja skladno z zneskom in postopkom, določenim v Asignacijski pogodbi.</p>
            <p>5.12. Pred povečanjem stanja profila investitorja skladno z Asignacijsko pogodbo (pooblastilo Asignanta NEKSTER-ju da poplača terjatev Asignatarju) je je NEKSTER upravičen zadržati del sredstev  v skladu s cenikom, navedenim na spletni strani.</p>
            <p>5.13. Investitor se lahko seznani z vsemi dejanji, ki se izvajajo v profilu investitorja, vključno z opravljenimi in prejetimi plačili, ob upoštevanju omejitev, ki so določene v pogodbi.</p>
            <p>5.14. V primeru, da so bila dejanja investitorja pri uporabi profila investitorja zabeležena nepravilno, je NEKSTER upravičen popraviti napake in ustrezno spremeniti profil investitorja.</p>
            <p>5.15. V primeru, da NEKSTER ugotovi, da ima profil investitorja negativno stanje, NEKSTER o tem obvesti investitorja, ki je dolžan poravnati stanje profila investitorja s takšnim zneskom, ki zadošča za popolno kritje negativnega stanja, najkasneje v roku 5 (petih) dni po prejemu obvestila od NEKSTER-ja.</p>
            <p>5.16. Posamične investicije ponujene Investitorju so označeni z Failure Score Dolžnika bonitetne hiše Bisnode d.o.o. in Nekster Ranking-om, ki je interna finančna ocena NEKSTER-ja pripravljena na podlagi Dolžnikovega vestnega in pravočasnega izpolnjevanja pogodbenih obveznosti.</p>
            <p>5.17. Vsaka investicija gre pred objavo skozi dvostopenjski sistem preprečevanja nastanka slabih posojil. Poleg tega se investicije še dodatno zavarujejo proti neplačilu pri zavarovalnici Acredia Versicherung AG, Euler Hermes Group, skladno z zavarovalno pogodbo (link).</p>
            <p>NEKSTER za določene investicije omogoča tudi dodatna jamstva - Buyback Guarantee (BG – jasmtvo odkupa) ali Payment Guarante (PG – jamstvo plačila).</p>
            <p>PG – investicija s to oznako ima jamstvo, da je investicija zavarovana pri zavarovalnici proti neplačilu. Če pride do škodnega primera tako zavarovane investicije, se po 30-ih dneh začne postopek izterjave. Če v obdobju 30 dni po začetku škodnega primera ni prišlo do poplačila, zavarovalnica v skladu z zavarovalno polico poplača škodo v skladu z zavarovalno polico.</p>
            <p>BG – oznaka omogoča investitorju, da investicijo kadarkoli prekine. Vključuje dodatno jamstvo z zavarovanjem pri zavarovalnici proti neplačilu. Če pride do škodnega primera tako zavarovane investicije, NEKSTER investicijo po 30-ih dneh (31. dan) od nastanka škodnega primera odkupi nazaj, sam pa nadaljuje z izterjavo skladno z zavarovalno polico.</p>
            <p>Investicije objavljene na platformi so dodatno z varnostnim skladom. V varnostni sklad NEKSTER prispeva sredstva na podlagi stopnje tveganja neplačil, kar zagotavlja izplačilo škod BG najkasneje v roku 30 dni po nastanku škodnega primera. Za škodni primer se šteje terjatev, ki ustreza kriterijem iz splošnih pogojev zavarovalnice Acredia Versicherung AG (link).</p>
            <p>5.18. Na podlagi določb v teh pogojih so investicije v Profilu investitorja označene z naslednjim statusom - Aktivna, Izplačana, Zamuda, Izterjava, Neplačilo, Škodni primer, Poplačilo.</p>
            <p>6. AVTOMATIČNO INVESTIRANJE</p>
            <p>6.1. Investitor ima pravico odkupiti terjatve z uporabo avtomatiziranega odkupa terjatev "Avto invest" (v nadaljnjem besedilu "Avto invest") v profilu investitorja. NEKSTER ponuja tri oblike Avto invest in sicer dve predpripravljeni - Avto invest Strategijo, ki omogoča največjo možno stopnjo likvidnosti (High-liquidity strategy) pri čemer je investiranje omejeno zgolj v investicije z oznako BG (Buyback Guarantee oz. Jamstvo odkupa) ter Avto invest Strategijo, ki omogoča največjo možno stopnjo donosnosti (High-return strategy) pri čemer je investiranje omejeno zgolj v investicije z oznako PG (Payment Guarante oz. Jamstvo plačila); in Prilagojeno Avto invest Strategijo (Custom strategy) pri kateri Investitor sam postavi parametre investicij v katere je pripravljen vložiti pri čemer pa dejanska izvedba investicije vseeno ostane avtomatizirana.</p>
            <p>6.2. Če želi investitor aktivirati Avto invest v profilu investitorja, mora podati soglasje za sklenitev Asignacijske pogodbe v skladu z besedilom pogodbe, ki velja na dan sklenitve Asignacijske pogodbe, ter soglašati s pogoji v Asignacijski pogodbo, ki velja v trenutku aktiviranja Avto investa.</p>
            <p>6.3. Aktivacijo Avto investa investitor opravi v svojem profilu investitorja, pri čemer bo NEKSTER nato po pooblastilu in parametrih izbral terjatve za investitorja.</p>
            <p>6.4 Investitor mora zagotoviti zadostno stanje profila investitorja za nakup terjatev z uporabo Avto investa.</p>
            <p>6.5. Z aktiviranjem  Avto investa  investitor pooblasti NEKSTER za sklepanje Asignacijskih pogodb za tiste terjatve, ki ustrezajo parametrom Avto investa določene s strani NEKSTER-ja, zmanjšanje stanja profila investitorja za znesek terjatev, ki ustrezajo tem postavljenim parametrom NEKSTER-ja, in za uporabo sredstev za plačilo cene terjatve asignantom in za registracijo terjatev v profilu investitorja.</p>
            <p>6.6. Investitor ima pravico spremeniti ali prekiniti delovanje Avto investa med celotnim trajanjem pogodbe.</p>
            <p>6.7. NEKSTER ni dolžan dodatno aktivno obveščati investitorja o terjatvah, pridobljenih med uporabo Avto investa in o pravicah in obveznostih, ki izhajajo iz tega. Investitor se mora seznaniti z dokumenti, obvestili in drugimi informacijami v profilu investitorja, ki se nanašajo na investitorja in na terjatve, ki jih je po njegovem pooblastilu odkupi NEKSTER v njegovem imenu.</p>
            <p>6.8. Investitor je seznanjen in razume, da se lahko Asignacijske pogodbe sklenejo na podlagi pogojev, ki se razlikujejo od tistih, ki so veljali v trenutku aktiviranja  Avto investa, pod pogojem, da so pogodbe od odstopu terjatev vedno skladne z vsaj tistimi parametri, ki jih je odobril investitor med aktiviranjem Avto investa.</p>
            <p>6.9. Investitor mora neodvisno spremljati informacije o trenutnih pogojih Asignacijskih pogodb, ki so na voljo na spletni strani in profilu investitorja. Če investitor ni predložil zahteve NEKSTER-ju za prenehanje  storitve odkupa terjatev Avto investa, se šteje, da se je investitor seznanil ter strinjal s pogoji in sklenil vse Asignacijske pogodbe.</p>
            <p>6.10. NEKSTER ni dolžan zagotoviti investitorju možnosti aktiviranja in uporabe Avto investa za odkup terjatev. NEKSTER lahko kadarkoli enostransko brez obrazložitve odpove storitev Avto invest z obvestilom investitorju.</p>
            <p>6.11. Investitor ima pravico, da enostransko odstopi (smiselno preklic) od investicij z oznako BG pred pogodbeno določenim rokom izpolnitve Asignacijske pogodbe (zapadlosti investicije), pri čemer bo NEKSTER  znesek investicije skupaj z dosežnimi obrestmi na dan preklica izplačal po zapadlosti investicije oz. Asignacijske pogodbe od katere je investitor odstopil pri čemer je glede na določbe 9. poglavja Asignacijske pogodbe (Jamstvo odkupa terjatve in pravice) ta rok podaljšan glede na določila tega poglavja.</p>
            <p>V primeru investicij z oznako PG investitor nima pravice, da enostransko odstopi (smiselno preklic) pred pogodbeno določenimi rokom izpolnitve Asignacijske pogodbe (zapadlosti investicije).</p>
            <p>6.12. Investitor je na poziv NEKSTERja dolžna potrditi nadaljevanje Avto investa najmanj enkrat vsaki 2 leti pri čemer si NEKSTER pridržuje pravico, da investitorja pozove, da nadaljevanje Avto investa potrdi tudi v krajšem časovnem roku.</p>
            <p>7. PRAVICE IN OBVEZNOSTI DRUŽBE NEKSTER</p>
            <p>7.1. NEKSTER je zavezan k izpolnitvi pogodbe in zagotoviti servisiranje terjatev v skladu s pogoji in določili Pogodbe, pogoji in določili Asignacijskih pogodb in pogoji uporabe spletne strani.</p>
            <p>7.2. NEKSTER bo v skladu s Pogodbo servisiral terjatve in izvajal vse dejavnosti v zvezi s servisiranjem v interesu Asignatarja.</p>
            <p>7.3. Investitor pooblašča NEKSTER-ja, da servisira terjatve v imenu NEKSTER-ja vendar na račun investitorja.</p>
            <p>7.4. NEKSTER ni odškodninsko odgovoren investitorju oz. Asignatarju, njunim pravnim naslednikom ali dedičem ali katerikoli tretji osebi v primeru, da cedent v celoti ali v kateremkoli  delu ne izpolni Odstopa terjatve s pogodbo in če se terjatev ne poplača in poravna.</p>
            <p>7.5. NEKSTER opravi plačila v korist Asignatarja in zadrži izplačila iz stanja profila investitorja samo v skladu s postopki, določenimi v pogodbi.</p>
            <p>8. PRAVICE IN OBVEZNOSTI INVESTITORJA</p>
            <p>8.1. Investitor potrjuje, da razume določbe pogojev uporabe spletne strani, Odstop terjatve s pogodbo in Asignacijske pogodbe. Investitor se odpoveduje morebitnim zahtevkom proti NEKSTER-ju na podlagi enostranskih pogojev prej omenjenih pogojev in pogodb.</p>
            <p>8.2. Vse dejavnosti, ki se izvajajo v profilu investitorja, se štejejo za dejavnosti, ki jih opravlja sam investitor in se tako priznajo kot zavezujoče za investitorja.</p>
            <p>8.3. Investitor upošteva določbe Pogodbe in določbe drugih pogodb, ki so zavezujoče za investitorja in so sklenjene z NEKSTER-jem.</p>
            <p>8.4. Investitor zagotovi zadostno stanje profila investitorja, tako da se lahko izpolni Pogodba in da se lahko pokrijejo vsa plačila, ki izhajajo iz nje.</p>
            <p>8.5. Če investitor ni zagotovil zadostnega zneska na stanju profila investitorja, NEKSTER ni dolžan izpolnjevati investitorjevih navodil, transakcij ali plačil.</p>
            <p>8.6. Investitor tretjim osebam ne sme razkriti informacij v zvezi s pogodbo, ki bi lahko zadevali interese NEKSTER-ja ali Cedenta.</p>
            <p>8.7. Investitor se strinja, da ne bo zahteval informacije o samem Cedentu in ne bo začel opravljati drugih dejanj, ki niso predmet pogodbe in pogojev v povezavi s terjatvijo in / ali ne bo začel izterjave terjatve. Asignatar se osebno ali prek drugih pooblaščenih zastopnikov ne more obrniti na Cedenta na kakršenkoli način v povezavi s sklenjeno Asignacijsko pogodbo in pridobljenimi terjatvami.</p>
            <p>8.8. Asignatar ne sme preklicati pooblastila izdanega NEKSTER-ju v zvezi z opravljanjem storitev v povezavi s terjatvami in upravljanjem terjatev v celotnem obdobju veljavnosti pogodbe.</p>
            <p>8.9. Brez poseganja v druge obveznosti investitorja, določene v pogodbi, investitor:</p>
            <p>8.9.1. uporablja spletno stran le za dejavnosti, ki jih dovoljuje spletna stran in pogodba;</p>
            <p>8.9.2. zagotovi samo pravilne informacije med registracijo na spletni strani, med uporabo spletne strani in tudi pri sklepanju pogodb z NEKSTER-jem ali komuniciranjem z NEKSTER-jem;</p>
            <p>8.9.3. sprejme vse potrebne ukrepe za preprečevanje dostopa tretjih oseb do profila investitorja;</p>
            <p>8.9.4. pravočasno in na podlagi rokov, ki jih določi NEKSTER, nudi NEKSTER-ju informacije, ki se zahtevajo v profilu investitorja in / ali spletni strani.</p>
            <p>9. CENIK IN DRUGA PLAČILA</p>
            <p>9.1. Investitor nakaže plačilo  NEKSTER-ju za opravljene storitve v povezavi s terjatvijo v skladu s cenikom, ki je objavljen na spletni strani.</p>
            <p>9.2. Istočasno s sklenitvijo Asignacijske pogodbe investitor NEKSTER-ja brez predhodnega usklajevanja pooblasti, da za plačila uporabi stanje profila investitorja v skladu s cenikom, ki je objavljen na spletni strani.</p>
            <p>9.3. NEKSTER je upravičen, da zadrži plačila v skladu s cenikom, ki je objavljen na spletni strani, iz sredstev, ki jih prejme od Cedenta, pred povečanjem stanja profila investitorja za ustrezen znesek.</p>
            <p>9.4. Investitor je obveščen in se strinja, da lahko investitorjeva banka za prenos na račun investitorja zadrži provizijo v skladu s pogoji banke in njenega cenika.</p>
            <p>9.5. Investitor se zaveda, da obstajajo zakoni in predpisi, ki veljajo za investitorja, vključno z zakoni in predpisi, ki urejajo plačilo davka na dohodke, ki jih investitor prejme za terjatev. Investitor sam je odgovoren za plačilo vseh potrebnih davkov v skladu z zahtevami veljavnih zakonov in predpisov.</p>
            <p>10. ODGOVORNOST</p>
            <p>10.1. Pogodbeni stranki sta odgovorni za izpolnjevanje pogojev pogodbe, pogojev uporabe spletne strani in izpolnjevanja obveznosti, ki so tam predpisane.</p>
            <p>10.2. Investitor je odgovoren za vso škodo, ki jo povzročijo njegove dejavnosti (dejanja ali opustitve) NEKSTER-ju, Cedentu, Cesionarju ali katerikoli tretji osebi.</p>
            <p>10.3. NEKSTER je odgovoren samo za  škodo, ki jo zaradi svoje dejavnosti (dejanja ali opustitve) povzroči investitorju, v kolikor je NEKSTER-ju mogoče očitati nastanek takšne škode in le v obsegu stanja investitorjevega profila v trenutku nastanka škode.</p>
            <p>10.4. Med izvajanjem te pogodbe, NEKSTER pri uporabi sredstev komuniciranja ne odgovarja za škodo, nastalo zaradi motenj pri uporabi pošte, elektronskih ali drugih komunikacijskih sredstev, pa tudi tehnične opreme, ki zagotavlja ustrezne storitve NEKSTER-ja, vključno, vendar ne omejeno na napake v komunikaciji, nepravilno delovanje spletne strani, nepravilno delovanje elektronskih izmenjav podatkov in plačilnih sistemov (vključno s sistemi internetnega bančništva) kreditnih institucij.</p>
            <p>10.5. NEKSTER ni odgovoren za škodo, ki je bila ali bi lahko bila povzročena investitorju v zvezi s sklenitvijo Asignacijske pogodbe, vključno s Cedentovim neizpolnjevanjem obveznosti iz Odstopa terjatve s pogodbo ter povračilu in poravnavi terjatve.</p>
            <p>10.6. Investitor je obveščen in se strinja, da so NEKSTER in Asignanti odgovorni le za pristnost terjatve in ne odgovarjajo in ne morejo biti odgovorni za zavarovanje terjatve in za Cedentovo nezmožnost poravnave terjatve.</p>
            <p>10.7. NEKSTER ni in ne more biti odgovoren za obveznost Cedenta, da poravna terjatev. NEKSTER tudi ni dolžan povrniti ali nadomestiti Asignatarju terjatev, ki ni bila poravnana. Če pa relevantna Asignacijska pogodba določi obveznost, da NEKSTER ali katera koli druga oseba odkupi terjatev od Asignatarja ali katerekoli druge osebe, ki je zagotovila izpolnjenje obveznosti Cedenta, je ta oseba odgovorna za izpolnitev povratnega odkupa in / ali garancijskih obveznosti v skladu s postopkom, predpisanim v Asignacijski pogodbi.</p>
            <p>10.8. Pogodbeni stranki sta oproščeni odgovornosti zaradi neizpolnjevanja obveznosti, če je prišlo do neizpolnitve zaradi razlogov, ki niso pod nadzorom zadevne pogodbene stranke in so nastali zaradi okoliščin višje sile.</p>
            <p>11. ZAUPNOST IN OSEBNI PODATKI INVESTITORJA</p>
            <p>11.1. Vse informacije, ki izhajajo iz pogodbe in zadevajo Odstop terjatve s pogodbo ali Asignacijsko pogodbo, so zaupne. NEKSTER mora podatke o investitorju in Cedentu posredovati v obsegu, ki je zahtevan, dogovorjen in dovoljen z Odstopom terjatve s pogodbo, Pogodbo, Asignacijsko pogodbo in z zakoni in drugimi predpisi Republike Slovenije.</p>
            <p>11.2. Investitor priznava in se strinja, da z registracijo na spletni strani investitor NEKSTER-ju z izrecnim  soglasjem zagotovi  obdelavo podatkov, če so osebni podatki investitorja v skladu z določbami, ki so vključene v Pogodbi in potrjujejo pravilnost  osebnih podatkov.</p>
            <p>11.3. Z registracijo na spletni strani investitor soglaša, da NEKSTER dodaja osebne podatke investitorja, ki so na voljo NEKSTER-ju (informacije, ki jih je investitor ob registraciji na spletni strani posredoval, kot tudi informacije, ki so na voljo NEKSTER-ju v zvezi z izvajanjem pogodbe) v bazo podatkov o strankah.</p>
            <p>11.4. Za izvajanje pogodbe in za komunikacijo z investitorjem NEKSTER obdeluje osebne podatke investitorja, prav tako pa ima NEKSTER pravico obdelovati osebne podatke investitorja za vzpostavitev, dopolnitev, izvajanje in/ali prekinitev pogodbenih odnosov z investitorjem.</p>
            <p>11.5. Investitor je bil obveščen in se strinja, da lahko NEKSTER uporablja osebne podatke investitorja, vključno z naslovom, telefonsko številko, e-mail naslovom, da obvesti investitorja o vseh novicah in spremembah storitev, ki jih ponuja NEKSTER, ali pa da se obrne na investitorja glede izvajanja pogodbe.</p>
            <p>11.6. Izjava o varstvu osebnih podatkov NEKSTER, ki je sestavni del teh pogojev, podrobneje opredeljuje obdelavo osebnih podatkov investitorja.</p>
            <p>12. OMEJEVANJE, BLOKIRANJE IN ZAPIRANJE PROFILA INVESTITORJA</p>
            <p>12.1. NEKSTER ima pravico, da brez predhodnega obvestila omeji investitorjeve možnosti   uporabe spletne strani, blokira profil investitorja ali enostransko odpove pogodbo ter zapre in izbriše profil investitorja, če se zgodi kaj od naslednjega:</p>
            <p>12.1.1. če je investitor kršil pogoje Pogodbe, pogoje uporabe spletne strani ali pogoje  Asignacijske pogodbe;</p>
            <p>12.1.2. če je investitor NEKSTER-ju dal napačne ali nepravilne podatke;</p>
            <p>12.1.3. če investitor uporablja spletno stran za nezakonite namene;</p>
            <p>12.1.4. če NEKSTER sumi, da investitor za odkup terjatev uporablja premoženjske koristi, pridobljene s kaznivim dejanjem, financiranjem terorizma ali da je bil sam investitor vpleten v takšne dejavnosti;</p>
            <p>12.1.5. če NEKSTER enostransko prekine Asignacijsko pogodbo;</p>
            <p>12.1.6. če je v skladu z odločitvami, ki zavezujejo NEKSTER na podlagi zakona, sodne ali druge odločbe treba prekiniti sodelovanje z investitorjem.</p>
            <p>12.2. NEKSTER ima pravico blokirati profil investitorja, če je geslo vneseno nepravilno 5-krat (petkrat) zapored.</p>
            <p>12.3. Če NEKSTER sumi, da investitorjeve dejavnosti na spletni strani in / ali profilu investitorja ne opravlja sam investitor, lahko NEKSTER  zavrne dejanja na spletni strani in profilu investitorja in / ali blokira profil investitorja. V takem primeru ima NEKSTER pravico, da ne odobri nobenih dejanj na spletni strani in profilu investitorja in zadrži profil investitorja zaklenjenega, dokler NEKSTER ne stopi v stik z investitorjem in preveri identiteto investitorja, investitor pa potrdi sprejeta dejanja.</p>
            <p>12.4. Brez poseganja v pravice NEKSTER-ja, ki so predpisane v 12.1. členu teh pogojev, ima NEKSTER pravico zapreti in izbrisati profil investitorja v naslednjih primerih:</p>
            <p>12.4.1. če to zahteva investitor sam;</p>
            <p>12.4.2. če investitor ni odkupil nobene terjatve več kot 12 (dvanajst) zaporednih mesecev;</p>
            <p>12.4.3. če je postalo stanje na profilu investitorja negativno in ni poravnano v roku, kot ga določajo ti pogoji;</p>
            <p>12.4.4. če investitor krši pogoje pogodbe;</p>
            <p>12.5. NEKSTER Investitorju na nadzorni plošči omogoča vklop funkcionalnosti dvostopenjskem preverjanju pristnosti (Two-Factor Authentication) preko posredovana kode v obliki SMS sporočila.</p>
            <p>13. ZAČETEK VELJAVNOSTI IN SPREMEMBE POGODBE</p>
            <p>13.1. Pogodba med NEKSTER-jem in investitorjem je sklenjena, ko investitor na spletni strani potrdi pogoje uporabe spletne strani, sprejme pogoje v Pogodbi in ko NEKSTER identificira investitorja.</p>
            <p>13.2. Vsakokrat veljavna različica pogodbe in pogoji uporabe spletne strani, ki se občasno spremenijo, so na voljo na spletni strani in / ali na profilu investitorja.</p>
            <p>13.3. NEKSTER ima pravico enostransko spreminjati Pogodbo in pogoje uporabe spletne strani.</p>
            <p>13.4. Investitor mora samostojno in redno spremljati informacije na profilu investitorja, vključno s spremembami pogodbe in pogoji uporabe spletne strani.</p>
            <p>13.5. Če investitor še naprej uporablja storitve, ki so na voljo na spletni strani po začetku veljavnosti sprememb, se šteje, da se je investitor strinjal s spremembami pogodbe in / ali pogoji uporabe spletne strani.</p>
            <p>13.6. NEKSTER ima pravico spremeniti cene upravljanja terjatev in druge cene, ki so navedene na spletni strani in so povezani z zagotavljanjem ponujenih storitev, ter določiti dodatne cene tako, da investitorja o tem obvesti vsaj 10 (deset) dni vnaprej.</p>
            <p>14. PRENEHANJE POGODBE</p>
            <p>14.1. NEKSTER lahko enostransko odpove pogodbo tako, da o tem obvesti investitorja najmanj 10 (deset) dni vnaprej.</p>
            <p>14.2. Investitor ima pravico enostransko odpovedati pogodbo in zahtevati izbris profila investitorja, pod pogojem, da investitor nima aktivne terjatve, ki jo servisira NEKSTER, in v skladu s pogodbo investitor nima dolžniških obveznosti do NEKSTER-ja ali kakršnekoli  druge osebe v skladu z Asignacijskimi pogodbami, ki so vnesene na spletno stran.</p>
            <p>14.3. V primeru prenehanja pogodbe skladno s postopkom, ki je določen v teh pogojih, je investitorju zavrnjena uporaba profila investitorja ter možnost odkupa novih terjatev in sklenitev Asignacijskih pogodb.</p>
            <p>14.4. V primeru prenehanja pogodbe NEKSTER opravi prenos preostalega stanja v profilu investitorja na bančni račun investitorja ter odvede stroške servisiranja terjatev in druge stroške, če so nastali na podlagi pogodbe in/ali cenika.</p>
            <p>14.5. V primeru, da je NEKSTER razglašen za insolventnega:</p>
            <p>14.5.1. ne bo mogoče opravljati transakcij na spletni strani in v profilu investitorja, Pogodba se prekine in trenutno stanje na profilu investitorja se investitorju izplača v skladno z relevantno zakonodajo;</p>
            <p>14.5.2. investitor prejme informacije o Asignacijskih pogodbah, ki so sklenjene in se še vedno izvajajo na svoj e-mail naslov;</p>
            <p>14.5.3. to ne bo vplivalo na veljavnost Asignacijskih pogodb in druge pravice investitorja, ki izhajajo iz Asignacijskih pogodb.</p>
            <p>14.6. Za zagotovitev posredovanja informacij, kot določata člena 14.5.2 in 14.5.3 teh pogojev, lahko NEKSTER sodeluje s tretjimi strankami / ponudniki storitev.</p>
            <p>15. DRUGE DOLOČBE</p>
            <p>15.1. Pogodba se sklene v elektronski obliki v skladu s postopkom, določenim v pogodbi.</p>
            <p>15.2. Vse dejavnosti na spletni strani in profilu investitorja se izvajajo z uporabo valute EUR.</p>
            <p>15.3. Investitor se strinja, da je NEKSTER upravičen do komuniciranja z investitorjem 1) tako, da pošlje SMS in / ali pokliče mobilno številko investitorja, ki je navedena v profilu investitorja; 2) tako, da pokliče druge številke, navedene v profilu investitorja; 3) s pošiljanjem e-poštnega sporočila na e-poštni naslov, naveden v profilu investitorja; 4) s pošiljanjem redne ali priporočene pošte na naslov investitorja, navedenega v profilu investitorja. Za poštne pošiljke se šteje, da so bile prejete v roku 3 (treh) dni od oddaje na pošti. Sporočila poslana po elektronski pošti se štejejo za prejeta v roku 24 ur po pošiljanju.</p>
            <p>15.4. Vsa obvestila, prijave in informacije, ki so določene v pogodbi, se sporočajo z objavo na profilu investitorja, na poslovni naslov NEKSTER-ja, ki je naveden na spletni strani ali na NEKSTER-jev e- poštni naslov, naveden na spletni strani. Pošta se šteje za prejeto 5. (peti) dan od oddaje; vsaka elektronska pošta se šteje za prejeto na dan, ko je bila poslana. V primeru pošiljanja elektronske pošte po 17.00 uri ali na dan, ki je dela prosti dan ali praznik v Republiki Sloveniji, se šteje elektronska pošta za prejeto naslednji delovni dan.</p>
            <p>15.5. Pogodba velja do  izpolnitve vseh obveznostih, določenih v tej pogodbi.</p>
            <p>15.6. Za vsa pravna razmerja, ki izhajajo iz pogodbe, veljajo zakoni in predpisi Republike Slovenije.</p>
            <p>15.7. Vsi spori med pogodbenimi strankami, ki izhajajo iz pogodbe, se rešujejo v skladu s postopkom, določenim v zakonih in predpisih Republike Slovenije.</p>
            <p>15.8. Investitorja zavezujejo določbe pogodbe v celotnem obdobju veljavnosti pogodbe oz. dokler profil investitorja ni izbrisan.</p>
            <p>15.9. NEKSTER je upravičen dodeliti vse pravice in terjatve, ki jih ima proti investitorju na podlagi pogodbe katerikoli tretji osebi brez predhodnega soglasja investitorja.</p>
            <p>15.11+0. Investitor razume in soglaša, da zabeležena sredstva v stanju profila investitorja niso depozit in posledično niso predmet jamstvenih shem.</p>
            <p>16. POOBLASTILO</p>
            <p>16.1. Asignacijske pogodbe lahko vključujejo pooblastilo za izvajanje:</p>
            <p>16.1.1. upravljanja s terjatvijo zoper dolžnika za in v imenu investitorja z uporabo vseh pravic in pooblastil, ki so bile opredeljene z Asignacijsko pogodbo;</p>
            <p>16.1.2. upravljanja s terjatvijo in sprejemanja vseh potrebnih ukrepov v zvezi s poplačilom terjatve in izvrševanjem Odstopa terjatve s pogodbo, dokler se v celoti ne poplača terjatev;</p>
            <p>16.1.3. brez predhodnega usklajevanja z investitorjem in po lastni presoji Cesionarja, napravijo spremembe in dopolnitve k Odstopu terjatve s pogodbo, vključno s podaljšanjem roka plačila terjatve, vendar ne več kot za 6 (šest) krat, pri čemer vsako od 6 (šestih) podaljšanj roka plačila terjatve ne sme biti daljše od 30 (trideset) dni, razen če je v Asignacijski pogodbi izrecno določeno drugače.</p>
            <p>16.2. Investitor je obveščen, da je pooblastilo, vključeno v Asignacijski pogodbi, veljavno v celotnem obdobju veljavnosti  Asignacijske pogodbe. Dejavnosti, ki jih opravi Cesionar in druge osebe v zvezi s tem, so zavezujoče za investitorja, investitor pa se odpove morebitnim zahtevkom do Cesionarja in drugih oseb v zvezi s tem.</p>
            <p>16.3. S sprejemom pogojev uporabe spletne strani investitor pooblasti NEKSTER-ja, da sprejme naslednje ukrepe:</p>
            <p>16.3.1. izvrši plačilo cene terjatve Asignantu z uporabo / zmanjšanjem stanja profila investitorja v ta namen;</p>
            <p>16.3.2. porazdeli prejeta plačila za poravnavo terjatev med investitorje (Asignatarje) v skladu s sklenjenimi Asignacijskimi pogodbami;</p>
            <p>16.3.3. zadrži vsa plačila v zvezi z Asignacijsko pogodbo v skladu s cenikom;</p>
            <p>16.3.4. da izvede druge ukrepe,  ki jih je upravičen izvajati v imenu investitorja v skladu s pogodbo.</p>
            <p>16.4. Investitor je bil obveščen in se strinja, da lahko NEKSTER v skladu s pogoji iz Asignacijske pogodbe kadarkoli brez predhodnega opozorila ali dogovora z investitorjem odkupi terjatev od investitorja (Asignatarja) s povečanjem stanja na profilu investitorja po ustrezni ceni odkupa določeni v Asignacijski pogodbi. Če je investitor sklenil Asignacijsko pogodbo, ki navaja, da je Asignant ali katerakoli druga oseba dolžna odkupiti  terjatev od investitorja, se terjatev v takšnem primeru odkupi ali odtegne od investitorja.</p>
            <p>16.5. Investitor je obveščen, da je pooblastilo NEKSTER-ju veljavno v celotnem obdobju veljavnosti pogodbe, zato so dejavnosti, ki jih v zvezi s tem izvaja NEKSTER, zavezujoče za investitorja, investitor pa se odreče zahtevkom proti NEKSTER-ju glede tega.</p>
            <p>Zadnje spremembe: 13.3.2020</p>
        </div>
    );
}

class TermsEng extends Component {
    render = () => (
        <div className="Register-Up-Content-Body-Section">
            <p>TERMS AND CONDITIONS OF THE NEKSTER WEBSITE USER AGREEMENT</p>
            <p>1. TERMS USED AND THEIR EXPLANATION</p>
            <p>Nekster - company NEKSTER d.o.o., Dunajska cesta 151, Ljubljana, 1000</p>
            <p>Ljubljana, Registration number: 6098371000, represented by director Nejc Apšner and director Aleksandar Zečevič.</p>
            <p>Assignment Agreement – a legal transaction by which the cedent resigns his claim to the cessionary.</p>
            <p>Cessionary – a person who has taken over a claim from the cedent on the basis of the Assignment Agreement</p>
            <p>Cedent – a person who has concluded the Assignment Agreement with the cessionary</p>
            <p>Application - NEKSTER application with which NEKSTER is entitled to provide the Investors with access rights to Investors’ profiles or access rights to the individual functionalities of the Investor's profile, by means of a mobile device.</p>
            <p>Assignor - a person who, in accordance with the Assignment Contract from the Assignor, is authorized to accept on its own behalf the fulfilment of obligations arising from the Assignment Agreement from the Assignor’s debtor.</p>
            <p>Assignment Contract - a contract concluded by Assignor, Assignor’s debtor and Assignee and is compromised by two unilateral transactions, namely Assignor’s authorization to Assignor’s debtor to pay the claim on his behalf to Assignee and Assignor’s authorization to Assignee to accept this fulfilment on his own behalf.</p>
            <p>Assignee’s bank account - a current account opened in the Assignor’s name with a credit institution registered in the Republic of Slovenia, foreign credit institution branch in Slovenia, or a credit institution registered in another European Union Member State.</p>
            <p>Assignee - a natural person or a legal entity, who is authorized to accept the repayment of the claim from the Assignor’s debtor.</p>
            <p>Price List - the fees specified on the Website which the Investor must pay to NEKSTER for the services regarding the Claim and for other services provided by NEKSTER.</p>
            <p>Investor - A natural person or a legal entity that has registered on the Website as an Investor and wants to purchase Claims of the Assignor that are offered on the Website, including a natural person or a legal entity that has purchased the Claim.</p>
            <p>Investors identification number - number assigned to the investor by NEKSTER and is needed for further identification of the investor and must be indicated when transferring funds for the purchase of the claim – balance of the Investor’s profile.</p>
            <p>Investor's bank account - A current account opened in Investor’s name with any credit institution registered in the Republic of Slovenia, foreign credit institution branch in Slovenia or a credit institution registered in another European Union Member State.</p>
            <p>Investor’s profile – Operational platform registered on the Website, which is available to the Investor after the registration on the Website (with e-mail address and password). Among other things, the Investor’s profile shall contain information regarding transactions conducted by the Investor and the balance of the Investor’s profile.</p>
            <p>Balance of the Investor’s profile - the amount of funds that are considered as an advance for the fulfilment of the Investor's mandate for repurchase for the payment of the cost of the claim, i. purchase of the claim. Information about the Investor's profile are indicated in the Investor profile on the Website. Balance of the Investor’s profile can be increased, reduced, any deductions can be deducted, even when paying to the investor in accordance with the Assignment Contract. Other measures may also be adopted in accordance with the procedure laid down in the contract and / or by the law. Occasionally, the Investor's profile may have a negative balance, which is considered as an investor’s debt to NEKSTER, which the investor must settle immediately after the negative balance of the Investor profile has been established.</p>
            <p>Website Terms of Use - these terms and conditions of the NEKSTER’s user agreement specified on the Website and the Website terms of use, described in other contracts between NEKSTER and the investor, as well as other terms and conditions set out on the website that bind each investor.</p>
            <p>User Agreement (hereinafter referred to as "the Contract") – a distance contract between NEKSTER and the investor on how to use the website and the Investor’s profile and the mandate is in compliance with the Website terms of use.</p>
            <p>Website - The internet website with the address www.nekster.eu, where natural persons or legal entities may register as Investors, make Investor's profile, and where there is available information on the acquisition of the Claims. Application within the meaning of this Assignment Contract is regarded as an integral part of the Website.</p>
            <p>Servicing the Claim - a set of actions carried out by NEKSTER in accordance with and in connection with the Agreement and the Assignment Contract for sale of claims and transfer of funds to the Assignee and other activities mentioned on the Website.</p>
            <p>Price of the Claim - A fee charged by the Assignor to the Assignee for the sale of the Claim. The Price of the Claim is set out in the Main Terms of the Assignment Contract.</p>
            <p>Claim - a claim against the debtor held by Cedent and as specified in the main terms and conditions of the Assignment Contract in its entirety or in any part thereof. The amount of the Claim is specified in the main terms and conditions of the Assignment Contract.</p>
            <p>Registration form - A form sheet, which is filled by a natural person or a legal entity on the Website for the purpose of registering as an Investor.</p>
            <p>Third party - Any natural person or legal entity that is not a contracting party to this Agreement.</p>
            <p>2. INVESTOR’S REGISTRATION ON THE WEBSITE</p>
            <p>2.1. In order to register on the Website and create an Investors profile, the Investor must fill out the registration form on the Website and accept the conditions of the Contract and the conditions for Website terms of use</p>
            <p>2.2. The Investor hereby confirms and guarantees that at the moment of submitting the Registration form, during the registration on the Website and during the entire time of use of the Investor’s profile, he/she meets the following conditions:</p>
            <p>2.2.1. the Investor is a natural person or a legal entity;</p>
            <p>2.2.2. if the Investor is a natural person, he/she must be at least 18 (eighteen) years old;</p>
            <p>2.2.3. the Investor has opened a current (transaction) account in the credit institution registered in the Republic of Slovenia, a branch of a foreign credit institution in Slovenia or any credit institution registered in any other European Union Member State;</p>
            <p>2.2.4. the Investor has legal capacity, and its legal capacity has not been restricted;</p>
            <p>2.2.5. the Investor is not under the influence of alcoholic, narcotic, psychotropic or other intoxicating substances;</p>
            <p>2.2.6. a bankruptcy procedure or any other similar procedure has not been initiated against the Investor;</p>
            <p>2.2.7. the Investor has all necessary permissions and consents if such are necessary to register on the Website, enter into Assignment Contract and take any actions in accordance with the Agreement and Assignment Contract;</p>
            <p>2.2.8. all information provided by the Investor in the Registration form and also during the validity of the Contract are accurate and true;</p>
            <p>2.2.9. the Investor is the beneficiary of the transactions made on the Website by the Investor.</p>
            <p>2.3. NEKSTER, after the receipt of the Investor’s Registration form, and after its conformation, NEKSTER shall create the Investor’s profile on the Website and assign the Investor’s identification number to the Investor.</p>
            <p>2.4. The services specified on the Website may only be received by registered Investors in accordance with the terms and conditions of the Contract, and the Investor’s profile may only be used by the registered Investor, who prior to using the Investor’s profile logs into the Investor’s profile by indicating its e-mail address and a password created by the Investor.</p>
            <p>2.5. NEKSTER is not obliged to register an Investor and may refuse Investor’s registration without explanation.</p>
            <p>2.6. NEKSTER has the right to request verification of the provided telephone number by sending to the Investor an SMS confirmation code, which the Investor enters in the appropriate form upon registration.</p>
            <p>3. INVESTOR’S IDENTIFICATION AND THE CONCLUSION OF THE CONTRACT</p>
            <p>3.1. NEKSTER conducts client review (due diligence) for business compliance review or business upon registration on the site. In the case of insignificant risk, NEKSTER reserves the right, during the conclusion of business relationship or during the first transfer of the funds for increase of investor profile, to verify the identity of investor in accordance with legally permissible reason, necessary to not interrupt the usual way of conducting business. NEKSTER reserves the right to terminate the provision of services in case that the information on the payment order for increasing the status of the investor profile does not match the information provided by the investor at registration.</p>
            <p>3.2. The Investor must submit to NEKSTER a copy of its valid personal identification document in an electronic form, by uploading it to the Investor’s profile at the place specified for that purpose. NEKSTER may check the validity of the document. NEKSTER is entitled to prohibit the Investor to acquire the Claims until the Investor has submitted to NEKSTER a copy of its valid personal identification document and until NEKSTER has checked its validity.</p>
            <p>3.3. For the purpose of identification, NEKSTER reserves the right to require additional identification documentation or additional information that would confirm the identity of the Investor or other information about the Investor.</p>
            <p>3.4. NEKSTER is entitled to unilaterally change the rules of identification of the Investor and the process of the identification and can define additional requirements for the identification of the Investor.</p>
            <p>3.5. By depositing the Registration form and transferring funds from its bank account to the NEKSTER bank account, Investor concludes the Contract, uses the website and other services that are offered in accordance with the Contract and the Website Terms of Use.</p>
            <p>3.6. The Contract between NEKSTER and the Investor shall come into force from the moment the Investor has accepted the Website Terms of Use on the Website and when NEKSTER has identified the Investor</p>
            <p>3.7. The Investor shall be informed about the conclusion of the Contract with the receipt of an electronic massage. Investor’s profile contains an indication that the contract has been concluded</p>
            <p>3.8. The Investor can become acquainted with the concluded Contract and with all performed and approved activities on the Website, including concluded contracts, in its Investor's profile.</p>
            <p>3.9. Authentication of the Investor in the Investor’s profile shall be done by the Investor providing its e-mail address and password.</p>
            <p>3.10. The Investor shall not disclose its Investor's password to any Third Party. If the password has become available to a Third Party, the Investor is obliged to immediately change it and at the same time promptly inform NEKSTER of the password becoming available to Third Party, with a request to block the Investor's Profile until the Investor changes the password.</p>
            <p>3.11. If the Investor is a legal entity which has filed the Registration form and has transferred funds from the Investor’s bank account to NEKSTER bank account, NEKSTER is entitled to identify the statutory representative or any other natural person who is the beneficiary of the Investor, and to identify the relevant legal entity as an Investor.</p>
            <p>4. BALANCE OF THE INVESTOR’S PROFILE</p>
            <p>4.1. Transfer of fund based on the Contract to NEKSTER's bank account is in accordance with the provisions of XVII. chapter of the Obligations Code of Republic of Slovenia – Order Contract (mandate), and is considered as the necessary instrument for the fulfilment of the order, that is purchasing the claims from the Assignor. The relevant amount of cash is essentially a claim (against the bank), which NEKSTER has acquired in the name and of account the Investor and only for the purpose of fulfilling the mandate of an authorized person. After the receipt of funds, NEKSTER tops up the balance of the Investor’s profile for the amount of the funds received. The Investor confirms that when transferring funds to NEKSTER’s bank account he/she will not fully deposit the mentioned funds as part of NEKSTER’s funds, but instead he/she will retain the necessary rights to the claim against NEKSTER for the amount specified in the Investor’s profile. There will not be interests for the transferred funds.</p>
            <p>4.2. A positive balance of the Investor’s profile is considered as an advance payment by the Investor to NEKSTER for the purpose of purchasing claims in line with the procedure specified in the contract and on the basis of the mandate. A negative balance of the Investor’s profile is considered as debt to NEKSTER, which the Investor has to pay at the request of NEKSTER, immediately after the negative balance of the Investor's profile has been established.</p>
            <p>4.3. The investor can transfer funds to the investor's profile only in his own name and only from his own bank account. However, from second transfer of funds, the transfer can also be made by other payment service providers.</p>
            <p>4.4. To transfer funds to the Investor's profile, the Investor must state its Investor's identification number in the order.</p>
            <p>4.5. If NEKSTER receives a payment from the Investor which cannot be identified as made by the Investor for the top up of the balance of the Investor’s profile, such payment shall not be deemed as received, and further transfers of funds to the Investor’s profile will not be possible until NEKSTER has identified this payment. NEKSTER is entitled to return the payment if NEKSTER is unable to identify it.</p>
            <p>4.6. The balance of the Investor’s profile shall also be increased in the order prescribed by the Assignment Contracts when the balance of the Investor’s profile is increased by the payments received in accordance with the Assignment Contract.</p>
            <p>4.7. The Investor cannot request any interest payments from NEKSTER, including legal interest or any other remuneration of Investor’s profile’s balance.</p>
            <p>4.8. The Investor hereby authorizes NEKSTER to use the balance of the Investor’s profile by reducing it within the limits of the available balance, in accordance with the terms and conditions of the Contract and in accordance with the actions taken by the Investor in the Investor’s profile.</p>
            <p>4.9. The Investor may at any time request NEKSTER to disburse the balance of the Investor’s profile to the bank account, from which the Investor has previously made transfers to the NEKSTER’s bank account. In the event of materially changed circumstances resulting from force majeure, as an event that could not have been foreseen, anticipated, avoided or averted and was caused from an outside source and is not a direct consequence due to the conduct of either party, which includes but is not limited to emergencies, natural disasters, pandemics and other actions of state or public authorities that have direct or indirect effects on the operation of NEKSTER, NEKSTER will approach the investor with a proposal regarding changes to the rights and obligations arising from the subject terms and conditions for the usage of the website, which also includes but it is not limited to deadlines.</p>
            <p>4.10. Once NEKSTER has received the Investor’s request regarding disbursement of the balance of the Investor’s profile and starts to process it, NEKSTER is entitled to refuse any use of the requested balance for making investments or taking any other measures with this balance.</p>
            <p>4.11. NEKSTER shall ensure disbursement of the requested balance of the Investor’s profile or a part thereof within the limits of the available balance to the Investor within 2 (two) bank business days after the day when the Investor’s application is deemed to be received. If it is impossible to disburse the balance in the aforementioned term due to the Investor’s fault or any other reasons out of NEKSTER’s control, the aforementioned term shall be deemed to be extended until the relevant obstacles have been eliminated, and NEKSTER shall not be held liable for the delay of the payment due to such causes.</p>
            <p>4.12. Balance of the Investor’s profile shall be reduced by the balance disbursed to the Investor.</p>
            <p>4.13. In order to ensure fulfilment of the Contract, Assignment Contract or any other actions, NEKSTER may, without prior coordination or approval of the Investor, reduce the balance of the Investor’s profile, by directing the funds to the NEKSTER’s bank account or to any other person in accordance with the actions taken by the Investor on the Website and in the Investor’s profile.</p>
            <p>4.14. The Investor agrees to top up the balance of the Investor’s profile only with funds of legal origin, which the Investor can prove at any time.</p>
            <p>4.15. The Investor has been informed that it is prohibited to top up the balance of the Investor’s profile with funds obtained dishonestly or illegally. In case of any suspicious activity NEKSTER may inform the relevant authorities and may block access to the Investor’s profile.</p>
            <p>4.16. If the Investor’s profile is closed and/or deleted on NEKSTER’s initiative, NEKSTER may disburse all of the balance of the Investor’s profile without prior warning or coordination with the Investor to any Investor’s bank account from which the Investor has previously made a transfer to NEKSTER.</p>
            <p>4.17. NEKSTER has fulfilled its obligations regarding the disbursement of funds to the Investor, when the funds have been paid out of any bank account of NEKSTER at its own discretion. NEKSTER shall not assume responsibility for crediting these funds into the Investor’s account. If after disbursement of the funds to the Investor, they are returned to NEKSTER due to any reason, NEKSTER shall not be obligated to inform the Investor regarding the return of the funds. In any case, the Investor must check the data regarding the receipt of the funds and ensure that it can receive the transfer made by NEKSTER to the Investor’s bank account from which NEKSTER has previously received funds from the Investor.</p>
            <p>4.18. NEKSTER is entitled to but not obliged to pay funds to the bank account of the Investor (other than that referred to before), only if the Investor certifies that all Investor’s bank accounts from which Investor previously made transfers to NEKSTER have been closed, and therefore transfer of funds may not be made.</p>
            <p>4.19. NEKSTER is entitled to withhold part of funds used for payment in case Investor has a debt to NEKSTER.</p>
            <p>4.20. NEKSTER is entitled to withhold form the balance of the Investor’s profile and/or from the funds for remittance to Investor in the event that NEKSTER is liable to detention on the basis of law or other legal remedy. 5. Purchasing of Claims</p>
            <p>5. PURCHASE OF CLAIMS</p>
            <p>5.1. When NEKSTER has identified the Investor, the Investor may purchase the Claims offered on the Website, provided that the balance of the Investor’s profile is sufficient for the purchase of the selected Claim.</p>
            <p>5.2. When purchasing the Claims, the Investor shall comply with the following terms:</p>
            <p>5.2.1. The Investor is entitled to choose one or more Claims from all the Claims that are offered on the Website;</p>
            <p>5.2.2. If the Investor wants to purchase only a portion of the Claim, the Investor shall specify the amount that the Investor is willing to pay for the portion of the respective Claim;</p>
            <p>5.2.3. The Investor is entitled to purchase an unlimited number of Claims against a Borrower both in the full amount and in any portion thereof;</p>
            <p>5.2.4. The Investor may purchase the Claims for an amount not exceeding the available balance of the Investor’s profile.</p>
            <p>5.3. NEKSTER shall record the Investor's applications to purchase Claims in chronological order, starting with the oldest. NEKSTER shall be entitled to change the order for the purchasing of Claims.</p>
            <p>5.4. By submitting the application to purchase the Claim, the Investor shall irrevocably authorise NEKSTER to use the balance of the Investor’s profile to pay the Price of the Claim to the Assignor.</p>
            <p>5.5. After selecting the Claim and indicating the price, which the Investor agrees to pay for the Claims, NEKSTER shall inform the Investor in the Investor’s profile of the selected Claims and submit the Assignment Agreement to the Investor for review and its approval. If the Investor agrees to signing the Agreement, the Investor approves it in its Investor’s profile.</p>
            <p>5.6. The Assignment Contract shall be deemed to be concluded at the moment when the Investor has confirmed the terms of the Assignment Contract, and NEKSTER has directed the necessary part of the balance of the Investor’s profile for the payment of the Price of the Claim to the Assignor.</p>
            <p>5.7. After entering into the Assignment Contract the concluded Assignment Contract is available in the Investor's profile throughout the period of its validity.</p>
            <p>5.8. NEKSTER shall ensure information on the Investor’s profile regarding the Claim of the concluded Assignment Contract, as well as the Claim repayment information.</p>
            <p>5.9. All documents regarding the Claim shall be kept by the Assignor or other person indicated in the Assignment Contract, and shall not be transferred to the Assignee.</p>
            <p>5.10. In the event that the Assignee has only purchased a part of Claim, the Assignee is aware and agrees that the Claim does not include all the claims of the Assignor against the Borrower, and NEKSTER is only entitled to service the Claim in favour of the Assignee for the portion of the Claim against the Borrower that had been acquired by the Assignee.</p>
            <p>5.11.  NEKSTER carries out payments under authorization from the Assignor, and delivers to the Assignees all received payments for fulfilment of obligations arising from the Assignment Contract. And thereby increasing the balance of the Investor’s profile pursuant to the amount and procedure specified in the Assignment Contract.</p>
            <p>5.12. Prior to increasing the balance of the Investor’s profile in accordance with the Assignment Contract (Assignor’s authorization to NEKSTER to repay the claim to the Assignee), NEKSTER is entitled to withhold part of the funds in accordance with the Price List indicated on the Website.</p>
            <p>5.13. The Investor is entitled to become acquainted with all actions that were carried out in the Investor's profile, including payments made and received, subject to the restrictions set forth in the Contract</p>
            <p>5.14. In the event that during the use of the Investor's profile some Investor’s activities have been recorded incorrectly, NEKSTER is entitled to correct the respective errors and to make adequate changes to the Investor's profile.</p>
            <p>5.15. In the case that NEKSTER finds that the Investor's profile has a negative balance, NEKSTER shall notify the Investor, who will be obliged to top up the balance of Investor’s profile by an amount which is sufficient to cover the negative balance in full, no later than within 5 (five) days after receiving the notification from NEKSTER.</p>
            <p>5.16. Individual investments offered to the Investor are marked with the Failure Score of the Debtor of the credit rating company Bisnode d.o.o. and Nekster Ranking, which is NEKSTER's internal financial assessment prepared on the basis of the Debtor's diligent and timely fulfilment of its contractual obligations.</p>
            <p>5.17. Before publication each investment goes through a two-stage system for prevention of bad loans. In addition, investments are insured against defaults at Acredia Versicherung AG, Euler Hermes Group, under the insurance contract (link).</p>
            <p>NEKSTER also provides additional guarantees for certain investments - Buyback Guarantee (BG) or Payment Guarantee (PG).</p>
            <p>PG - an investment with this label has a guarantee that the investment is insured with a insurance company against default. In case of damage case of investment with this label, the recovery process begins after 30 days. If no repayment has been made within 30 days of the start of the claim, the insurance company shall pay the claim in accordance with the insurance policy.</p>
            <p>BG – label enables the investor to terminate the investment at any time. Includes an additional guarantee with insurance company against default. In case of damage case investments with this labe, NEKSTER buys back the investment after 30 days (the 31st day) since the occurrence of the damage case, and proceeds to recover according to the insurance policy.</p>
            <p>Investments posted on the platform are additionally guaranteed with a security fund. NEKSTER contributes funds to the security fund based on the default risk level, which guarantees payment of damages BG no later than 30 days after the occurrence of the damage case. For classification as a damage case the claim must meet the criteria of the general terms and conditions of Acredia Versicherung AG (link).</p>
            <p>5.18. Pursuant to the provisions of these Terms, Investments in the Investor Profile are marked with the following status - Active, Paid, Delay, Recovery, Non-payment, Damage, Repayment.</p>
            <p>6. AUTO INVEST</p>
            <p>6.1. The Investor shall be entitled to purchase Claims by using the automatized process for purchasing the Claims “Auto invest” (hereinafter “Auto invest”) in its Investor’s profile. NEKSTER offers three forms of Auto invest, two pre-prepared Auto invest Strategy that provides the highest possible level of liquidity (High-liquidity strategy), with investing limited to BG (Buyback Guarantee) investments only and Auto invest Strategy that provides the highest possible level of return (High-return strategy), with investing limited to PF (Payment Guarantee) investments only;</p>
            <p>and Customized Auto Invest Strategy (Custom strategy) in which the Investor sets the parameters of the investments in which he/she is ready to invest, but still the actual implementation of the investment is automated.</p>
            <p>6.2. If the Investor wants to activate Auto invest in the Investor’s profile, the Investor must give his/hers consent for the conclusion of the Assignment Agreement pursuant to the wording of the Contract, which is in force on the day of the conclusion of the Assignment Contract and must give his/hers consent to the terms and conditions of the Assignment Contract which is in force at the moment of the activation of Auto invest.</p>
            <p>6.3. Investor carries out the activation of the Auto invest, in its Investor’s profile, whereupon NEKSTER shall select the Claims for the Investor.</p>
            <p>6.4 The Investor must ensure adequate balance of the Investor’s profile for purchasing the Claims with Auto invest.</p>
            <p>6.5. By activating Auto invest, the Investor authorises NEKSTER to conduct Assignment Contracts for Claims that correspond to the Auto invest parameters set out by the NEKSTER, to reduce the balance of the Investor’s profile for the Price of Claim for the Claims that correspond to the parameters set out by NEKSTER, and to use the respective funds to pay the price of the Claim to the Assignors and for registration of the Claims in the Investor’s profile.</p>
            <p>6.6. The Investor is entitled to amend or terminate the functionality of Auto invest during the entire duration of the Contract.</p>
            <p>6.7. NEKSTER is not obliged to additionally inform the Investor about the Claims acquired during the use of Auto Invest and about the rights and obligations that arise from such. The Investor must familiarise itself with the documents, notifications and other information specified in the Investor's Profile and relating to the Investor and to the Claims, which according to Investor’s authorization NEKSTER buys on his behalf.</p>
            <p>6.8. The Investor has been acquainted and understands that the Assignment Contracts can be concluded on terms and conditions that differ from those in effect at the moment of activation of the Auto invest, provided that the Assignment Agreements always ensure compliance with at least those parameters approved by the Investor during the activation of Auto invest.</p>
            <p>6.9. The Investor must independently monitor information on the current terms and conditions of the Assignment Contracts, which are available on the Website and on the Investor’s profile. If the Investor has not submitted a request to NEKSTER to terminate the Claim purchasing service Auto invest, it shall be considered that the Investor has familiarised itself and agrees and accepts all Assignment Contracts concluded using this process as binding.</p>
            <p>6.10. NEKSTER is not obliged to ensure for the Investor the possibility to activate and use Auto invest for purchasing Claims, and NEKSTER may at any time unilaterally stop the Auto invest, with a notice to the investor.</p>
            <p>6.11. The investor has the right to unilaterally withdraw (terminate) from investments marked BG before the contractually determined deadline for fulfilment of the Assignment Agreement (maturity of the investment), where NEKSTER will pay the investment amount together with the achieved interest on the maturity date of investment Assignment Agreement respectively</p>
            <p>from which the investor has withdrawn, whereby in accordance with the provisions of Chapter 9 of the Assignment Agreement (Buyback Guarantee And Rights), this period has been extended in accordance with the provisions of this chapter.</p>
            <p>In the case of investments marked PG, the investor has no right to unilaterally withdraw (terminate) before the contractually determined deadline for the fulfilment of the Assignment Agreement (maturity of the investment).</p>
            <p>6.12. At the request of NEKSTER, the investor is obliged to confirm the continuation of Auto invest at least once every 2 years, and NEKSTER reserves the right to request the investor to confirm the continuation of Auto invest within a shorter period of time.</p>
            <p>7. NEKSTER’S RIGHTS AND OBLIGATIONS</p>
            <p>7.1. NEKSTER is obliged to fulfil the Contract and to ensure Servicing of the Claims according to the terms and conditions of the Contract and terms and conditions of the Assignment Contract, as well as Website Terms of Use.</p>
            <p>7.2. NEKSTER shall service the Claims in accordance with the Contract and shall carry out all activities related to servicing of the Claim in the interests of the Assignee.</p>
            <p>7.3. The Investor hereby authorises NEKSTER to service the Claims in the name of NEKSTER but also in the interest of Investor.</p>
            <p>7.4. NEKSTER shall not be held liable neither to the Investor or the Assignee, or their legal successors or heirs, or any other Third Party in the event that the that the Cedent completely or in any part fails to comply with the Assignment Agreement and if the Claim is not repaid and settled.</p>
            <p>7.5. NEKSTER shall make payments in favour of the Assignee and shall withhold payments from the balance of the Investor’s profile only according to the procedure set forth in the Contract.</p>
            <p>8. INVESTOR’S RIGHTS AND OBLIGATIONS</p>
            <p>8.1. The Investor hereby confirms that he/she clearly understands the provisions of these Website Terms of Use, the Assignment Agreement and the Assignment Contract. The investor waives any claims against NEKSTER on the basis of unilateral conditions of the aforementioned conditions and contracts.</p>
            <p>8.2. All activities carried out in the Investor's Profile shall be regarded as activities carried out by the Investor itself and thus shall be recognised as binding upon the Investor.</p>
            <p>8.3. The Investor shall comply with provisions of the Contract and other contracts that are binding upon Investor and are concluded with NEKSTER.</p>
            <p>8.4. The Investor shall ensure adequate balance of the Investor’s in order to fulfil the Contract and to cover any payments arising from it.</p>
            <p>8.5. If the Investor has not ensured adequate balance of the Investor’s profile, NEKSTER shall not be obliged to execute Investor’s instructions, transactions or payments.</p>
            <p>8.6. The Investor must not disclose information regarding the Contract to any Third Parties which could concern the interests of NEKSTER or the Cedent.</p>
            <p>8.7. The Investor agrees not to request information about the Cedent and not to start performing other acts that are not the subject of the contract and the conditions in connection with the claim and / or will not try to recover the claim. The Assignee cannot, personally or through other authorised representatives, contact the Cedent in any way in connection with the concluded Assignment Contract and the acquired Claims.</p>
            <p>8.8. The Assignee cannot revoke the authorisation issued to NEKSTER regarding Claim Servicing and Claim management during the entire validity term of the Contract.</p>
            <p>8.9. Without prejudice to the other obligations of the Investor set forth in the Contract the Investor shall:</p>
            <p>8.9.1. use the Website only for activities permitted by the Website and the Contract;</p>
            <p>8.9.2. provide only correct information during registration on the Website, while using it and when concluding contracts with NEKSTER or communicating with NEKSTER;</p>
            <p>8.9.3. take all necessary measures to prevent access of any Third Party to the Investor's Profile;</p>
            <p>8.9.4. timely and based on the deadlines set forth by NEKSTER, provide NEKSTER with the information which is requested in the Investor’s profile and/or Website.</p>
            <p>9. PRICE LIST AND OTHER PAYMENTS</p>
            <p>9.1. The Investor makes a payment to NEKSTER for the provided services pursuant to the Claim in accordance with the Price List indicated on the Website.</p>
            <p>9.2. Simultaneously with the signing of the Assignment Contract, the Investor shall authorise NEKSTER, without prior coordination, to use the balance of the Investor’s profile for the payments in accordance with the Price List indicated on the Website.</p>
            <p>9.3. NEKSTER is entitled to withhold payments in accordance with the Price List specified on the Website from the funds received from the Cedent, prior to increasing the balance of the Investor’s profile for the relevant amount.</p>
            <p>9.4. The Investor is informed and agrees that the Investor’s bank for the transfer to the Investor’s account can withhold commission in accordance with the terms and conditions of the Investor’s bank and its price list.</p>
            <p>9.5. The Investor is aware that there are laws and regulations applicable to the Investor, including laws and regulations governing the payment of tax for the income received by the Investor for the Claim. The Investor is responsible for the payment of all the necessary duties and taxes in accordance with the requirements of the laws and regulations.</p>
            <p>10. LIABILITY</p>
            <p>10.1. The Parties are responsible for compliance with the terms and conditions of the Contract and Website Terms of Use and the fulfilment of obligations prescribed therein.</p>
            <p>10.2. The Investor is held liable for all damages caused by its activities (actions or omissions) to NEKSTER, Cedent, Cessionary or any other Third Party.</p>
            <p>10.3. NEKSTER is only held liable for the damages caused by its activities (actions or omissions) to the Investor, insofar as NEKSTER can be blamed for the occurrence of such damages and only to the extent of the amount of the balance in the Investor’s profile at the time of the damage.</p>
            <p>10.4. During the performance of this Contract, when using means of communication, NEKSTER shall not be held liable for any damages resulting from interference with the use of mail,  electronic or other means of communication as well as technical equipment that is ensuring the relevant services of NEKSTER, including, but not limited to communications failures, Website malfunction, the malfunction of electronic data exchange systems and payment systems (including internet banking systems) of credit institutions.</p>
            <p>10.5. NEKSTER shall not be held liable for damages that have been or may be caused to the Investor in connection with the conclusion of the Assignment Contract, including the Cedent’s non-compliance with the Assignment Agreement and Claim repayment and settlement.</p>
            <p>10.6. The Investor is informed and agrees that NEKSTER and the Assignors are only held liable for the authenticity of the Claim and cannot be held liable for the security for the Claim and for Cedent's inability to settle the claim.</p>
            <p>10.7. NEKSTER is not and cannot be held liable for the Cedent’s obligation to settle the Claim, and NEKSTER is also not obliged to reimburse or compensate the Assignee for the Claim not repaid. Yet, if the relevant Assignment Contract prescribes an obligation for NEKSTER or any other person to repurchase the Claim from the Assignee, or any other person that has guaranteed the fulfilment of the Cedent’s obligations, such relevant person shall be held liable for the fulfilment of the repurchase and/or guarantee obligations pursuant to the procedure specified in the Assignment Contract.</p>
            <p>10.8. The Parties are exempt from liability for failure to fulfil their obligations if the non-fulfilment has occurred due to reasons that are out of control of the respective Party and have resulted from force majeure circumstances.</p>
            <p>11. CONFIDENTIALITY AND INVESTOR’S PERSONAL DATA</p>
            <p>11.1. Any information arising from the Contract and which concern the Assignment Agreement or the Assignment Contract are confidential. NEKSTER must provide information about the Investor and the Cedent to the extent required and permitted by the Assignment Agreement, the Contract, the Assignment Contract and the laws and other regulations of the Republic of Slovenia.</p>
            <p>11.2. The Investor acknowledges and agrees that by registering on the Website the Investor provides NEKSTER with an explicit consent for processing if the Investor’s personal data are in accordance with the provisions that are included in the Contract and confirm the correctness of its personal data.</p>
            <p>11.3. By registration on the Website, the Investor agrees that NEKSTER shall add Investor’s personal data, which is available to NEKSTER (information which the Investor has provided upon registration on the Website, as well as information that has become available to NEKSTER in connection with the performance of the Contract) into its customer database.</p>
            <p>11.4. For the purpose of executing this Contract and to communicate with the Investor, NEKSTER is entitled to process the Investor’s personal data and NEKSTER has also the right to process the personal information of the investor for the establishment, completion, implementation and / or termination of contractual relations with the investor.</p>
            <p>11.5. The Investor was informed and agrees that NEKSTER can use Investor’s personal data, including the address, telephone number, e-mail address, to inform the investor about all the news and changes to the services provided by NEKSTER, or to contact the investor regarding the performance of the contract.</p>
            <p>11.6. NEKSTER’s Privacy policy which is an integral part of these terms and conditions, gives more precise details about the processing of Investor’s personal data.</p>
            <p>12. LIMITING, BLOCKING AND CLOSING OF THE INVESTOR’S PROFILE</p>
            <p>12.1. NEKSTER is entitled to, without any prior notice, restrict the Investor’s possibilities to use the Website, block the Investor’s profile or unilaterally terminate the Contract and close and delete the Investor’s profile if any of the following occurs:</p>
            <p>12.1.1. if the Investor has violated the terms and conditions of the Contract, Website Terms of Use or terms and conditions of the Assignment Contract;</p>
            <p>12.1.2. if the Investor has provided NEKSTER with false or incorrect information;</p>
            <p>12.1.3. if the Investor uses the Website for illegal purposes;</p>
            <p>12.1.4. if NEKSTER has suspicions that for purchasing the Claims the Investor uses proceeds of crime, finances terrorism, or if the Investor has been involved in any such activity;</p>
            <p>12.1.5. if NEKSTER unilaterally terminates the Assignment Contract;</p>
            <p>12.1.6. if in accordance with the decisions adopted by NEKSTER, under the law, court decision or any other external regulation cooperation with the Investor must be terminated.</p>
            <p>12.2. NEKSTER is entitled to block the Investor's profile if the password is entered incorrectly 5 (five) times in a row.</p>
            <p>12.3. If NEKSTER has any suspicions that the activities on the Website and/or on the Investor’s profile are not performed by the Investor itself, NEKSTER may refuse to accept the actions taken on the Website and on the Investor’s profile and/or block the Investor’s profile. In such a case, NEKSTER is entitled not to approve any actions taken on the Website and on Investor’s profile and keep the Investor’s profile blocked until NEKSTER has contacted the Investor, NEKSTER has verified the Investor’s identity and the Investor has confirmed the taken actions.</p>
            <p>12.4. Without prejudice to NEKSTER’s rights prescribed in Clause 12.1 hereof, NEKSTER is entitled to close and delete the Investor’s profile in the following situations:</p>
            <p>12.4.1. if requested by the Investor itself;</p>
            <p>12.4.2. if the Investor has not acquired any Claim for more than 12 (twelve) consecutive months;</p>
            <p>12.4.3. if the balance of the Investor’s profile has reached a negative value and has not been settled within the time limit set by these terms and conditions;</p>
            <p>12.4.4. if the Investor is in breach of the terms and conditions of the Contract.</p>
            <p>13. ENTRY INTO FORCE AND AMENDING THE CONTRACT</p>
            <p>13.1. The Contract between NEKSTER and the Investor shall come into force from the moment when the Investor has approved the Website Terms of Use on the Website, has given consent to the Contract and NEKSTER has identified the Investor.</p>
            <p>13.2. The current version of the Contract and the Website Terms of Use, which are amended from time to time, shall be available on the Website and/or in the Investor’s profile.</p>
            <p>13.3. NEKSTER is entitled to unilaterally amend the Contract and Website Terms of Use.</p>
            <p>13.4. The Investor must independently and regularly monitor the information in the Investor’s profile, including amendments to the Contract and the Website Terms of Use.</p>
            <p>13.5. If the Investor continues to use the services available on the Website after the amendments came into force, it shall be deemed that the Investor has agreed to amendments to the Contract and/or the Website Terms of Use.</p>
            <p>13.6. NEKSTER is entitled to amend the Claim Servicing Fees and other charges specified on the Website and are related to other offered services as well as to determine additional charges by notifying the Investor of such at least 10 (ten) days in advance.</p>
            <p>14. TERMINATION OF THE CONTRACT</p>
            <p>14.1. NEKSTER may unilaterally terminate the Contract by notifying the Investor at least 10 (ten) days in advance.</p>
            <p>14.2. The Investor is entitled to unilaterally terminate the Contract and request deletion of the Investor’s profile, provided that the Investor does not have any active Claim serviced by NEKSTER, and in accordance with the Contract the Investor does not have any debt obligations to NEKSTER or any other person in accordance with the Assignment Contracts that are specified on the Website.</p>
            <p>14.3. In the event of termination of the Contract in accordance with the procedure set forth in these terms and conditions, the Investor is denied the use of the Investor's Profile as well as the possibility to purchase new Claims and to conclude Assignment Contracts.</p>
            <p>14.4. In the event of termination of the Contract NEKSTER shall make a transfer of the remaining balance of the Investor’s profile to the Investor’s bank account and entails the costs of servicing the Claims and other costs if they are incurred on the basis of the Contract and / or Price List.</p>
            <p>14.5. In the unlikely event that NEKSTER is declared insolvent:</p>
            <p>14.5.1. it will not be possible to carry out transactions on the Website and in the Investor’s profile, the Contract shall be terminated, and the current balance in the Investor’s profile is paid to the Investor in compliance with the relevant legislation;</p>
            <p>14.5.2. the Investor shall receive information regarding the Assignment Contracts which are concluded and are still in effect to the Investor’s e-mail address;</p>
            <p>14.5.3. this will not affect the validity of the Assignment Contracts and any of the Investor’s rights arising from the Assignment Contracts.</p>
            <p>14.6. In order to ensure that information prescribed in Clause 14.5.2 and 14.5.3 hereof is provided and sent, NEKSTER can cooperate with third parties’ / service providers.</p>
            <p>15. OTHER PROVISIONS</p>
            <p>15.1. The Contract is concluded by electronic means in accordance with the procedure specified in the Contract.</p>
            <p>15.2. All activities on the Website and in the Investor's Profile shall be carried out by using EUR currency.</p>
            <p>15.3. The Investor agrees that NEKSTER is entitled to communicate with the Investor 1) by sending an SMS and/or by calling the Investor's mobile number specified in the Investor's Profile; 2) by calling other numbers specified in the Investor's Profile; 3) by sending an e-mail message to the e-mail address specified in the Investor's Profile; 4) by sending regular or registered mail to the Investor’s address specified in the Investor's Profile. Correspondence sent by mail is deemed to have been received on the third day after posting them at the post office. Correspondence sent by e-mail shall be considered to be received within 24 hours after the sending.</p>
            <p>15.4. All notices, applications and information, which are specified in the Contract, shall be submitted or sent by uploading it in the Investor’s profile, to the NEKSTER business address indicated on the Website, or sent to the NEKSTER e-mail indicated on the Website. Correspondence sent by mail shall be considered as received on the 5th (fifth) day after posting it; any correspondence sent by e-mail shall be considered as received on the day when it was send. In case of sending an e-mail after 17.00 or on a day that is a day off day or a holiday in the Republic of Slovenia, it is considered that the e-mail is received the next working day.</p>
            <p>15.5. The Contract is valid until the complete fulfilment of all the obligations set forth herein.</p>
            <p>15.6. The laws and regulations of the Republic of Slovenia shall be applied to all legal relations arising from the Contract.</p>
            <p>15.7. All disputes between the Parties arising from the Contract shall be resolved pursuant to the procedure specified in the legislation of the Republic of Slovenia</p>
            <p>15.8. The Investor is bound by the provisions of the Contract throughout the period of validity of the Contract, until the Investor's Profile is deleted.</p>
            <p>15.9. NEKSTER is entitled to assign any rights and claims it has against the Investor arising from the Contract without a prior Investor’s consent to any Third Party.</p>
            <p>15.10. The Investor understands and consents that the balance of the Investor’s profile is not a deposit and therefore are not subject to any guarantee schemes.</p>
            <p>16. AUTHORISATION</p>
            <p>16.1. The Assignment Contracts can include an authorization to conduct:</p>
            <p>16.1.1. to service the Claim against the Debtor for and on behalf of the Investor by using all the rights and authorizations in the Assignment Contract;</p>
            <p>16.1.2. to service the Claim and to take all necessary actions related to repayment of the Claim and execution of the Assignment Agreement, until the Claim is not repaid in full amount;</p>
            <p>16.1.3. without prior coordination with the Investor and at the Cessionary’s own discretion, to make amendments and supplements to the Assignment Agreement, including the Claim’s repayment period, but not more than 6 (six) times, meanwhile setting forth that each of the 6 (six) extensions of the Claim repayment period shall not be longer than 30 (thirty) days, unless in the Assignment Contract it is explicitly stipulated otherwise.</p>
            <p>16.2. The Investor is informed that the authorisation included in the Assignment Contract shall be valid throughout the validity period of the Assignment Contract. Activities carried out by the Cessionary and other persons in this regard shall be binding upon the Investor, and the Investor waives the claims against the Cessionary and any other person in this regard.</p>
            <p>16.3. By approving the Website Terms of the Use, the Investor authorises NEKSTER to take such actions:</p>
            <p>16.3.1. make the payment of the Price of Claim to the Assignor, using/reducing the balance of the Investor’s profile for this purpose;</p>
            <p>16.3.2. distribute the received payments towards settling the Claims among the Investors (Assignees) in accordance with the concluded Assignment Contracts;</p>
            <p>16.3.3. to withhold all payments related to the Assignment Contract according to the Price List;</p>
            <p>16.3.4. to perform other actions that NEKSTER is entitled to take on behalf of the Investor under the Contract.</p>
            <p>16.4. The Investor has been informed and agrees that NEKSTER pursuant to the terms and conditions of the Assignment Contract may at any time without prior notice or agreement with the Investor, repurchase the Claim from the Investor (Assignee) by increasing the balance of the Investor’s profile by the respective repurchase/buyback price specified in the Assignment Contract. If the Investor has concluded the Assignment Contract which states that the Assignor or any other person is obliged to repurchase/buy back the Claim from the Investor, in such event the Claim shall be repurchased/bought back from the Investor.</p>
            <p>16.5. The Investor is informed that the authorisation to NEKSTER is valid throughout the entire validity period of the Contract and therefore the activities carried out by NEKSTER shall be binding upon the Investor, and the Investor waives any claims against NEKSTER in this regard.</p>
        </div>
    );
}

export default Terms;