import React, {Component} from "react";
import {activeInvestmentSelector} from "../reducers/investment";
import {newInvestment, setActiveInvestment, setActiveUserInvestment} from "../actions/investment";
import {getAccountBalance} from "../actions/accountBalance";
import {connect} from "react-redux";
import {ReactComponent as PGIcon} from "../images/pg-icon.svg";
import {ReactComponent as BGIcon} from "../images/bg-icon.svg";
import {formatMoney} from "../components/utils";
import {Redirect} from 'react-router-dom'
import {ReactComponent as InfoBtn} from "../images/round-info-button.svg";
import {ReactComponent as CloseBtn} from "../images/baseline-close-24px.svg";
import Chart from "react-google-charts";
import {Trans, withTranslation} from "react-i18next";

const mapStateToProps = state => {
    return {
        user: state.user.currentUser,
        accountBalance: state.accountBalance.accountBalance,
        investment: activeInvestmentSelector(state) || {},
        success: state.investment.success,
        processingNewInvestment: state.investment.processingNewInvestment,
        error: state.investment.error,
    };
};

class AppNewInvestment extends Component {
    state = {
        goBack: false,
        chartWidth: 0
    };

    onInvest(e) {
        e.preventDefault();
        const value = parseFloat(this.refs.investInput.value) || 0.0;
        this.props.dispatch(newInvestment(this.props.investment.Id, value, ok => {
            this.setState({goBack: true})
        }));
    }

    render() {
        if (this.state.goBack) {
            return <Redirect to='/u/investments'/>
        }
        const {accountBalance, investment, processingNewInvestment, t} = this.props,
            hasLoaded = !!investment.Id, {originator = {deals: {}}} = investment || {};
        return (
            <div>
                <form className="banner" onSubmit={this.onInvest.bind(this)}>
                    <div className="banner__row banner__row--with-action">
                        <h3 className="mdc-typography--headline6 banner__title">{t('newInvestmentTitle')}</h3>
                        <div className="banner__content">
                            <div className="action-field action-field--with-prefix">
                                <i className="action-field__prefix">€</i>
                                <input type="number"
                                       ref="investInput"
                                       className="action-field__input"
                                       step="0.01"
                                       name="amount"
                                       max={investment.availableAmount}
                                       placeholder={t('max') + " " + formatMoney(accountBalance.availableFunds) + " €"}/>
                                <button type="submit"
                                        className="action-field__button button button--unelevated button--green"
                                        disabled={processingNewInvestment}>{t('invest')}
                                </button>
                            </div>
                        </div>
                        <h2 className="mdc-typography--headline5 title title--border hide-on-mobile">{t('securityGraph')}</h2>
                    </div>
                </form>
                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner">
                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">

                            <span
                                className="mdc-typography--headline5 banner__content-title">{t('newInvestmentInfoText')}</span>
                        </div>
                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-tablet">
                            {/*<h2 className="mdc-typography--headline5 title title--border">Investment details</h2>*/}

                            <div className="list list--vertical list--ordinal-background">
                                <div className="title">
                                    <span className="">{t('loanDataTitle')}</span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('loanType')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t(investment.type || "N/A")}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('loanAmount')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{formatMoney(investment.totalAmount) || '0'} €</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('loanAvailableAmount')}</b></span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{formatMoney(investment.availableAmount) || '0'} €</b></span>
                                </div>
                                {/*<div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                </div>*/}
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('roiPA')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{formatMoney(investment.interestRate) || '0'} %</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('roi')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{investment.rank || 'N/A'}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('investmentDateOfIssue')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{investment.dateStartFormatted}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('investmentMaturityDate')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{investment.dateEndFormatted || 'N/A'}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('investmentDuration')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{investment.termDays ? investment.termDays + " days" : "N/A"}</b></span>
                                </div>
                                {/*<div className="list-item">
                                    <span className="mdc-typography--body1 custom-account-list-item"/>
                                    <span
                                        className="mdc-typography--body1 custom-account-list-item"/>
                                </div>*/}

                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('protection')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item custom-account-list-item--with-icon">{investment.protection === "PG" ?
                                        <PGIcon/> : investment.protection === "BG" ?
                                            <BGIcon/> : (investment.protection || t("N/A"))}</span>
                                </div>
                            </div>
                        </div>

                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-tablet">
                            {/*<span className="title "/>*/}
                            <div className="list list--vertical list--ordinal-background">
                                <div className="title">
                                    <span className="">{t('debtorData')}</span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('country')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{originator.countryCode || 'N/A'}</span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('partnerSince')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{originator.partnerSince || 'N/A'}</span>
                                </div>
                                {/* <div className="list-item list-item--no-background">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                </div>*/}
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('failureScore')}</span>
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item"><b
                                        className="custom-account-list-item__ranking">{originator.creditRating || 'N/A'}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('neksterRanking')}</span>
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item"><b
                                        className="custom-account-list-item__ranking">{originator.creditRating || 'N/A'}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('totalNOLoanDeals')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{originator.deals ? originator.deals.totalCount : "0"}</b>&nbsp;({originator.deals ? originator.deals.activeCount : "0"} active)</span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('totalValLoanDeals')}</b></span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{formatMoney(originator.deals ? originator.deals.totalValue : 0) + ' €'}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('companySize')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{originator.companySize || 'N/A'}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('NOEmployees')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{originator.noOfEmployees || 'N/A'}</b></span>
                                </div>

                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('totalIncome')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{originator.totalIncome || 'N/A'}</b></span>
                                </div>
                            </div>
                        </div>

                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-tablet">
                            <i className="title-spacer"/>
                            <div className="chart-container">
                                <div ref="card" className="card card--flip">
                                    <div className="card__surface">
                                        <div ref="content" className="">
                                            <div ref="chartIcon" className="chart__protection">
                                                <div className="chart__protection-icon">
                                                    {investment.protection === "PG" ?
                                                        <PGIcon/> : investment.protection === "BG" ?
                                                            <BGIcon/> : (investment.protection || t("N/A"))}
                                                </div>
                                            </div>

                                            {hasLoaded && (
                                                <Chart
                                                    width={this.state.chartWidth}
                                                    height={this.state.chartWidth}
                                                    chartType="PieChart"
                                                    loader={<div/>}
                                                    data={[
                                                        [
                                                            {type: 'string', label: "Investor"},
                                                            {type: 'number', label: "Amount"},
                                                            {type: 'string', role: 'tooltip'},
                                                        ],
                                                        ['Invested amount', investment.totalAmount - investment.availableAmount, t("investedAmount") + '\n' + formatMoney(investment.totalAmount - investment.availableAmount) + ' €'],
                                                        ['Available amount', investment.availableAmount, t("availableInvestmentAmount") + '\n' + formatMoney(investment.availableAmount) + ' €'],
                                                        /*['Own participation', ((100 * investment.totalAmount) / 90) * .1, t("ownParticipation") + '\n' + formatMoney(((100 * investment.totalAmount) / 90) * .1) + ' €'],*/
                                                    ]}
                                                    options={{
                                                        pieHole: 0.5,
                                                        legend: 'none',
                                                        colors: ['#057E9D', '#a6ce38', '#91D8F7'],
                                                        chartArea: {left: 16, top: 16, right: 16, bottom: 16},
                                                        backgroundColor: {
                                                            fill: 'transparent',
                                                        },
                                                        pieSliceBorderColor: 'transparent',
                                                        pieStartAngle: 180,
                                                        animation: {
                                                            startup: true,
                                                            duration: 500,
                                                        },
                                                    }}
                                                />)}
                                            {/*<div className="chart__legend mdc-typography--caption">
                                                <div className="chart__legend-item">
                                                    <i className="chart__legend-color" style={chartColors[0]}/>
                                                    <span className="chart__legend-text">Total amount</span>
                                                </div>
                                                <div className="chart__legend-item">
                                                    <i className="chart__legend-color" style={chartColors[1]}/>
                                                    <span className="chart__legend-text">Available amount</span>
                                                </div>
                                                <div className="chart__legend-item">
                                                    <i className="chart__legend-color" style={chartColors[2]}/>
                                                    <span className="chart__legend-text">Achilles</span>
                                                </div>
                                            </div>*/}
                                        </div>
                                        <div className={"mdc-menu-surface--anchor card__more"}>
                                            <button ref="infoBtn"
                                                    className="icon-button icon-button--dense card__info-btn">
                                                <InfoBtn/>
                                            </button>
                                        </div>
                                        <div className="card__back surface--with-text">
                                            <h4 className="mdc-typography--subtitle2 surface__title">{t('graphInfo')}</h4>
                                            <div className="card__back-scroll-content">
                                                <p className="mdc-typography--body2"><Trans ns="app"
                                                                                            i18nKey="newInvestmentGraphInfo"><br/></Trans>
                                                </p>
                                            </div>
                                            <div className="card__more">
                                                <button ref="closeBtn"
                                                        className="icon-button icon-button--dense icon-button--untouched card__info-btn">
                                                    <CloseBtn/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        //this._chart = new Chart(this.refs.chart, chartOptions);

        // todo: probable multiple listeners after re-navigation
        this.refs.chartIcon.style.height = this.refs.chartIcon.offsetWidth.toString() + "px";
        window.addEventListener('resize', () => {
            if (this.refs.chartIcon) {
                this.refs.chartIcon.style.height = this.refs.chartIcon.offsetWidth.toString() + "px";
            }
        }, false);

        this.props.dispatch(setActiveInvestment(this.props.id));
        this.props.dispatch(getAccountBalance());

        // chart size
        //this.props.dispatch(setActiveUserInvestment(this.props.id));
        this.setState({chartWidth: this.refs.content.clientWidth});

        // graph flipping
        this._infoListener = e => {
            this.refs.card.classList.add("card--is-flipped")
        };
        this._closeListener = e => {
            this.refs.card.classList.remove("card--is-flipped")
        };
        this.refs.infoBtn.addEventListener('click', this._infoListener);
        this.refs.closeBtn.addEventListener('click', this._closeListener);
    }
}


export default connect(mapStateToProps)(withTranslation("app")(AppNewInvestment));
