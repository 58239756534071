import {GET_ALL_WITHDRAWLS} from '../actions/withdrawals';
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    withdrawals: {},
    withdrawalIds: [], // for displaying results in order
    sort: "",
    direction: "",
    limit: 20,
    offset: 0,
    atEnd: false,
};

const withdrawals = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_WITHDRAWLS:
            return {
                ...state,
                withdrawals: {...state.withdrawals, ...action.withdrawals},
                withdrawalIds: action.withdrawalIds,
                atEnd: action.atEnd,
                sort: action.sort,
                direction: action.direction,
                limit: action.limit,
                offset: action.offset,
            };
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default withdrawals;
