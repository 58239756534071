import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";

export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';

export const getTransactions = (limit = 5, offset = 0, sortBy) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, userId = state.user.currentUser.Id;

    fetch(API_URL + '/transactions/' + userId + '?limit=' + limit + '&offset=' + offset, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(data => {
        const transactions = data.reduce((obj, transaction) => {
            obj[transaction.Id] = transaction;
            return obj
        }, {});
        console.log(transactions);
        dispatch({
            type: GET_ALL_TRANSACTIONS,
            transactions
        })
    }).catch(err => {
        console.log(err);
    });
};