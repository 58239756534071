import React, {Component} from "react";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import {MDCSelect} from "@material/select";
import {updateUserInvestorProfile, uploadDocuments} from "../actions/user";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {ReactComponent as Upload} from "../images/upload_file-white-18dp.svg";
import {ReactComponent as Check} from "../images/check_circle-green-18dp.svg";

import countries from '../locales/countries'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import IconButton from '@material-ui/core/IconButton';

const mapStateToProps = state => {
    return {user: state.user.currentUser};
};

class AppFinancialInformation extends Component {
    render() {
        const {t, user} = this.props;
        return (
            <div className="mdc-layout-grid mdc-layout-grid--full-height">
                <div className="steps">
                    <div className="steps__title">
                        <h2 className="mdc-typography--headline5 title title--border">{t('financialInformationTitle')}</h2>
                    </div>
                    <div className="steps__content">
                        {user.investorType === "Business" ? (
                            <Switch>
                                <Route exact path="/u/account/financial-information" render={() => (
                                    <Redirect to="/u/account/financial-information/1"/>
                                )}/>
                                <Route path="/u/account/financial-information/1"
                                       component={() => (
                                           <AppFinancialInformationStep1 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/2"
                                       component={() => (
                                           <AppFinancialInformationStep2 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/3"
                                       component={() => (
                                           <AppFinancialInformationStep3 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/4"
                                       component={() => (
                                           <AppFinancialInformationStep4 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/5"
                                       component={() => (
                                           <AppFinancialInformationStep5 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route exact path="/u/account/financial-information/upload" render={() => (
                                    <Redirect to="/u/account/financial-information/6"/>
                                )}/>
                                <Route path="/u/account/financial-information/6"
                                       component={() => (
                                           <AppFinancialInformationStep6 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/upload-id"
                                       component={() => (
                                           <AppFinancialInformationStepUploadID dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/upload-passport"
                                       component={() => (
                                           <AppFinancialInformationStepUploadPassport dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                            </Switch>
                        ) : (
                            <Switch>
                                <Route exact path="/u/account/financial-information" render={() => (
                                    <Redirect to="/u/account/financial-information/1"/>
                                )}/>
                                <Route path="/u/account/financial-information/1"
                                       component={() => (
                                           <AppFinancialInformationPersonalStep1 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/2"
                                       component={() => (
                                           <AppFinancialInformationPersonalStep2 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/3"
                                       component={() => (
                                           <AppFinancialInformationPersonalStep3 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/4"
                                       component={() => (
                                           <AppFinancialInformationPersonalStep4 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route exact path="/u/account/financial-information/upload" render={() => (
                                    <Redirect to="/u/account/financial-information/5"/>
                                )}/>
                                <Route path="/u/account/financial-information/5"
                                       component={() => (
                                           <AppFinancialInformationStep6 dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/upload-id"
                                       component={() => (
                                           <AppFinancialInformationStepUploadID dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                                <Route path="/u/account/financial-information/upload-passport"
                                       component={() => (
                                           <AppFinancialInformationStepUploadPassport dispatch={this.props.dispatch} t={t}/>
                                       )}/>
                            </Switch>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

class AppFinancialInformationPersonalStep1 extends Component {
    state = {};

    render() {
        const {t} = this.props;
        return (
            <form ref="form" className="steps__field">
                <label className="form-field__label mdc-typography--headline5">{t('fiPersonal1')}</label>
                <div ref="select" className={"mdc-select mdc-select--outlined mdc-select--required"}>
                    <input type="hidden" name="enhanced-select"/>
                    <button type="submit" className="mdc-select__dropdown-icon"/>
                    <div className="mdc-select__selected-text mdc-typography--body1">{t('select')}</div>
                    <div className="mdc-select__menu mdc-menu mdc-menu-surface steps__menu">
                        <ul className="mdc-list">
                            <li className="mdc-list-item" data-value="SALARY">{t('personalSourceOfFunds.SALARY')}</li>
                            <li className="mdc-list-item" data-value="SAVINGS">{t('personalSourceOfFunds.SAVINGS')}</li>
                            <li className="mdc-list-item" data-value="CAPITAL">{t('personalSourceOfFunds.CAPITAL')}</li>
                            <li className="mdc-list-item" data-value="DIVIDENDS">{t('personalSourceOfFunds.DIVIDENDS')}</li>
                            <li className="mdc-list-item" data-value="GIFT">{t('personalSourceOfFunds.GIFT')}</li>
                            <li className="mdc-list-item" data-value="INHERITANCE">{t('personalSourceOfFunds.INHERITANCE')}</li>
                            <li className="mdc-list-item" data-value="BUSINESS">{t('personalSourceOfFunds.BUSINESS')}</li>
                            <li className="mdc-list-item" data-value="OTHER">{t('personalSourceOfFunds.OTHER')}</li>
                        </ul>
                    </div>
                </div>
                {this.state.next ? <Redirect to="/u/account/financial-information/2"/> : <div/>}
            </form>
        )
    }

    componentDidMount() {
        const select = new MDCSelect(this.refs.select);
        select.listen("MDCSelect:change", e => {
            if (this.refs.select.classList.contains("mdc-select--next") !== !!e.detail.value) {
                this.refs.select.classList.toggle("mdc-select--next")
            }
            //console.log(e.detail);
        });
        this.refs.form.addEventListener('submit', e => {
            e.preventDefault();
            if (!select.value) {
                select.valid = false;
            }
            //console.log(select.value);

            select.disabled = true;

            this.props.dispatch(updateUserInvestorProfile("fundsSources", [select.value], () => {
                select.disabled = false;
                this.setState({next: true});
            }))
        });
    }
}

class AppFinancialInformationPersonalStep2 extends Component {
    state = {};

    render() {
        const {t} = this.props;
        return (
            <form ref="form" className="steps__field">
                <label className="form-field__label mdc-typography--headline5">{t('fiPersonal2')}</label>
                <div ref="select" className={"mdc-select mdc-select--outlined mdc-select--required"}>
                    <input type="hidden" name="enhanced-select"/>
                    <button type="submit" className="mdc-select__dropdown-icon"/>
                    <div className="mdc-select__selected-text mdc-typography--body1">{t('select')}</div>
                    <div className="mdc-select__menu mdc-menu mdc-menu-surface steps__menu">
                        <ul className="mdc-list">
                            <li className="mdc-list-item" data-value="EUR_0_20000">{t('personalAnnualIncome.EUR_0_20000')}</li>
                            <li className="mdc-list-item" data-value="EUR_20001_50000">{t('personalAnnualIncome.EUR_20001_50000')}</li>
                            <li className="mdc-list-item" data-value="EUR_50001_75000">{t('personalAnnualIncome.EUR_50001_75000')}</li>
                            <li className="mdc-list-item" data-value="EUR_75001_100000">{t('personalAnnualIncome.EUR_75001_100000')}</li>
                            <li className="mdc-list-item" data-value="EUR_100001">{t('personalAnnualIncome.EUR_100001')}</li>
                        </ul>
                    </div>
                </div>
                {this.state.next ? <Redirect to="/u/account/financial-information/3"/> : <div/>}
            </form>
        )
    }

    componentDidMount() {
        const select = new MDCSelect(this.refs.select);
        select.listen("MDCSelect:change", e => {
            if (this.refs.select.classList.contains("mdc-select--next") !== !!e.detail.value) {
                this.refs.select.classList.toggle("mdc-select--next")
            }
            //console.log(e.detail);
        });
        this.refs.form.addEventListener('submit', e => {
            e.preventDefault();
            if (!select.value) {
                select.valid = false;
            }
            //console.log(select.value);

            select.disabled = true;

            this.props.dispatch(updateUserInvestorProfile("annualIncome", select.value, () => {
                select.disabled = false;
                this.setState({next: true});
            }))
        });
    }
}

const countryList = Object.keys(countries).map((key, i) => {
  const c = countries[key];
  return Object.assign({text: c.name.toUpperCase()}, c)
});

class AppFinancialInformationPersonalStep3 extends Component {
  state = {
    isDrzavaValid: false,
    selectedDrzava: null,
  };

  render() {
    const {t} = this.props;
    return (
      <form ref="form" className="steps__field">
        <label className="form-field__label custom-label mdc-typography--headline5">{t('fiPersonal3')}</label>
          <div style={{width: '100%', maxWidth: '340px'}}>
            <Autocomplete
              disableClearable
              forcePopupIcon={false}
              openOnFocus={true}
              fullWidth={true}
              options={countryList}
              getOptionLabel={(option) => option.name}
              noOptionsText='Države ni na seznamu.'
              size={'medium'}
              onChange={(event, newValue) => this.isDrzavaSelected(newValue) }
              renderInput={(params) =><TextField 
                { ...params}
                onKeyPress={(event) => this.keyPressed(event)}
                placeholder={t('fiPersonal3Placeholder')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.nextStep4}
                      >
                        {this.state.isDrzavaValid ? 
                        <ArrowRightIcon className="text-field__next-icon-auto-complete"/> 
                        : 
                        <ArrowDropDownIcon/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />}
            />
        </div>
          {/* <div ref="textfield" className="text-field text-field--next text-field--invalid">
              <input ref="input" className="text-field__input" type="text"
                      placeholder={t('fiPersonal3Placeholder')}/>
              <button type="submit" className="text-field__next-icon"/>
          </div> */}

          {this.state.next ? <Redirect to="/u/account/financial-information/4"/> : <div/>}
      </form>
    )
  }
  
  keyPressed = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.nextStep4();
    }
  }

  isDrzavaSelected = (value) => {
    this.setState({selectedDrzava: value})
    if (value !== '') {
        this.setState({isDrzavaValid: true})
    } else {
        this.setState({isDrzavaValid: false});
    }
  }

  nextStep4 = () => {
    if(this.state.isDrzavaValid) {
        this.props.dispatch(updateUserInvestorProfile("countryOfResidence", this.state.selectedDrzava.name, () => {
        this.setState({next: true});
      }))
    }
  }

  // componentDidMount() {
  //     this.refs.input.addEventListener("keyup", e => {
  //         if (this.refs.input.value && this.refs.input.value.length > 0) {
  //             this.refs.textfield.classList.remove("text-field--invalid")
  //         } else {
  //             this.refs.textfield.classList.add("text-field--invalid")
  //         }
  //     });
  //     this.refs.form.addEventListener('submit', e => {
  //         e.preventDefault();
  //         this.refs.input.disabled = true;

  //         this.props.dispatch(updateUserInvestorProfile("countryOfResidence", this.refs.input.value, () => {
  //             this.refs.input.disabled = false;
  //             this.setState({next: true});
  //         }))
  //     });
  // }
}

class AppFinancialInformationPersonalStep4 extends Component {
    state = {};

    render() {
        const {t} = this.props;
        return (
            <form ref="form" className="steps__field">
                <label className="form-field__label custom-label mdc-typography--headline5">{t('fiPersonal4')}</label>

                <div ref="textfield" className="text-field text-field--next text-field--invalid">
                    <input ref="input" className="text-field__input" type="text"
                           placeholder={t('fiPersonal4Placeholder')}/>
                    <button type="submit" className="text-field__next-icon"/>
                </div>

                {this.state.next ? <Redirect to="/u/account/financial-information/5"/> : <div/>}
            </form>
        )
    }

    componentDidMount() {
        this.refs.input.addEventListener("keyup", e => {
            if (this.refs.input.value && this.refs.input.value.length > 0) {
                this.refs.textfield.classList.remove("text-field--invalid")
            } else {
                this.refs.textfield.classList.add("text-field--invalid")
            }
        });
        this.refs.form.addEventListener('submit', e => {
            e.preventDefault();
            this.refs.input.disabled = true;

            this.props.dispatch(updateUserInvestorProfile("taxNo", this.refs.input.value, () => {
                this.refs.input.disabled = false;
                this.setState({next: true});
            }))
        });
    }
}

class AppFinancialInformationStep1 extends Component {
    state = {};

    render() {
        const {t} = this.props;
        return (
            <form ref="form" className="steps__field">
                <label className="form-field__label mdc-typography--headline5">{t('fiBusiness1')}</label>
                <div ref="select" className={"mdc-select mdc-select--outlined mdc-select--required"}>
                    <input type="hidden" name="enhanced-select"/>
                    <button type="submit" className="mdc-select__dropdown-icon"/>
                    <div className="mdc-select__selected-text mdc-typography--body1">{t('select')}</div>
                    <div className="mdc-select__menu mdc-menu mdc-menu-surface steps__menu">
                        <ul className="mdc-list">
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt1')}>{t('fiBusiness1opt1')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt2')}>{t('fiBusiness1opt2')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt3')}>{t('fiBusiness1opt3')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt4')}>{t('fiBusiness1opt4')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt5')}>{t('fiBusiness1opt5')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt6')}>{t('fiBusiness1opt6')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt7')}>{t('fiBusiness1opt7')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt8')}>{t('fiBusiness1opt8')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt9')}>{t('fiBusiness1opt9')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt10')}>{t('fiBusiness1opt10')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt11')}>{t('fiBusiness1opt11')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt12')}>{t('fiBusiness1opt12')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt13')}>{t('fiBusiness1opt13')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt14')}>{t('fiBusiness1opt14')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt15')}>{t('fiBusiness1opt15')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt16')}>{t('fiBusiness1opt16')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt17')}>{t('fiBusiness1opt17')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt18')}>{t('fiBusiness1opt18')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt19')}>{t('fiBusiness1opt19')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt20')}>{t('fiBusiness1opt20')}</li>
                            <li className="mdc-list-item" data-value={t('fiBusiness1opt21')}>{t('fiBusiness1opt21')}</li>
                        </ul>
                    </div>
                </div>
                {this.state.next ? <Redirect to="/u/account/financial-information/2"/> : <div/>}
            </form>
        )
    }

    componentDidMount() {
        const select = new MDCSelect(this.refs.select);
        select.listen("MDCSelect:change", e => {
            if (this.refs.select.classList.contains("mdc-select--next") !== !!e.detail.value) {
                this.refs.select.classList.toggle("mdc-select--next")
            }
            //console.log(e.detail);
        });
        this.refs.form.addEventListener('submit', e => {
            e.preventDefault();
            if (!select.value) {
                select.valid = false;
            }
            //console.log(select.value);

            select.disabled = true;

            this.props.dispatch(updateUserInvestorProfile("economicActivity", select.value, () => {
                select.disabled = false;
                this.setState({next: true});
            }))
        });
    }
}

class AppFinancialInformationStep2 extends Component {
    state = {};

    render() {
        const {t} = this.props;
        return (
            <form ref="form" className="steps__field">
                <label className="form-field__label mdc-typography--headline5">{t('fiBusiness2')}</label>
                <div ref="select" className={"mdc-select mdc-select--outlined mdc-select--required"}>
                    <input type="hidden" name="enhanced-select"/>
                    <button type="submit" className="mdc-select__dropdown-icon"/>
                    <div className="mdc-select__selected-text mdc-typography--body1">{t('selectRegion')}</div>
                    <div className="mdc-select__menu mdc-menu mdc-menu-surface steps__menu">
                        <ul className="mdc-list">
                            <li className="mdc-list-item" data-value="UK">{t('region.UK')}</li>
                            <li className="mdc-list-item" data-value="EU_EEA">{t('region.EU_EEA')}</li>
                            <li className="mdc-list-item" data-value="OTHER">{t('region.OTHER')}</li>
                        </ul>
                    </div>
                </div>
                {this.state.next ? <Redirect to="/u/account/financial-information/3"/> : <div/>}
            </form>
        )
    }

    componentDidMount() {
        const select = new MDCSelect(this.refs.select);
        select.listen("MDCSelect:change", e => {
            if (this.refs.select.classList.contains("mdc-select--next") !== !!e.detail.value) {
                this.refs.select.classList.toggle("mdc-select--next")
            }
            //console.log(e.detail);
        });
        this.refs.form.addEventListener('submit', e => {
            e.preventDefault();
            if (!select.value) {
                select.valid = false;
            }
            //console.log(select.value);

            select.disabled = true;

            this.props.dispatch(updateUserInvestorProfile("mainCounterPartyOrigin", select.value, () => {
                select.disabled = false;
                this.setState({next: true});
            }))
        });
    }
}

class AppFinancialInformationStep3 extends Component {
    state = {
      
    };

    render() {
        const {t} = this.props;
        return (
            <form ref="form" className="steps__field">
                <label className="form-field__label mdc-typography--headline5">{t('fiBusiness3')}</label>
                <div ref="select" className={"mdc-select mdc-select--outlined mdc-select--required"}>
                    <input type="hidden" name="enhanced-select"/>
                    <button type="submit" className="mdc-select__dropdown-icon"/>
                    <div className="mdc-select__selected-text mdc-typography--body1">{t('selectType')}</div>
                    <div className="mdc-select__menu mdc-menu mdc-menu-surface steps__menu">
                        <ul className="mdc-list">
                            <li className="mdc-list-item" data-value="PRIVATE_LIMITED">{t('companyType.PRIVATE_LIMITED')}</li>
                            <li className="mdc-list-item" data-value="PUBLIC_LIMITED">{t('companyType.PUBLIC_LIMITED')}</li>
                            <li className="mdc-list-item" data-value="SELF_EMPLOYED">{t('companyType.SELF_EMPLOYED')}</li>
                            <li className="mdc-list-item" data-value="PARTNERSHIP">{t('companyType.PARTNERSHIP')}</li>
                            <li className="mdc-list-item" data-value="ASSOCIATION">{t('companyType.ASSOCIATION')}</li>
                            <li className="mdc-list-item" data-value="FOUNDATION">{t('companyType.FOUNDATION')}</li>
                            <li className="mdc-list-item" data-value="OTHER">{t('companyType.OTHER')}</li>
                        </ul>
                    </div>
                </div>
                {this.state.next ? <Redirect to="/u/account/financial-information/4"/> : <div/>}
            </form>
        )
    }

    componentDidMount() {
        const select = new MDCSelect(this.refs.select);
        select.listen("MDCSelect:change", e => {
            if (this.refs.select.classList.contains("mdc-select--next") !== !!e.detail.value) {
                this.refs.select.classList.toggle("mdc-select--next")
            }
            //console.log(e.detail);
        });
        this.refs.form.addEventListener('submit', e => {
            e.preventDefault();
            if (!select.value) {
                select.valid = false;
            }
            //console.log(select.value);

            select.disabled = true;

            this.props.dispatch(updateUserInvestorProfile("type", select.value, () => {
                select.disabled = false;
                this.setState({next: true});
            }))
        });
    }
}

class AppFinancialInformationStep4 extends Component {
    state = {};

    render() {
        const {t} = this.props;
        return (
            <form ref="form" className="steps__field">
                <label className="form-field__label mdc-typography--headline5">{t('fiBusiness4')}</label>
                <div ref="select" className={"mdc-select mdc-select--outlined mdc-select--required"}>
                    <input type="hidden" name="enhanced-select"/>
                    <button type="submit" className="mdc-select__dropdown-icon"/>
                    <div className="mdc-select__selected-text mdc-typography--body1">{t('select')}</div>
                    <div className="mdc-select__menu mdc-menu mdc-menu-surface steps__menu">
                        <ul className="mdc-list">
                            <li className="mdc-list-item" data-value="EUR_50000">{t('annualIncome.EUR_50000')}</li>
                            <li className="mdc-list-item" data-value="EUR_500000">{t('annualIncome.EUR_500000')}</li>
                            <li className="mdc-list-item" data-value="EUR_1000000">{t('annualIncome.EUR_1000000')}</li>
                            <li className="mdc-list-item" data-value="EUR_5000000">{t('annualIncome.EUR_5000000')}</li>
                            <li className="mdc-list-item" data-value="EUR_5000001">{t('annualIncome.EUR_5000001')}</li>
                        </ul>
                    </div>
                </div>
                {this.state.next ? <Redirect to="/u/account/financial-information/5"/> : <div/>}
            </form>
        )
    }

    componentDidMount() {
        const select = new MDCSelect(this.refs.select);
        select.listen("MDCSelect:change", e => {
            if (this.refs.select.classList.contains("mdc-select--next") !== !!e.detail.value) {
                this.refs.select.classList.toggle("mdc-select--next")
            }
            //console.log(e.detail);
        });
        this.refs.form.addEventListener('submit', e => {
            e.preventDefault();
            if (!select.value) {
                select.valid = false;
            }
            //console.log(select.value);

            select.disabled = true;

            this.props.dispatch(updateUserInvestorProfile("annualIncome", select.value, () => {
                select.disabled = false;
                this.setState({next: true});
            }))
        });
    }
}

class AppFinancialInformationStep5 extends Component {
    state = {};

    render() {
        const {t} = this.props;
        return (
            <form ref="form" className="steps__field">
                <label className="form-field__label mdc-typography--headline5">{t('fiBusiness5')}</label>
                <div ref="select" className={"mdc-select mdc-select--outlined mdc-select--required"}>
                    <input type="hidden" name="enhanced-select"/>
                    <button type="submit" className="mdc-select__dropdown-icon"/>
                    <div className="mdc-select__selected-text mdc-typography--body1">{t('select')}</div>
                    <div className="mdc-select__menu mdc-menu mdc-menu-surface steps__menu">
                        <ul className="mdc-list">
                            <li className="mdc-list-item" data-value="PERCENT_10">{t('investPercentage.PERCENT_10')}</li>
                            <li className="mdc-list-item" data-value="PERCENT_20">{t('investPercentage.PERCENT_20')}</li>
                            <li className="mdc-list-item" data-value="PERCENT_21">{t('investPercentage.PERCENT_21')}</li>
                        </ul>
                    </div>
                </div>
                {this.state.next ? <Redirect to="/u/account/financial-information/6"/> : <div/>}
            </form>
        )
    }

    componentDidMount() {
        const select = new MDCSelect(this.refs.select);
        select.listen("MDCSelect:change", e => {
            if (this.refs.select.classList.contains("mdc-select--next") !== !!e.detail.value) {
                this.refs.select.classList.toggle("mdc-select--next")
            }
            //console.log(e.detail);
        });
        this.refs.form.addEventListener('submit', e => {
            e.preventDefault();
            if (!select.value) {
                select.valid = false;
            }
            //console.log(select.value);

            select.disabled = true;

            this.props.dispatch(updateUserInvestorProfile("availableIncomePercent", select.value, () => {
                select.disabled = false;
                this.setState({next: true});
            }))
        });
    }
}

class AppFinancialInformationStep6 extends Component {
    state = {
        next: false,
    };

    render() {
        const {t} = this.props;
        return (
            <div className="steps__field">
                <span className="form-field__label mdc-typography--headline5">{t('fiBusiness6')}</span>
                <p className="form-field__label mdc-typography--body2">{t('fiBusiness6Text')}</p>
                <div className="layout-upload-documents">
                    <Link to="/u/account/financial-information/upload-id" type="button" className="button button--unelevated button--white">{t('uploadID')}</Link>
                    <Link to="/u/account/financial-information/upload-passport" type="button" className="button button--unelevated button--white">{t('uploadPassport')}</Link>
                </div>
                {this.state.next ? <Redirect to="/u/account"/> : <div/>}
                <div className="layout-upload-documents layout-upload-documents--end">
                    <button onClick={this._skipStep.bind(this)} type="button" className="button"
                            hidden={this.state.files && this.state.files.length > 0}>{t('skipStep')}
                    </button>
                </div>
            </div>
        )
    }

    _skipStep() {
        this.setState({next: true})
    }
}

class AppFinancialInformationStepUploadID extends Component {
    state = {
        front: null,
        back: null,
        loading: false,
        error: null,
        next: false,
    };

    render() {
        const {t} = this.props, {front, back, loading, error} = this.state;
        return (
            <form onSubmit={this._onSubmit} className="steps__field">
                <label className="form-field__label mdc-typography--headline5">{t('bothSidesOfID')}</label>
                <div className="dropbox-wrapper">
                    <label className="dropbox" onDragEnter={this._onDragEnter} onDragLeave={this._onDragLeave}
                           onDragOver={this._onDragOver} onDrop={this._onDropFront}>
                        <input disabled={loading} onChange={this._onChangeFront} name="documents" type="file" accept="image/jpeg" hidden/>
                        <div className="dropbox__upload">
                            <Check className="dropbox__upload-success" hidden={!front}/>
                            <img src={require('../images/id-front.png').default} alt="" hidden={!!front}/>
                            {front ? <img src={front.src} alt={front.file.name}/> : ''}
                            <span>{t('frontSide')}</span>
                        </div>
                    </label>
                    <label className="dropbox" onDragEnter={this._onDragEnter} onDragLeave={this._onDragLeave}
                           onDragOver={this._onDragOver} onDrop={this._onDropBack}>
                        <input disabled={loading} onChange={this._onChangeBack} name="documents" type="file" accept="image/jpeg" hidden/>
                        <div className="dropbox__upload">
                            <Check className="dropbox__upload-success" hidden={!back}/>
                            <img src={require('../images/id-back.png').default} alt="" hidden={!!back}/>
                            {back ? <img src={back.src} alt={back.file.name}/> : ''}
                            <span>{t('backSide')}</span>
                        </div>
                    </label>
                </div>

                <button type="submit" disabled={loading} className="button button--unelevated button--submit-action"
                        hidden={!front || !back}>{loading ? t('uploading') : t('continue')}
                </button>
                <p className="mdc-typography--body2 mdc-theme--error">
                    {error || ""}
                </p>
                {this.state.next ? <Redirect to="/u/account"/> : <div/>}
                <Link disabled={loading} to="/u/account/financial-information/upload" className="button">{t('goBack')}</Link>
            </form>
        )
    }

    _onDragEnter = e => {
        e.preventDefault()
        e.stopPropagation()

        e.target.classList.add("dropbox--highlight")
    }
    _onDragLeave = e => {
        e.preventDefault()
        e.stopPropagation()

        e.target.classList.remove("dropbox--highlight")
    }

    _onDragOver = e => {
        e.preventDefault()
        e.stopPropagation()

        e.target.classList.add("dropbox--highlight")
    }

    _onDropFront = e => {
        e.preventDefault()
        e.stopPropagation()

        e.target.classList.remove("dropbox--highlight")

        this._fileChange(e.dataTransfer.files, "front")
    }

    _onDropBack = e => {
        e.preventDefault()
        e.stopPropagation()

        e.target.classList.remove("dropbox--highlight")

        this._fileChange(e.dataTransfer.files, "back")
    }

    _fileChange(files, side = "front") {
        if(files && files.length > 0) {
            const reader = new FileReader(), file = files[0];
            reader.onload = e2 => {
                this.setState({[side]: {file, src: e2.target.result}});
            };
            reader.readAsDataURL(file);
        }
    }

    _onChangeFront = e => this._fileChange(e.target.files, "front")

    _onChangeBack = e => this._fileChange(e.target.files, "back")

    _onSubmit = e => {
        e.preventDefault();

        this.setState({loading: true})

        const fd = new FormData(e.target);
        fd.set("type", "ID");

        this.props.dispatch(uploadDocuments(fd, ok => {
            if(ok) {
                this.setState({next: true, loading: false})
            } else {
                this.setState({loading: false, back: null, front: null, error: this.props.t('errorUploading')})
            }
        }))
    }
}

class AppFinancialInformationStepUploadPassport extends Component {
    state = {
        front: null,
        loading: false,
        error: null,
        next: false,
    };

    render() {
        const {t} = this.props, {front, loading, error} = this.state;
        return (
            <form onSubmit={this._onSubmit} className="steps__field">
                <label className="form-field__label mdc-typography--headline5">{t('uploadPassport')}</label>
                <div className="dropbox-wrapper">
                    <label className="dropbox" onDragEnter={this._onDragEnter} onDragLeave={this._onDragLeave}
                           onDragOver={this._onDragOver} onDrop={this._onDrop}>
                        <input disabled={loading} onChange={this._onChangeFront} name="documents" type="file" accept="image/jpeg" hidden/>
                        <div className="dropbox__upload">
                            <Check className="dropbox__upload-success" hidden={!front}/>
                            <img src={require('../images/passport.png').default} alt="" hidden={!!front}/>
                            {front ? <img src={front.src} alt={front.file.name}/> : ''}
                            <span>{t('selectFile')}</span>
                        </div>
                    </label>
                </div>

                <button type="submit" disabled={loading} className="button button--unelevated button--submit-action"
                        hidden={!front}>{loading ? t('uploading') : t('continue')}
                </button>
                <p className="mdc-typography--body2 mdc-theme--error">
                    {error || ""}
                </p>
                {this.state.next ? <Redirect to="/u/account"/> : <div/>}
                <Link disabled={loading} to="/u/account/financial-information/upload" className="button">{t('goBack')}</Link>
            </form>
        )
    }

    _onDragEnter = e => {
        e.preventDefault()
        e.stopPropagation()

        e.target.classList.add("dropbox--highlight")
    }
    _onDragLeave = e => {
        e.preventDefault()
        e.stopPropagation()

        e.target.classList.remove("dropbox--highlight")
    }
    _onDragOver = e => {
        e.preventDefault()
        e.stopPropagation()

        e.target.classList.add("dropbox--highlight")
    }
    _onDrop = e => {
        e.preventDefault()
        e.stopPropagation()

        e.target.classList.remove("dropbox--highlight")

        this._fileChange(e.dataTransfer.files, "front")
    }

    _fileChange(files, side = "front") {
        if(files && files.length > 0) {
            const reader = new FileReader(), file = files[0];
            reader.onload = e2 => {
                this.setState({[side]: {file, src: e2.target.result}});
            };
            reader.readAsDataURL(file);
        }
    }

    _onChangeFront = e => {
        this._fileChange(e.target.files, "front")
    }

    _onSubmit = e => {
        e.preventDefault();

        this.setState({loading: true})

        const fd = new FormData(e.target);
        fd.set("type", "PASSPORT");

        this.props.dispatch(uploadDocuments(fd, ok => {
            if(ok) {
                this.setState({next: true, loading: false})
            } else {
                this.setState({loading: false, front: null, error: this.props.t('errorUploading')})
            }
        }))
    }
}

export default connect(mapStateToProps)(withTranslation('app')(AppFinancialInformation));
