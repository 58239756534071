import {GET_ALL_DEPOSITS} from '../actions/deposits';
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    deposits: {},
    depositIds: [], // for displaying results in order
    sort: "",
    direction: "",
    limit: 20,
    offset: 0,
    atEnd: false,
};

const deposits = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_DEPOSITS:
            return {
                ...state,
                deposits: {...state.deposits, ...action.deposits},
                depositIds: action.depositIds,
                atEnd: action.atEnd,
                sort: action.sort,
                direction: action.direction,
                limit: action.limit,
                offset: action.offset,
            };
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default deposits;
