import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";
import {GET_ALL_INVESTMENTS} from "./investment";

export const GET_ALL_DEPOSITS = 'GET_ALL_DEPOSITS';

export const getAllDeposits = ({
                                   offset = 0,
                                   sort = "date",
                                   direction = "desc",
                               } = {}) => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader,
        userId = state.user.currentUser.Id,
        oldIds = state.deposits.depositIds || [],
        limit = state.deposits.limit;

    sort = sort || state.deposits.sort;
    direction = direction || state.deposits.direction;

    fetch(API_URL + '/deposits/' + userId + '?sort=' + sort + '&direction=' + direction + '&limit=' + limit + '&offset=' + offset, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then((_data = []) => {

        let ids = [], newCount = 0;
        let data = _data.reduce((obj, it) => {
            obj[it.Id] = it;
            ids.push(it.Id);
            newCount++
            return obj
        }, {});

        // removes key from oldIds if request returned same entry in result
        for (let i = oldIds.length - 1; i >= 0; i--) {
            if (data.hasOwnProperty(oldIds[i])) {
                oldIds.splice(i, 1);
                newCount--
            }
        }
        if (offset > 0) {
            ids = [...oldIds, ...ids]
        }
        dispatch({
            type: GET_ALL_DEPOSITS,
            deposits: data, depositIds: ids, sort, direction, limit, offset, atEnd: newCount === 0 || newCount < limit
        })
    }).catch(err => {
        console.log(err);
    })
};
