import {GET_ALL_TRANSACTIONS} from '../actions/transactions';
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    transactions: {}
};

const transactions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_TRANSACTIONS:
            return {
                ...state,
                transactions: action.transactions
            };
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default transactions;
