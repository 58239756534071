import React from "react";
import {connect} from "react-redux";
import {Trans, withTranslation} from "react-i18next";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


const mapStateToProps = state => {
    return {user: state.user.currentUser, _activeTab: state.tabs.tab};
};

const useStyles = makeStyles({
    dialog: {
        borderRadius: '16px!important',
    },
    title: {
        color: '#fff',
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
        color: '#fff',
    },
});


/* Animacija modalnega okna */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function StrategyConfirmDialog(props) {
    const classes = useStyles();
    const {onClose, open, options = {}, t} = props;
    const theme = useTheme();

    // Prelomnica za mobilne naprave
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Dialog
            classes={{paper: classes.dialog}}
            fullScreen={fullScreen}
            aria-labelledby="simple-dialog-title"
            open={open}
            TransitionComponent={Transition}>
            <DialogTitle id="simple-dialog-title">
                <span className="mdc-typography--headline5"><Trans ns='app' i18nKey={options?.title}><b/></Trans></span>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p className="mdc-typography--body2">
                        <Trans ns='app' i18nKey={"selectStrategyConfirmText"}/>
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button type="button" className="button button--outlined button--white"
                        data-mdc-dialog-action="close" onClick={() => onClose(false)}>
                    <span className="mdc-button__label">  {t('cancel')}</span>
                </button>
                <button type="button" className="button button--unelevated button--white" onClick={() => onClose(true)}>
                    {t('confirm')}
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default connect(mapStateToProps)(withTranslation("app")(StrategyConfirmDialog));
