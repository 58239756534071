import React, {Component} from "react";

import {ReactComponent as ArrowLeft} from "../images/arrow-left.svg";
import {connect} from "react-redux";
import InvestorProfileBanner from "../components/InvestorProfileBanner";
import {updateUserInvestorProfile, updateUserTaxNo} from "../actions/user";
import DepositBanner from "../components/DepositBanner";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import countries from '../locales/countries'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


const mapStateToProps = state => {
    return {user: state.user.currentUser, _activeTab: state.tabs.tab};
};

const countryList = Object.keys(countries).map((key, i) => {
    const c = countries[key];
    return Object.assign({text: c.name.toUpperCase()}, c)
});

class AppInvestorProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editingTaxResidence: false,
            editingTaxNo: false,
            drzavaDavcnegaRezidentaValue: this.user?.investorProfile.countryOfResidence,
            isDrzavaValid: true,
        };
    }

    render() {
        const {user, t} = this.props, 
        {
            editingTaxResidence,
            editingTaxNo,
            editingIncome,
            drzavaDavcnegaRezidentaValue,
            isDrzavaValid
        } = this.state;

        return (
            <section className={this.props._activeTab === 1 ? "page page--next active" : "page page--next not-active"}>
                <div className="page__inner">
                    <div className="custom-navigation-wrapper" ref="accountBtn">
                        <div className="custom-navigation">
                            <div className="custom-navigation__text">
                                <span>{t('accountTitle')}</span>
                            </div>
                            <div className="custom-navigation__icon">
                                <ArrowLeft/>
                            </div>
                        </div>
                    </div>

                    {user.status.confirmed === true && ((user.investorType === "Personal" && user.investorProfile && user.investorProfile.completed) || (user.investorType === "Business" && user.companyInvestorProfile && user.companyInvestorProfile.completed)) ? (
                        <DepositBanner/>
                    ) : (
                        <InvestorProfileBanner/>
                    )}

                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet">
                                <h2 className="mdc-typography--headline5 title title--border">{t('investorProfileTitle')}</h2>
                                <i className="title-spacer"/>

                                <div className="list list--vertical list--ordinal-background">
                                    {user.company ? (
                                        <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('companyName')}</span>
                                            <span
                                                className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.company.name}</b></span>
                                        </div>
                                    ) : <noscript/>}
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('firstName')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.contact.firstName}</b></span>
                                    </div>
                                    <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('lastName')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.contact.lastName}</b></span>
                                    </div>
                                    <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('address')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.address.streetName}, {user.address.townOrSuburb}</b></span>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('countryOfCitizenship')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.address.regionOrState}</b></span>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('defaultBankAccount')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.mainBankAccount ? user.mainBankAccount.accountNumber : '-'}</b></span>
                                    </div>

                                    {/* Država davčnega rezidenta */}
                                    <div className="list-item">
                                        <span className="mdc-typography--body1 list-item__text custom-account-list-item">
                                            {t('countryOfTaxResidence')}
                                        </span>
                                        <div className="mdc-typography--body1 list-item__other custom-account-list-item">
                                            { !editingTaxResidence ? 
                                            <b className="editable-textfield__placeholder">
                                                {user.investorProfile?.countryOfResidence ? user.investorProfile.countryOfResidence : ""}
                                            </b>
                                            :
                                            <Autocomplete
                                                value={drzavaDavcnegaRezidentaValue}
                                                id="combo-box-demo"
                                                options={countryList}
                                                getOptionLabel={(option) => option.name}
                                                fullWidth={true}
                                                openOnFocus={true}
                                                noOptionsText='Države ni na seznamu.'
                                                size={'medium'}
                                                onChange={(event, newValue) => {
                                                    this.drzavaDavcnegaRezidentaValue = newValue;
                                                }}
                                                onInputChange={(event, newInputValue) => this._isDrzavaSelected(newInputValue)}
                                                renderInput={(params) =><TextField { ...params} autoFocus error  />}
                                            />
                                             }
                                            <div className="mdc-typography--body1 list-item__text custom-account-list-item custom-account-list-item--adjust-end">
                                                <button disabled ={!isDrzavaValid}
                                                    className={editingTaxResidence ? "button button--unelevated button--white" : "button button--unelevated button--primary"}
                                                    onClick={this._editTaxResidence.bind(this)}>
                                                    {editingTaxResidence ? t('save') : t('edit')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>                       
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('taxNumber')}</span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item">
                                            <div
                                                className={editingTaxNo ? "editable-textfield editable-textfield--editable" : "editable-textfield"}>
                                                <b className="editable-textfield__placeholder">{user.taxNo}</b>
                                                <input ref="taxNo" className="textfield editable-textfield__input"
                                                       type="text"
                                                       defaultValue={user.taxNo}/>
                                            </div>
                                            <div
                                                className="mdc-typography--body1 list-item__text custom-account-list-item custom-account-list-item--adjust-end">
                                                <button
                                                    className={editingTaxNo ? "button button--unelevated button--white" : "button button--unelevated button--primary"}
                                                    onClick={this._editTaxNo.bind(this)}>
                                                    {editingTaxNo ? t('save') : t('edit')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('sourceOfMoney')}</span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item">
                                            <b>Slovenia</b>
                                            <div
                                                className="mdc-typography--body1 list-item__text custom-account-list-item custom-account-list-item--adjust-end">
                                                <button className="button button--unelevated button--primary">{t('edit')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>*/}
                                    {/*<div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('annualIncome')}</span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item">
                                            <div
                                                className={editingIncome ? "editable-textfield editable-textfield--editable" : "editable-textfield"}>
                                                <b className="editable-textfield__placeholder">{user.investorProfile ? user.investorProfile.householdIncome : ""}</b>
                                                <input ref="income" className="textfield editable-textfield__input"
                                                       type="text"
                                                       defaultValue={user.investorProfile ? user.investorProfile.householdIncome : ""}/>
                                            </div>
                                            <div
                                                className="mdc-typography--body1 list-item__text custom-account-list-item custom-account-list-item--adjust-end">
                                                <button
                                                    className={editingIncome ? "button button--unelevated button--white" : "button button--unelevated button--primary"}
                                                    onClick={this._editIncome.bind(this)}>
                                                    {editingIncome ? t('save') : t('edit')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>*/}
                                    {user.status.confirmed ? '' : (
                                        <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('idDocument')}</span>
                                            <div
                                                className="mdc-typography--body1 list-item__other custom-account-list-item">
                                                <div
                                                    className="mdc-typography--body1 list-item__text custom-account-list-item custom-account-list-item--adjust-end">
                                                    <Link to={"/u/account/financial-information/upload"}
                                                          className="button button--unelevated button--primary">{t('upload')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="list-item list">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('idDocumentValidityDate')}</span>
                                        <span
                                            className={`mdc-typography--body1 ${user.status.confirmed ? "list-item__other" : "list-item__text"} custom-account-list-item`}><b>{user.identificationDocument?.validTo ? user.identificationDocument.validTo : t('N/A')}</b></span>
                                    </div>
                                </div>
                            </div>

                            {user.investorType === "Business" ? this._renderBusiness(user.companyInvestorProfile, t) : this._renderPersonal(user.investorProfile, user.taxNo, t)}
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    _renderBusiness(profile, t) {
        return (
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet">
                <h2 className="mdc-typography--headline5 title title--border">{t('validationForm')}</h2>
                <i className="title-spacer"/>

                <div className="list list--vertical list--ordinal-background">
                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('fiBusiness1')}</b></span>
                    </div>
                    <div className="list-item">
                        <div className="mdc-typography--body1 list-item__text custom-account-list-item">
                            {profile.economicActivity || t("N/A")}
                        </div>
                    </div>
                    <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('fiBusiness2')}</b></span>
                    </div>
                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{profile.mainCounterPartyOrigin ? t("region." + profile.mainCounterPartyOrigin) : t("N/A")}</span>
                    </div>
                    <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('fiBusiness3')}</b></span>
                    </div>
                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{profile.type ? t("companyType." + profile.type) : t("N/A")}</span>
                    </div>
                    <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('fiBusiness4')}</b></span>
                    </div>
                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{profile.annualIncome ? t("annualIncome." + profile.annualIncome) : t("N/A")}</span>
                    </div>
                    <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('fiBusiness5')}</b></span>
                    </div>
                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{profile.availableIncomePercent ? t("investPercentage." + profile.availableIncomePercent) : t("N/A")}</span>
                    </div>
                </div>
            </div>
        )
    }

    _renderPersonal(profile, taxNo, t) {
        return (<div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet">
            <h2 className="mdc-typography--headline5 title title--border">{t('validationForm')}</h2>
            <i className="title-spacer"/>

            <div className="list list--vertical list--ordinal-background">
                <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('fiPersonal1')}</b></span>
                </div>
                <div className="list-item">
                    <div className="mdc-typography--body1 list-item__text custom-account-list-item">
                        {profile.fundsSources && profile.fundsSources.length > 0 ? t("personalSourceOfFunds." + profile.fundsSources[0]) : t("N/A")}
                    </div>
                </div>
                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('fiPersonal2')}</b></span>
                </div>
                <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{profile.annualIncome ? t("personalAnnualIncome." + profile.annualIncome) : t("N/A")}</span>
                </div>
                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('fiPersonal3')}</b></span>
                </div>
                <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{profile.countryOfResidence || t("N/A")}</span>
                </div>
                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('fiPersonal4')}</b></span>
                </div>
                <div className="list-item">
                  <span className="mdc-typography--body1 list-item__text custom-account-list-item">{taxNo || t("N/A")}</span>
                </div>
            </div>
        </div>)
    }

    _openUploadDialog() {
        //this.props.dispatch(openDialog(DIALOG_UPLOAD_ID))
    }

    _isDrzavaSelected(value) {
        if (value !== '') {
            const isDrzavaValid = true;
            this.setState({isDrzavaValid})
        } else {
            const isDrzavaValid = false;
            this.setState({isDrzavaValid});
        }
    }

    _editTaxResidence(e) {
        const {editingTaxResidence} = this.state;
        this.setState({editingTaxResidence: !editingTaxResidence});
        
        if (editingTaxResidence) {
            // save
            const value = this.drzavaDavcnegaRezidentaValue.name;

            if (value !== '') {
                this.props.dispatch(updateUserInvestorProfile("countryOfResidence", value));
            }
        }
    }

    _editTaxNo(e) {
        const {editingTaxNo} = this.state;
        this.setState({editingTaxNo: !editingTaxNo});

        if (editingTaxNo) {
            // save
            const value = this.refs.taxNo.value;
            this.props.dispatch(updateUserTaxNo(value));
        } else {

            setTimeout(() => {
                this.refs.taxNo.select();
                this.refs.taxNo.focus();
            }, 100)
        }

    }

    _editIncome(e) {
        const {editingIncome} = this.state;
        this.setState({editingIncome: !editingIncome});

        if (editingIncome) {
            // save
            const value = this.refs.income.value;
            this.props.dispatch(updateUserInvestorProfile("householdIncome", value));
        }
    }

    componentDidMount() {
        //const chipSet = new MDCChipSet(this.refs.chips);
        this.refs.accountBtn.addEventListener('click', () => {
            document.getElementsByClassName('page--prev')[0].classList.toggle('not-active');
            document.getElementsByClassName('page--next')[0].classList.toggle('active');
        });
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppInvestorProfile));