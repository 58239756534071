import React, {Component} from 'react';
import {MDCTabBar} from "@material/tab-bar/component";
import {connect} from "react-redux";
import {setActiveTab} from "../actions/tabs";

const mapStateToProps = state => {

    return {userInvestments: state.investment.userInvestments};
};

class TabSwitcher extends Component {
    render() {
        return (
            <div ref="tabBar" className="mdc-tab-bar" role="tablist">
                <div className="mdc-tab-scroller">
                    <div className="mdc-tab-scroller__scroll-area">
                        <div className="mdc-tab-scroller__scroll-content">
                            {this.props.tabs.map(((value, index) => (
                                <button key={index} className={index === 0 ? "mdc-tab mdc-tab--active" : "mdc-tab"}
                                        role="tab" aria-selected={index === 0} tabIndex={index === 0 ? "0" : ""}>
                                                                            <span className="mdc-tab__content"><span
                                                                                className="mdc-tab__text-label">{value}</span></span>
                                    <span
                                        className={index === 0 ? "mdc-tab-indicator mdc-tab-indicator--active" : "mdc-tab-indicator"}><span
                                        className="mdc-tab-indicator__content mdc-tab-indicator__content--underline"/></span>
                                    <span className="mdc-tab__ripple"/>
                                </button>

                            )))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const tabBar = new MDCTabBar(this.refs.tabBar);
        this.refs.tabBar.addEventListener('MDCTabBar:activated', e => {
            this.props.dispatch(setActiveTab(e.detail.index));
        })
    }
}

export default connect(mapStateToProps)(TabSwitcher);
