import React, {Component, useEffect, useRef, useState} from 'react';

import '../styles/app.scss';
import {ReactComponent as Linkedin} from "../images/Linkedin.svg";
import {ReactComponent as Instagram} from "../images/Instagram.svg";
import {ReactComponent as Facebook} from "../images/Facebook.svg";
import {ReactComponent as HomeIcon} from "../images/home-button.svg";
import {ReactComponent as GrowthIcon} from "../images/growth-icon.svg";
import {ReactComponent as DepositIcon} from "../images/credit-card.svg";
import {ReactComponent as WalletIcon} from "../images/wallet-icon.svg";
import {ReactComponent as UserIcon} from "../images/user-icon.svg";
import {ReactComponent as SpeedIcon} from "../images/speed-icon.svg";
import {ReactComponent as NotificationsIcon} from "../images/outline-notifications-24px.svg";
import {ReactComponent as SettingsIcon} from "../images/exit_to_app-black-24dp.svg";
import ScrollToTop from "../components/ScrollToTop";
import {BrowserRouter as Router, NavLink, Redirect, Route, Switch, useHistory} from "react-router-dom";
import AppHome from "./AppHome";
import AppInvestments from "./AppInvestments";
import AppDeposit from "./AppDeposit";
import AppAutoInvest from "./AppAutoInvest";
import AppWithdraw from "./AppWithdraw";
import AppMyInvestments from "./AppMyInvestments";

import AppCustomAutoInvest from "./AppCustomAutoInvest";
import AppAccount from "./AppAccount";
import AppInvestorProfile from "./AppInvestorProfile";
import AppNewInvestment from "./AppNewInvestment";

import {connect, useDispatch} from "react-redux";
import {authenticateUser} from "../actions/user";
import AppFinancialInformation from "./AppFinancialInformation";
import AppMyInvestment from "./AppMyInvestment";
import TabSwitcher from "../components/TabSwitcher";
import {setActiveTab} from "../actions/tabs";
import {withTranslation} from "react-i18next";
import {getNotifications} from "../actions/notifications";
import {MDCMenu} from "@material/menu/component";
import Dialog from "../components/dialogs/Dialog";
import AppSummary from "./AppSummary";
import AppUserChangePassword from "./AppUserChangePassword";
import Analytics from "../components/Analytics";
import AppStats from "./AppStats";
import {resetState} from "../actions/root";

const mapStateToProps = state => {
    return {
        _lang: state.user.currentUser && state.user.currentUser.settings ? state.user.currentUser.settings.language : "EN",
        _currentUser: state.user.currentUser,
        _redirectOnLoginUri: state.user.redirectOnLoginUri,
        _notifications: state.notifications.notifications,
    };
};

function App(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {_currentUser, _notifications, t} = props;
    const [redirect, setRedirect] = useState("");

    const mainWrapper = useRef();
    const navList = useRef();
    const notificationsBtn = useRef();
    const notifications = useRef();

    useEffect(() => {
        dispatch(getNotifications());

        if (props._redirectOnLoginUri?.startsWith("/u/")) setRedirect(props._redirectOnLoginUri)
    }, [])

    useEffect(() => {
        if (props._lang && props.i18n) {
            props.i18n.changeLanguage(props._lang);
        }
    }, [props._lang, props.i18n])

    useEffect(() => {
        if (history) {
            const uns = history.listen((location) => {
                if (window.ga) {
                    window.ga('set', 'page', location.pathname + location.search);
                    window.ga('send', 'pageview');
                }
            })
            /*return () => {
                uns()
            }*/
        }

    }, [history])

    useEffect(() => {
        if (notifications?.current && notificationsBtn?.current) {
            const _menu = new MDCMenu(notifications.current);
            //_menu.setFixedPosition(true);
            //_menu.hoistMenuToBody();
            _menu.setAnchorCorner(1);
            const _infoListener = e => {
                _menu.open = true
            };
            notificationsBtn?.current.addEventListener("click", _infoListener)
        }

    }, [notifications?.current, notificationsBtn?.current])

    function logOut() {
        dispatch(authenticateUser());
        dispatch(resetState());
    }

    function _tabsSwitch(i) {
        //console.log("tab switch", i)
        //props._depositTabActive
    }

    return (
        <Router history={history}>
            {redirect && (<Redirect to={redirect}/>)}
            <div className="app mdc-typography">
                <main ref={mainWrapper} className="app__main-wrapper">
                    <aside className="app__main-sidebar">
                        {/*<div className="maximize-wrapper">
                                <button
                                    className="icon-button icon-button--primary icon-button--dense icon-button--right"
                                    ref="maximize">
                                    <HamburgerIcon/>
                                </button>
                            </div>*/}
                        <div ref={navList} className="mdc-list mdc-list--nav mdc-list--non-interactive">
                            <NavLink to="/u/dashboard" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <span className="mdc-list-item__graphic icon-button"><HomeIcon/></span>
                                <span className="mdc-list-item__text">{t('navHome')}</span>
                            </NavLink>
                            <NavLink to="/u/auto-invest-strategies" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <span className="mdc-list-item__graphic icon-button"><GrowthIcon/></span>
                                <span className="mdc-list-item__text">{t('navInvestments')}</span>
                            </NavLink>
                            <NavLink to="/u/deposits" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <span className="mdc-list-item__graphic icon-button"><DepositIcon/></span>
                                <span className="mdc-list-item__text">{t('navWallet')}</span>
                            </NavLink>
                            <NavLink to="/u/my-investments" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <span className="mdc-list-item__graphic icon-button"><WalletIcon/></span>
                                <span className="mdc-list-item__text">{t('navMyInvestments')}</span>
                            </NavLink>
                            <NavLink to="/u/account" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <span className="mdc-list-item__graphic icon-button"><UserIcon/></span>
                                <span className="mdc-list-item__text">{t('navAccount')}</span>
                            </NavLink>
                            <NavLink to="/u/performance" className="mdc-list-item"
                                     activeClassName="mdc-list-item--active">
                                <span className="mdc-list-item__graphic icon-button"><SpeedIcon/></span>
                                <span className="mdc-list-item__text">{t('navPlatformPerformance')}</span>
                            </NavLink>
                        </div>
                        <div className="mdc-layout-grid mdc-layout-grid-logo-container">
                            <div className="logoWrapper"/>
                        </div>
                    </aside>

                    <div className="app__main">
                        <header className="app__main-header">
                                <span className="mdc-typography--body2 hide-on-mobile">{t('welcomeBackName')} <b
                                    className="app__main-header-name">{_currentUser && _currentUser.contact ? _currentUser.contact.firstName : ''}</b></span>
                            <div className="app__main-header__logo show-on-mobile"/>
                            <div className="app__main-header__actions">
                                <div className="mdc-menu-surface--anchor">
                                    <button ref={notificationsBtn} className="icon-button icon-button--dense">
                                        <NotificationsIcon/></button>
                                    <div ref={notifications} className="mdc-menu mdc-menu-surface">
                                        <h4 className="mdc-menu__title">{t('notifications')}</h4>
                                        <ul className={"mdc-list" + ((_notifications || []).length > 0 ? ' mdc-list--two-line' : '')}
                                            role="menu" aria-hidden="true"
                                            aria-orientation="vertical" tabIndex="-1">
                                            <li className="mdc-list-divider"/>
                                            {(_notifications || []).map((item, index) => (
                                                <li key={"notification-" + index} className="mdc-list-item"
                                                    role="menuitem">
                                                        <span className="mdc-list-item__text">
                                                          <span
                                                              className="mdc-list-item__primary-text">{item.type}</span>
                                                          <span
                                                              className="mdc-list-item__secondary-text">{item.date}</span>
                                                        </span>
                                                </li>
                                            ))}
                                            {(_notifications || []).length === 0 && <li key={"notification"}
                                                                                        className="mdc-list-item mdc-list-item--disabled">
                                                        <span className="mdc-list-item__text">
                                                          <span
                                                              className="mdc-list-item__secondary-text">{t('noNotifications')}</span>
                                                        </span>
                                            </li>}
                                        </ul>
                                    </div>
                                </div>
                                <button onClick={logOut} className="icon-button icon-button--dense">
                                    <SettingsIcon/></button>
                            </div>
                        </header>
                        <div className="app__main-content">
                            <ScrollToTop>
                                <Switch>
                                    <Route exact path="/" render={() => (
                                        <Redirect to="/u/dashboard"/>
                                    )}/>
                                    <Route exact path="/u" render={() => (
                                        <Redirect to="/u/dashboard"/>
                                    )}/>
                                    <Route exact path="/u/" render={() => (
                                        <Redirect to="/u/dashboard"/>
                                    )}/>
                                    <Route exact path="/u/dashboard" component={() => (
                                        <AppHome/>
                                    )}/>
                                    <Route exact path="/u/performance" component={() => (
                                        <AppStats/>
                                    )}/>
                                    <Route path="/u/auto-invest-strategies" component={() => (
                                        <AppAutoInvest/>
                                    )}/>
                                    <Route path="/u/custom-invest-strategy" component={() => (
                                        <AppCustomAutoInvest/>
                                    )}/>
                                    <Route path="/u/account/financial-information"
                                           component={() => (<AppFinancialInformation/>)}/>
                                    <Route path="/u/investments/:id" component={(props) => (
                                        <AppNewInvestment id={props.match.params.id}/>
                                    )}/>
                                    <Route path="/u/investments" component={() => (
                                        <AppInvestments/>
                                    )}/>
                                    <Route path="/u/deposits" render={() => {
                                        dispatch(setActiveTab(0));

                                        return (
                                            <div className="display-container display-container--mobile">
                                                <div className="display-container hide-on-mobile">
                                                    <AppDeposit/>
                                                    <AppWithdraw/>
                                                </div>
                                                <div className="show-on-mobile">
                                                    <div className="display-container">
                                                        <AppDeposit active={props._depositTabActive}/>
                                                        <AppWithdraw active={props._withdrawTabActive}/>
                                                    </div>
                                                    <TabSwitcher tabs={["Deposit", "Withdraw"]}
                                                                 onChange={e => _tabsSwitch(e)}/>
                                                </div>
                                            </div>
                                        );
                                    }}/>
                                    <Route path="/u/account" render={() => {
                                        props.dispatch(setActiveTab(0));

                                        return (
                                            <div className="display-container display-container--mobile">
                                                <div className="display-container hide-on-mobile">
                                                    <AppAccount/>
                                                    <AppInvestorProfile/>
                                                </div>
                                                <div className="show-on-mobile">
                                                    <div className="display-container">
                                                        <AppAccount active={props._accountTabActive}/>
                                                        <AppInvestorProfile active={props._profileTabActive}/>
                                                    </div>
                                                    <TabSwitcher tabs={["Account", "Investor profile"]}
                                                                 onChange={e => _tabsSwitch(e)}/>
                                                </div>
                                            </div>
                                        );
                                    }}/>
                                    <Route path="/u/my-investments/:id" component={(props) => (
                                        <AppMyInvestment id={props.match.params.id}/>
                                    )}/>
                                    <Route path="/u/my-investments" render={() => {
                                        props.dispatch(setActiveTab(0));

                                        return (
                                            <div className="display-container display-container--mobile">
                                                <div className="display-container hide-on-mobile">
                                                    <AppMyInvestments/>
                                                    <AppSummary/>
                                                </div>
                                                <div className="show-on-mobile">
                                                    <div className="display-container">
                                                        <AppMyInvestments active={props._depositTabActive}/>
                                                        <AppSummary active={props._withdrawTabActive}/>
                                                    </div>
                                                    <TabSwitcher tabs={["My investments", "Account summary"]}
                                                                 onChange={e => _tabsSwitch(e)}/>
                                                </div>
                                            </div>
                                        );

                                    }}/>
                                    {/*<Route path="/u/performance" component={() => (
                                            <ComingSoon/>
                                        )}/>*/}
                                    <Route exact path="/u/change-password" component={({match = {}} = {}) => (
                                        <AppUserChangePassword/>
                                    )}/>
                                </Switch>
                            </ScrollToTop>
                        </div>
                    </div>
                </main>
                <footer className="app__footer">
                    <div className="app__footer-connect mdc-layout-grid mdc-layout-grid--align-left">
                        <div className="mdc-layout-grid__inner">
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-1 mdc-layout-grid__cell--align-middle">
                                <a className="icon-button icon-button--dense icon-button--outlined icon-button--shaped-circle"
                                   href="https://www.linkedin.com/company/nekster/?viewAsMember=true"
                                   target="_blank"><Linkedin/></a>
                            </div>
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-1 mdc-layout-grid__cell--align-middle">
                                <a className="icon-button icon-button--dense icon-button--outlined icon-button--shaped-circle"
                                   href="https://www.instagram.com/nekster.eu/?hl=en"
                                   target="_blank"><Instagram/></a>
                            </div>
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-1 mdc-layout-grid__cell--align-middle">
                                <a className="icon-button icon-button--dense icon-button--outlined icon-button--shaped-circle"
                                   href="https://www.facebook.com/NeksterSmartInvestment"
                                   target="_blank"><Facebook/></a>
                            </div>
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-9 mdc-layout-grid__cell--align-middle">
                                <span className="mdc-typography--body1 connect__title">{t('connectNow')}</span>
                                <span
                                    className="mdc-typography--caption connect__subtitle">{t('visitUsOnSocialNetworks')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="mdc-typography--caption app__footer-links">
                        <span>&copy; 2022. Nekster, d.o.o.</span>
                        {/*<span>Update cookie settings</span>*/}
                        {/*<span>Privacy policy</span>*/}
                        {/*<span>Imprint</span>*/}
                    </div>
                </footer>
                {/*<div className="app-loader">Loading...</div>*/}
                <Dialog/>
            </div>
            <Analytics/>
        </Router>
    );
}

export default connect(mapStateToProps)(withTranslation("app")(App));
