import React, {useEffect, useState} from "react";
import {ReactComponent as CheckedIcon} from "../images/checked.svg";
import {Link} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {cancelAutoInvest, getAutoInvests, selectAutoInvest} from "../actions/autoinvest";
import ManualInvestmentsBanner from "../components/ManualInvestmentsBanner";
import {Trans, withTranslation} from "react-i18next";
import {getInvestmentStats} from "../actions/investment";
import StrategyConfirmDialog from "../components/dialogs/StrategyConfirmDialog";

const mapStateToProps = state => {
    return {
        _userAutoInvest: state.autoinvest.userAutoInvest,
        investmentsStats: state.investment.investmentsStats,
    };
};

function AppAutoInvest(props) {
    const {_userAutoInvest = {}, investmentsStats = {}, t} = props,
        isCustom = _userAutoInvest.type === "CUSTOM",
        isAutoShort = _userAutoInvest.type === "SHORT_TERM", isAutoLong = _userAutoInvest.type === "LONG_TERM";
    const [open, setOpen] = useState(false);
    const [dialogOptions, setDialogOptions] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAutoInvests());
        dispatch(getInvestmentStats());
    }, [dispatch])

    function dialogHandleClose(confirmed) {
        setOpen(false);
        if (confirmed && dialogOptions?.type) {
            dispatch(selectAutoInvest(dialogOptions?.type, ok => {
                if (ok) {
                    dispatch(getAutoInvests());
                }
            }));
        }
    }

    function _selectShortTermAutoInvest() {
        setDialogOptions({
            title: "aiHighLiquidity",
            type: "SHORT_TERM"
        })
        setOpen(true)
    }

    function _selectLongTermAutoInvest() {
        setDialogOptions({
            title: "aiHighReturn",
            type: "LONG_TERM"
        })
        setOpen(true)
    }

    function _cancelAutoInvest() {
        dispatch(cancelAutoInvest(ok => {
            if (ok) {
                dispatch(getAutoInvests());
            }
        }));
    }

    return (
        <div>
            <ManualInvestmentsBanner/>
            {/*<CustomAutoInvestBanner/>*/}
            <div className="mdc-layout-grid mdc-layout-grid--big-gutter">
                <div className="mdc-layout-grid__inner">
                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                        <h2 className="mdc-typography--headline5 title title--border">{t('aiStrategiesTitle')}</h2>
                        <span className="mdc-typography--body2">{t('aiStrategiesSubtitle')}</span>
                        {/*<i className="title-spacer"/>*/}
                    </div>

                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                        <div
                            className={"card card--outlined card--shaped strategy-card" + (isAutoShort ? " strategy-card--selected" : "")}>
                            {/*<div className="card__tag card__tag-no-tb hide-on-mobile">
                                    <span className="mdc-typography--subtitle2">0 &ndash; 3</span>
                                    <span className="mdc-typography--caption">months</span>
                                </div>*/}
                            <div className="card__header strategy-card__header">
                                <h4 className="mdc-typography--headline6 card__headline">
                                    <Trans ns='app' i18nKey={'aiHighLiquidity'}><b/></Trans>
                                </h4>
                                {/*<span className="mdc-typography--subtitle1">from <b>7 %</b> return</span>*/}
                            </div>
                            <ul className="card__content strategy-card__content list list--vertical list--dots">
                                <li className="list-item">
                                    <span className="mdc-typography--body2">{t('aiHighLiquidityText1')}</span>
                                </li>
                                <li className="list-item">
                                    <span className="mdc-typography--body2">{t('aiHighLiquidityText2')}</span>
                                </li>
                            </ul>
                            <div className="strategy-card__stats">
                                <div className="strategy-card__stat-item">
                                        <span
                                            className="strategy-card__stat-item-title">{t('threeYearYield')}</span>
                                    <span
                                        className="strategy-card__stat-item-number">{("" + (investmentsStats?.months36?.bg?.interestRate || "0.00")).replace(".", ",")}%</span>
                                </div>
                                <div className="strategy-card__stat-item">
                                        <span
                                            className="strategy-card__stat-item-title">{t('oneYearYield')}</span>
                                    <span
                                        className="strategy-card__stat-item-number">{("" + (investmentsStats?.months12?.bg?.interestRate || "0.00")).replace(".", ",")}%</span>
                                </div>
                            </div>
                            <div className="card__footer">
                                {isAutoShort ? (
                                    <button onClick={_cancelAutoInvest}
                                            className="button button--fill button--green button--unelevated button--prefix-icon">
                                        <div className="button__icon">
                                            <i className="icon">
                                                <CheckedIcon/>
                                            </i>
                                        </div>
                                        <div className="button__label">{t('selected')}</div>
                                    </button>
                                ) : (
                                    <button onClick={_selectShortTermAutoInvest}
                                            className="button button--fill button--primary button--white button--unelevated">
                                        <div className="button__label">{t('selectStrategy')}</div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                        <div
                            className={"card card--outlined card--shaped strategy-card" + (isAutoLong ? " strategy-card--selected" : "")}>
                            <div className="card__header strategy-header">
                                <h4 className="mdc-typography--headline6 card__headline">
                                    <Trans ns='app' i18nKey={'aiHighReturn'}><b/></Trans>
                                </h4>
                                {/*<span className="mdc-typography--subtitle1">from <b>7 %</b> return</span>*/}
                            </div>
                            <ul className="card__content strategy-card__content list list--vertical list--dots">
                                <li className="list-item">
                                    <span className="mdc-typography--body2">{t('aiHighReturnText1')}</span>
                                </li>
                                <li className="list-item">
                                    <span className="mdc-typography--body2">{t('aiHighReturnText3')}</span>
                                </li>
                            </ul>
                            <div className="strategy-card__stats">
                                <div className="strategy-card__stat-item">
                                        <span
                                            className="strategy-card__stat-item-title">{t('threeYearYield')}</span>
                                    <span
                                        className="strategy-card__stat-item-number">{("" + (investmentsStats?.months36?.pg?.interestRate || "0.00")).replace(".", ",")}%</span>
                                </div>
                                <div className="strategy-card__stat-item">
                                        <span
                                            className="strategy-card__stat-item-title">{t('oneYearYield')}</span>
                                    <span
                                        className="strategy-card__stat-item-number">{("" + (investmentsStats?.months12?.pg?.interestRate || "0.00")).replace(".", ",")}%</span>
                                </div>
                            </div>
                            <div className="card__footer">
                                {isAutoLong ? (
                                    <button onClick={_cancelAutoInvest}
                                            className="button button--fill button--green button--unelevated button--prefix-icon">
                                        <div className="button__icon">
                                            <i className="icon">
                                                <CheckedIcon/>
                                            </i>
                                        </div>
                                        <div className="button__label">{t('selected')}</div>
                                    </button>
                                ) : (
                                    <button onClick={_selectLongTermAutoInvest}
                                            className="button button--fill button--primary button--white button--unelevated">
                                        <div className="button__label">{t('selectStrategy')}</div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                        <div
                            className={"card card--outlined card--shaped strategy-card" + (isCustom ? " strategy-card--selected" : "")}>
                            <div className="card__header strategy-header">
                                <h4 className="mdc-typography--headline6 card__headline">
                                    <Trans ns='app' i18nKey={'aiCustom'}><b/></Trans>
                                </h4>
                            </div>
                            <ul className="card__content strategy-card__content list list--vertical list--dots">
                                <li className="list-item">
                                    <span className="mdc-typography--body2">{t('customText1')}</span>
                                </li>
                                <li className="list-item">
                                    <span className="mdc-typography--body2">{t('customText2')}</span>
                                </li>
                                <li className="list-item">
                                    <span className="mdc-typography--body2">{t('customText3')}</span>
                                </li>
                                <li className="list-item">
                                    <span className="mdc-typography--body2">{t('customText4')}</span>
                                </li>
                            </ul>
                            <div className="card__footer">
                                {isCustom ? (
                                    <Link to="/u/custom-invest-strategy"
                                          className="button button--fill button--green button--unelevated button--prefix-icon">
                                        <div className="button__icon">
                                            <i className="icon">
                                                <CheckedIcon/>
                                            </i>
                                        </div>
                                        <div className="button__label">{t('selected')}</div>
                                    </Link>
                                ) : (
                                    <Link to="/u/custom-invest-strategy"
                                          className="button button--fill button--primary button--white button--unelevated">
                                        <div className="button__label">{t('selectStrategy')}</div>
                                    </Link>
                                )}

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <StrategyConfirmDialog open={open} onClose={dialogHandleClose} options={dialogOptions}/>
        </div>
    )
}


export default connect(mapStateToProps)(withTranslation("app")(AppAutoInvest));
