import React, {Component} from "react";
import {MDCChipSet} from '@material/chips';
import {MDCSelect} from '@material/select';
import {MDCSlider} from '@material/slider';
import AutoInvestBanner from "../components/AutoInvestBanner";
import {connect} from "react-redux";
import {cancelAutoInvest, getAutoInvests, selectCustomAutoInvest} from "../actions/autoinvest";
import {Redirect} from "react-router-dom";
import {Trans, withTranslation} from "react-i18next";
import {ReactComponent as InfoBtn} from "../images/round-info-button.svg";
import {ReactComponent as CloseBtn} from "../images/baseline-close-24px.svg";

const mapStateToProps = state => {
    return {_userAutoInvest: state.autoinvest.userAutoInvest};
};

class AppCustomAutoInvest extends Component {
    state = {
        goBack: false
    };

    render() {
        const {t, _userAutoInvest} = this.props;
        if (this.state.goBack) {
            return <Redirect to='/u/auto-invest-strategies'/>
        }
        return (
            <div>
                <AutoInvestBanner/>
                <div className="mdc-layout-grid mdc-layout-grid--big-gutter custom-form-layout">
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                            <h2 className="mdc-typography--headline5 title title--border">{t('customInvestmentTitle')}</h2>
                            {/*<i className="title-spacer"/>*/}
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                            <div ref="card" className="card card--flip card--fit card--fill-parent">
                                <div className="card__surface">
                                    <div className="table-fixed">
                                        <label className="form-field">
                                            <span className="form-field__label custom-label mdc-typography--body">{t('ciInput1')}</span>
                                            <div className="action-field action-field--with-prefix">
                                                <i className="action-field__prefix">€</i>
                                                <input type="number"
                                                       ref="preferredAmount"
                                                       className="action-field__input"
                                                       step=".01"
                                                       placeholder={t('ciInput1Placeholder')}/>
                                                <button ref="infoBtn" className="icon-button icon-button--dense card__info-btn">
                                                    <InfoBtn/>
                                                </button>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="card__back surface--with-text">
                                        <h4 className="mdc-typography--subtitle2 surface__title">{t('info')}</h4>
                                        <div className="card__back-scroll-content">
                                            <p className="mdc-typography--body2">
                                                <Trans ns="app" i18nKey="ciAmountInfo"><br/></Trans>
                                            </p>
                                        </div>
                                        <div className="card__more">
                                            <button ref="closeBtn" className="icon-button icon-button--dense icon-button--untouched card__info-btn">
                                                <CloseBtn/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                            <label className="form-field">
                                <span className="form-field__label custom-label mdc-typography--body">{t('ciInput2')}</span>
                                <div ref="chips" className="mdc-chip-set mdc-chip-set--filter">
                                    <div className="mdc-chip" id="30">
                                        <div className="mdc-chip__checkmark">
                                            <svg className="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                                                <path className="mdc-chip__checkmark-path" fill="none" stroke="black"
                                                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                            </svg>
                                        </div>
                                        <div className="mdc-chip__text">1 &ndash; 30 {t('days')}</div>
                                    </div>
                                    <div className="mdc-chip" id="60">
                                        <div className="mdc-chip__checkmark">
                                            <svg className="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                                                <path className="mdc-chip__checkmark-path" fill="none" stroke="black"
                                                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                            </svg>
                                        </div>
                                        <div className="mdc-chip__text">31 &ndash; 60 {t('days')}</div>
                                    </div>
                                    <div className="mdc-chip" id="90">
                                        <div className="mdc-chip__checkmark">
                                            <svg className="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                                                <path className="mdc-chip__checkmark-path" fill="none" stroke="black"
                                                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                            </svg>
                                        </div>
                                        <div className="mdc-chip__text">61 &ndash; 90 {t('days')}</div>
                                    </div>
                                    <div className="mdc-chip" id="180">
                                        <div className="mdc-chip__checkmark">
                                            <svg className="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                                                <path className="mdc-chip__checkmark-path" fill="none" stroke="black"
                                                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                            </svg>
                                        </div>
                                        <div className="mdc-chip__text">90+ {t('days')}</div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                            <label className="form-field">
                                <span className="form-field__label custom-label mdc-typography--body">{t('ciInput3')}</span>
                                <div ref="loanType" className="mdc-chip-set mdc-chip-set--filter">
                                    <div className="mdc-chip" id="INVOICE_FACTORING">
                                        <div className="mdc-chip__checkmark">
                                            <svg className="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                                                <path className="mdc-chip__checkmark-path" fill="none" stroke="black"
                                                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                            </svg>
                                        </div>
                                        <div className="mdc-chip__text">{t('invoiceFactoring')}</div>
                                    </div>
                                    <div className="mdc-chip" id="REVERSE_FACTORING">
                                        <div className="mdc-chip__checkmark">
                                            <svg className="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                                                <path className="mdc-chip__checkmark-path" fill="none" stroke="black"
                                                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                            </svg>
                                        </div>
                                        <div className="mdc-chip__text">{t('reverseFactoring')}</div>
                                    </div>
                                    <div className="mdc-chip" id="INTERNATIONAL_FACTORING">
                                        <div className="mdc-chip__checkmark">
                                            <svg className="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                                                <path className="mdc-chip__checkmark-path" fill="none" stroke="black"
                                                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                            </svg>
                                        </div>
                                        <div className="mdc-chip__text">{t('internationalFactoring')}</div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                            <label className="form-field">
                                <span className="form-field__label custom-label mdc-typography--body">{t('ciInput4')}</span>
                                <div className="mdc-slider-wrapper">
                                    <div className="mdc-slider-pair">
                                        <div ref="slider1"
                                             className="mdc-slider mdc-slider--discrete mdc-slider-pair__slider"
                                             tabIndex="0" role="slider"
                                             aria-valuemin="0" aria-valuemax="15" aria-valuenow="1"
                                             aria-label="Select Value">

                                            <div className="mdc-slider__thumb-container">
                                                <div className="mdc-slider__pin">
                                                    <span className="mdc-slider__pin-value-marker"/>
                                                </div>
                                                <svg className="mdc-slider__thumb" width="21" height="21">
                                                    <circle cx="10.5" cy="10.5" r="7.875"/>
                                                </svg>
                                                <div className="mdc-slider__focus-ring"/>
                                            </div>
                                            <div className="mdc-slider__track-container">
                                                <div className="mdc-slider__track"/>
                                            </div>
                                        </div>
                                        <div ref="slider2"
                                             className="mdc-slider mdc-slider--discrete mdc-slider-pair__slider"
                                             tabIndex="0" role="slider"
                                             aria-valuemin="0" aria-valuemax="15" aria-valuenow="14"
                                             aria-label="Select Value">
                                            <div className="mdc-slider__track-container">
                                                <div className="mdc-slider__track"/>
                                            </div>
                                            <div className="mdc-slider__thumb-container">
                                                <div className="mdc-slider__pin">
                                                    <span className="mdc-slider__pin-value-marker"/>
                                                </div>
                                                <svg className="mdc-slider__thumb" width="21" height="21">
                                                    <circle cx="10.5" cy="10.5" r="7.875"/>
                                                </svg>
                                                <div className="mdc-slider__focus-ring"/>
                                            </div>
                                        </div>
                                        <span ref="sliderValue" className="mdc-slider__range mdc-typography--body1">1 - 14 %</span>
                                    </div>

                                </div>
                            </label>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                            <label className="form-field">
                                <span className="form-field__label custom-label mdc-typography--body">{t('ciInput5')}</span>
                                <div ref="country" className="mdc-select mdc-select--outlined">
                                    <input type="hidden" name="enhanced-select"/>
                                    <i className="mdc-select__dropdown-icon"/>
                                    <div className="mdc-select__selected-text"/>
                                    <div className="mdc-select__menu mdc-menu mdc-menu-surface demo-width-class">
                                        <ul className="mdc-list">
                                            {/*<li className="mdc-list-item mdc-list-item--selected mdc-list-item--disabled" data-value=""
                                                aria-selected="true"/>*/}
                                            <li className="mdc-list-item" data-value="">
                                                {t('countryAll')}
                                            </li>
                                            <li className="mdc-list-divider"/>
                                            <li className="mdc-list-item" data-value="AT">
                                                {t('countryAustria')}
                                            </li>
                                            <li className="mdc-list-divider"/>
                                            <li className="mdc-list-item" data-value="DE">
                                                {t('countryGermany')}
                                            </li>
                                            <li className="mdc-list-divider"/>
                                            <li className="mdc-list-item" data-value="SI">
                                                {t('countrySlovenia')}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                            <label className="form-field">
                                <span className="form-field__label custom-label mdc-typography--body">{t('ciInput6')}</span>
                                <div ref="security" className="mdc-chip-set mdc-chip-set--filter">
                                    <div className="mdc-chip" id="PG">
                                        <div className="mdc-chip__checkmark">
                                            <svg className="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                                                <path className="mdc-chip__checkmark-path" fill="none" stroke="black"
                                                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                            </svg>
                                        </div>
                                        <div className="mdc-chip__text">PG</div>
                                    </div>
                                    <div className="mdc-chip" id="BG">
                                        <div className="mdc-chip__checkmark">
                                            <svg className="mdc-chip__checkmark-svg" viewBox="-2 -3 30 30">
                                                <path className="mdc-chip__checkmark-path" fill="none" stroke="black"
                                                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                            </svg>
                                        </div>
                                        <div className="mdc-chip__text">BG</div>
                                    </div>
                                </div>
                            </label>
                            <div className="flex flex--justify-end">
                                <button type="button" className="button" onClick={this._onCancelClick.bind(this)}>{t(_userAutoInvest && _userAutoInvest.type === "CUSTOM" ? "cancelStrategy" : "goBack")}</button>
                                <button className="button button--unelevated button--green button--fill"
                                        onClick={this._selectCustomAutoInvest.bind(this)}>{t('ciConfirm')}</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _onCancelClick() {
        const {_userAutoInvest} = this.props;
        if(_userAutoInvest && _userAutoInvest.type === "CUSTOM") {
            // cancel strategy
            this.props.dispatch(cancelAutoInvest(() => {
                this.props.dispatch(getAutoInvests());
            }))
        } else {
            // navigate back
            this.setState({goBack: true})
        }

    }

    _selectCustomAutoInvest() {
        //this.props.dispatch(selectAutoInvest());
        const type = "CUSTOM", _termDays = this.chipSet.selectedChipIds || [], loanTypes = this.loanType.selectedChipIds,
            protectionTypes = this.security.selectedChipIds, from = this.minRange.value, to = this.maxRange.value,
            interestRateRange = {from, to}, country = this.country.value, preferredAmount = parseFloat(this.refs.preferredAmount.value);

        const termDays = [];
        _termDays.forEach(d => termDays.push(parseInt(d)));

        const data = {type, preferredAmount, termDays, loanTypes, protectionTypes, interestRateRange, countryCodes: [country]};

        this.props.dispatch(selectCustomAutoInvest(data, ok => {
            this.setState({goBack: true})
        }))
    }

    componentDidMount() {
        this.chipSet = new MDCChipSet(this.refs.chips);
        this.loanType = new MDCChipSet(this.refs.loanType);
        this.security = new MDCChipSet(this.refs.security);
        this.country = new MDCSelect(this.refs.country);
        const slider1 = new MDCSlider(this.refs.slider1);
        const slider2 = new MDCSlider(this.refs.slider2);
        this.minRange = slider1;
        this.maxRange = slider2;

        const {_userAutoInvest} = this.props;
        this.refs.preferredAmount.value = _userAutoInvest?.preferredAmount || '';
        this.chipSet.chips.forEach(i => i.selected = _userAutoInvest?.termDays?.includes(parseInt(i.id)));
        this.loanType.chips.forEach(i => i.selected = _userAutoInvest?.loanTypes?.includes(i.id));
        this.security.chips.forEach(i => i.selected = _userAutoInvest?.protectionTypes?.includes(i.id));
        this.country.value = _userAutoInvest?.countryCodes?.[0] || '';
        slider1.value = _userAutoInvest?.interestRateRange?.from || 1;
        slider2.value = _userAutoInvest?.interestRateRange?.to || 14;

        let slider1Value = slider1.value, slider2Value = slider2.value, slider1max = slider2Value,
            slider2min = slider1Value;
        this._sliderChange1 = e => {
            slider1Value = e.detail.value;
            slider2min = slider1Value;
            if (slider1Value >= slider1max) {
                slider1.value = slider1max - 1;
            }
            this.refs.sliderValue.textContent = slider1Value.toString() + " - " + slider2Value.toString() + " %"
        };
        this._sliderChange2 = e => {
            slider2Value = e.detail.value;
            slider1max = slider2Value;
            if (slider2Value <= slider2min) {
                slider2.value = slider2min + 1;
            }
            this.refs.sliderValue.textContent = slider1Value.toString() + " - " + slider2Value.toString() + " %"
        };
        this.refs.sliderValue.textContent = slider1Value.toString() + " - " + slider2Value.toString() + " %";

        this.refs.slider1.addEventListener("MDCSlider:input", this._sliderChange1);
        this.refs.slider1.addEventListener("MDCSlider:change", this._sliderChange1);
        this.refs.slider2.addEventListener("MDCSlider:input", this._sliderChange2);
        this.refs.slider2.addEventListener("MDCSlider:change", this._sliderChange2);

        // card flipping
        this._infoListener = e => {
            this.refs.card.classList.toggle("card--is-flipped")
        };
        this._closeListener = e => {
            this.refs.card.classList.toggle("card--is-flipped")
        };
        this.refs.infoBtn.addEventListener('click', this._infoListener);
        this.refs.closeBtn.addEventListener('click', this._closeListener);

        this.props.dispatch(getAutoInvests());
    }

    componentWillUnmount() {
        if(this.refs.slider1) {
            this.refs.slider1.removeEventListener("MDCSlider:input", this._sliderChange1);
            this.refs.slider1.removeEventListener("MDCSlider:change", this._sliderChange1);
            this.refs.slider2.removeEventListener("MDCSlider:input", this._sliderChange2);
            this.refs.slider2.removeEventListener("MDCSlider:change", this._sliderChange2);
        }
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppCustomAutoInvest));