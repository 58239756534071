import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";

export const GET_ACCOUNT_BALANCE = 'GET_ACCOUNT_BALANCE';

export const getAccountBalance = () => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, userId = state.user.currentUser.Id;

    fetch(API_URL + '/accountBalance', {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(accountBalance => {
        dispatch({
            type: GET_ACCOUNT_BALANCE,
            accountBalance
        })
    }).catch(err => {
        console.log(err);

        dispatch({
            type: GET_ACCOUNT_BALANCE,
            accountBalance: {}
        })
    })
};