import React, {Component} from "react";
import {connect} from "react-redux";
import {downloadUserInvestmentContract, getAllUserInvestments} from "../actions/investment";
import {ReactComponent as PGIcon} from "../images/pg-icon.svg";
import {ReactComponent as BGIcon} from "../images/bg-icon.svg";
import {ReactComponent as PDFIcon} from "../images/pdf-file.svg";
import {Link, Redirect} from "react-router-dom";
import {formatMoney, isMobile} from "../components/utils";
import DepositBanner from "../components/DepositBanner";
import {Trans, withTranslation} from "react-i18next";
import {ReactComponent as ArrowRight} from "../images/arrow-right.svg";
import {setActiveTab} from "../actions/tabs";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {getCountryIcon} from "../components/utils";

const mapStateToProps = (state) => {
    return {
        userInvestments: state.investment.userInvestments,
        userInvestmentIds: state.investment.userInvestmentIds,
        atEnd: state.investment.atEnd,
        direction: state.investment.direction,
        sort: state.investment.sort,
        limit: state.investment.limit,
        offset: state.investment.offset,
        _activeTab: state.tabs.tab,
    };
};

class AppMyInvestments extends Component {
    state = {};

    render() {
        let {userInvestmentIds = [], userInvestments, sort, direction, atEnd, t} = this.props,
            showSortIcon = (
                <div>
                    {direction === 'asc' ? (
                        <ArrowUpwardIcon className="sort-header__icon"/>
                    ) : (
                        <ArrowDownwardIcon className="sort-header__icon"/>
                    )}
                </div>
            );

        return (
            <section className={this.props._activeTab === 0 ? "page page--prev active" : "page page--prev not-active"}>
                <div className="page__inner">
                    <div>
                        <div className="hide-on-mobile">
                            {userInvestmentIds.length > 0 ? null : <DepositBanner/>}
                        </div>
                        <div className="mdc-layout-grid">
                            <div className="mdc-layout-grid__inner">
                                <div
                                    className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-tablet">
                                    <div className="table-header">
                                        <div className="table-header__title">
                                            <h2 className="mdc-typography--headline5 title title--border table-header__title-text">
                                                {t("myInvestmentsTitle")}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table id="sortable-table" className="sortable table table--no-wrap">
                                            <thead>
                                            <tr className="mdc-typography--body2 table__head-row">
                                                <th className="no-sort"/>
                                                <th className="no-sort hide-on-mobile">{t("status")}</th>
                                                <th onClick={() => this.sortTable("id")}
                                                    className={sort === "id" ? direction === "asc" ? "sort-header sort-header--asc" : "sort-header sort-header--desc" : "sort-header sort-header--unsorted"}>
                                                    <span className="sort-header__text">
                                                        {t("id")}
                                                        <span className="sort-header__icon">
                                                            <ArrowUpwardIcon className="sort-header__icon-asc"/>
                                                            <ArrowDownwardIcon className="sort-header__icon-desc"/>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th className="no-sort hide-on-mobile"/>
                                                <th className="no-sort hide-on-mobile">{t("loanOriginator")}</th>
                                                <th className="no-sort hide-on-mobile">{t("loanType")}</th>
                                                <th onClick={() => this.sortTable("amount")}
                                                    className={sort === "amount" ? direction === "asc" ? "sort-header sort-header--asc" : "sort-header sort-header--desc" : "sort-header sort-header--unsorted"}>
                                                    <span className="sort-header__text">
                                                        {t("amount")}
                                                        <span className="sort-header__icon">
                                                            <ArrowUpwardIcon className="sort-header__icon-asc"/>
                                                            <ArrowDownwardIcon className="sort-header__icon-desc"/>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th onClick={() => this.sortTable("issued")}
                                                    className={sort === "issued" ? direction === "asc" ? "hide-on-mobile sort-header sort-header--asc" : "hide-on-mobile sort-header sort-header--desc" : "hide-on-mobile sort-header sort-header--unsorted"}>
                                                    <span className="sort-header__text">
                                                        {t("issued")}
                                                        <span className="sort-header__icon">
                                                            <ArrowUpwardIcon className="sort-header__icon-asc"/>
                                                            <ArrowDownwardIcon className="sort-header__icon-desc"/>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th onClick={() => this.sortTable("matured")}
                                                    className={sort === "matured" ? direction === "asc" ? "sort-header sort-header--asc" : "sort-header sort-header--desc" : "sort-header sort-header--unsorted"}>
                                                    <span className="sort-header__text">
                                                        {t("maturityDate")}
                                                        <span className="sort-header__icon">
                                                            <ArrowUpwardIcon className="sort-header__icon-asc"/>
                                                            <ArrowDownwardIcon className="sort-header__icon-desc"/>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th onClick={() => this.sortTable("ended")}
                                                    className={sort === "ended" ? direction === "asc" ? "sort-header sort-header--asc" : "sort-header sort-header--desc" : "sort-header sort-header--unsorted"}>
                                                    <span className="sort-header__text">
                                                        {t("endDate")}
                                                        <span className="sort-header__icon">
                                                            <ArrowUpwardIcon className="sort-header__icon-asc"/>
                                                            <ArrowDownwardIcon className="sort-header__icon-desc"/>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th className="no-sort">{t("protection")}</th>
                                                <th className="no-sort hide-on-mobile"/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {userInvestmentIds.map((key, index) => {
                                                let investment = userInvestments[key];
                                                if (!investment || !investment.Id) return null;
                                                const id = investment.Id,
                                                    active = investment.active,
                                                    countryIcon = getCountryIcon(investment.investment.originator.countryCode);
                                                return (
                                                    <tr className="mdc-typography--body1" key={index}
                                                        onClick={this.onRowClick.bind(this, id)}>
                                                        <td className={"bullet " + (active ? "orange" : "white")}/>
                                                        <td className="hide-on-mobile">
                                                            <Trans i18nKey={active ? "ACTIVE" : "DONE"} ns={"app"}/>
                                                        </td>
                                                        <td>{investment.investment.number}</td>
                                                        <td className="cell-flag hide-on-mobile">
                                                            {countryIcon ? <img src={countryIcon} alt="" /> : null}
                                                        </td>
                                                        <td className="bold hide-on-mobile">
                                                            {investment.investment.originator !== null ? investment.investment.originator.name : ""}
                                                        </td>
                                                        <td className="hide-on-mobile">{t(investment.investment.type)}</td>
                                                        <td className="table-number bold">{formatMoney(investment.amount)} €</td>
                                                        <td className="hide-on-mobile">{investment.dateStartFormatted}</td>
                                                        <td>{investment.investment.dateEndFormatted}</td>
                                                        <td>{investment.dateEndFormatted}</td>
                                                        <td>
                                                            {investment.investment.protection === "PG" ? (
                                                                <PGIcon/>
                                                            ) : investment.investment.protection === "BG" ? (
                                                                <BGIcon/>
                                                            ) : (
                                                                investment.investment.protection || t("N/A")
                                                            )}
                                                        </td>
                                                        <td className="hide-on-mobile" align="right">
                                                            <div className="flex">
                                                                <div
                                                                    className="hide-on-mobile icon-button"
                                                                    onClick={this._downloadContract.bind(this, id)}
                                                                >
                                                                    <PDFIcon/>
                                                                </div>
                                                                <Link
                                                                    to={"/u/my-investments/" + investment.Id}
                                                                    className="button button--unelevated button-invest button-invest--green"
                                                                >
                                                                    {t("view")}
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div
                                    className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 flex flex--justify-center">
                                    <button
                                        type="button"
                                        onClick={this._loadMore.bind(this)}
                                        className="button button--unelevated"
                                        hidden={atEnd}>
                                        {t("loadMore")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {this.state.redirect ? <Redirect push to={"/u/my-investments/" + this.state.redirect}/> :
                            <div/>}
                    </div>
                    <div className="custom-navigation-wrapper" ref="navBtn">
                        <div className="custom-navigation">
                            <div className="custom-navigation__text">
                                <span>{t("accountSummaryTitle")}</span>
                            </div>
                            <div className="custom-navigation__icon">
                                <ArrowRight/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    sortTable = (columnName) => {
        this.props.dispatch(getAllUserInvestments({
            sort: columnName,
            direction: this.props.sort === columnName ? (this.props.direction === "asc" ? "desc" : "asc") : "asc"
        }));
    };

    _loadMore() {
        this.props.dispatch(getAllUserInvestments({
            offset: this.props.offset + 1,
            sort: this.props.sort,
            direction: this.props.direction
        }));
    }

    onRowClick(id) {
        if (isMobile()) {
            this.setState({redirect: id});
        }
    }

    _downloadContract(id) {
        this.props.dispatch(downloadUserInvestmentContract(id));
    }

    componentDidMount() {
        this.refs.navBtn.addEventListener("click", () => {
            this.props.dispatch(setActiveTab(1));
        });

        this.props.dispatch(getAllUserInvestments());
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppMyInvestments));
