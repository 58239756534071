import React, {Component} from "react";
import {activeUserInvestmentSelector} from "../reducers/investment";
import {
    cancelUserInvestment,
    downloadUserInvestmentContract,
    newInvestment,
    setActiveUserInvestment
} from "../actions/investment";
import {connect} from "react-redux";
import {ReactComponent as PGIcon} from "../images/pg-icon.svg";
import {ReactComponent as BGIcon} from "../images/bg-icon.svg";
import {formatMoney} from "../components/utils";
import Chart from "react-google-charts";
import {ReactComponent as CloseBtn} from "../images/baseline-close-24px.svg";
import {ReactComponent as InfoBtn} from "../images/round-info-button.svg";
import {Trans, withTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";

const mapStateToProps = state => {
    return {
        investment: activeUserInvestmentSelector(state) || {},
        success: state.investment.success,
        error: state.investment.error,
    };
};

class AppMyInvestment extends Component {
    state = {
        chartWidth: 0,
        redirectBack: false,
    };

    onInvest(e) {
        e.preventDefault();
        const value = parseFloat(this.refs.investInput.value) || 0.0;
        this.props.dispatch(newInvestment(this.props.investment.Id, value));
    }

    render() {
        const {investment, t} = this.props, {chartWidth, redirectBack} = this.state,
            hasLoaded = !!investment.investment,
            detail = investment ? (investment.investment || {}) : {}, {originator = {deals: {}}} = detail || {};
        return (
            <div>
                {redirectBack && (<Redirect to="/u/my-investments"/>)}
                <div className="banner">
                    <div className="banner__row">
                        <div className="banner__content">
                            <h2 className="mdc-typography--headline5 banner__content-title">{t('myInvestmentInfoText')}</h2>
                        </div>
                        <button className="button button--unelevated button--green"
                                onClick={this._downloadContract.bind(this)}>{t('downloadPDF')}
                        </button>
                        {investment.cancelable === true && investment.active === true ? (
                            <button className="button button--unelevated button--white"
                                    onClick={this._cancelInvestment.bind(this)}>{t('cancelInvestment')}
                            </button>
                        ) : (
                            <button className="button button--unelevated button--white"
                                    disabled hidden>Cancel Investment
                            </button>
                        )}
                    </div>
                </div>
                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner">
                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-tablet">
                            {/*<h2 className="mdc-typography--headline5 title title--border">Investment details</h2>*/}
                            <i className="title-spacer"/>
                            <div className="list list--vertical list--ordinal-background">
                                <div className="title">
                                    <span className="">{t('loanDataTitle')}</span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('loanType')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t(detail.type || t("N/A"))}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('loanAmount')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{formatMoney(detail.totalAmount) || '0'} €</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('amount')}</b></span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{formatMoney(investment.amount) || '0'} €</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">{t('roiPA')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{formatMoney(investment.autoInvestData ? investment.autoInvestData.interestRate : detail.interestRate) || '0'} %</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('roi')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{detail.rank || t("N/A")}</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">{t('dateOfIssue')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{detail.dateStartFormatted}</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">{t('investmentMaturityDate')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{detail.dateEndFormatted}</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">{t('endDate')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{investment.dateEndFormatted}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('protection')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item custom-account-list-item--with-icon">{detail.protection === "PG" ?
                                        <PGIcon/> : detail.protection === "BG" ? <BGIcon/> : (detail.protection || t("N/A"))}</span>
                                </div>
                            </div>
                        </div>

                        <div
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-tablet">
                            {/*<span className="title "/>*/}
                            <i className="title-spacer"/>
                            <div className="list list--vertical list--ordinal-background">
                                <div className="title">
                                    <span className="">{t('debtorData')}</span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('country')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{originator !== null ? originator.countryCode || t("N/A")  : t("N/A")}</span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('partnerSince')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{originator !== null ? originator.partnerSince || t("N/A")  : t("N/A")}</span>
                                </div>
                                {/* <div className="list-item list-item--no-background">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                </div>*/}
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('failureScore')}</span>
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item"><b
                                        className="custom-account-list-item__ranking">{originator !== null ? originator.creditRating || t("N/A")  : t("N/A")}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('neksterRanking')}</span>
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item"><b
                                        className="custom-account-list-item__ranking">{originator !== null ? originator.creditRating || t("N/A")  : t("N/A")}</b></span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item">{t('totalNOLoanDeals')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{originator !== null ? (originator.deals ? originator.deals.totalCount : "0") : t("N/A")}</b>&nbsp;({originator !== null ? (originator.deals ? originator.deals.activeCount : "0") : ''} {t("activeNo")})</span>
                                </div>
                                <div className="list-item">
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('totalValLoanDeals')}</b></span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{originator !== null ? (formatMoney(originator.deals ? originator.deals.totalValue : 0) + ' €') : 'N/A'}</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">{t('companySize')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{originator !== null ? originator.companySize || "N/A" : 'N/A'}</b></span>
                                </div>
                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">{t('NOEmployees')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{originator !== null ? originator.noOfEmployees || "N/A"  : 'N/A'}</b></span>
                                </div>

                                <div className="list-item">
                                    <span className="mdc-typography--body1 list-item__text custom-account-list-item">{t('totalIncome')}</span>
                                    <span
                                        className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{originator !== null ? originator.totalIncome || "N/A"  : 'N/A'}</b></span>
                                </div>
                            </div>
                        </div>

                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-tablet">
                            <i className="title-spacer"/>
                            <div className="chart-container">
                                <div ref="card" className="card card--flip">
                                    <div className="card__surface">
                                        <div ref="content" className="">
                                            <div ref="chartIcon" className="chart__protection">
                                                <div className="chart__protection-icon">
                                                    {detail.protection === "PG" ?
                                                        <PGIcon/> : detail.protection === "BG" ? <BGIcon/> : (detail.protection)}
                                                </div>
                                            </div>

                                            {hasLoaded && (
                                                <Chart
                                                    width={chartWidth}
                                                    height={chartWidth}
                                                    chartType="PieChart"
                                                    loader={<div/>}
                                                    data={[
                                                        [
                                                            {type: 'string', label: "Investor"},
                                                            {type: 'number', label: "Amount"},
                                                            {type: 'string', role: 'tooltip'},
                                                        ],
                                                        ['Invested amount', detail.totalAmount - investment.amount, t("investedAmount")+'\n' + formatMoney(detail.totalAmount - investment.amount) + ' €'],
                                                        ['Available amount', investment.amount, t("availableInvestmentAmount")+'\n' + formatMoney(investment.amount) + ' €'],
                                                        /*['Own participation', ((100 * detail.totalAmount) / 90) * .1, t("ownParticipation")+'\n' + formatMoney(((100 * detail.totalAmount) / 90) * .1) + ' €'],*/
                                                    ]}
                                                    options={{
                                                        pieHole: 0.5,
                                                        legend: 'none',
                                                        colors: ['#057E9D', '#a6ce38', '#91D8F7'],
                                                        chartArea: {left: 16, top: 16, right: 16, bottom: 16},
                                                        backgroundColor: {
                                                            fill: 'transparent',
                                                        },
                                                        pieSliceBorderColor: 'transparent',
                                                        pieStartAngle: 180,
                                                        animation: {
                                                            startup: true,
                                                            duration: 500,
                                                        },
                                                    }}
                                                />
                                            )}

                                            {/*<div className="chart__legend mdc-typography--caption">
                                                <div className="chart__legend-item">
                                                    <i className="chart__legend-color" style={chartColors[0]}/>
                                                    <span className="chart__legend-text">Total amount</span>
                                                </div>
                                                <div className="chart__legend-item">
                                                    <i className="chart__legend-color" style={chartColors[1]}/>
                                                    <span className="chart__legend-text">Available amount</span>
                                                </div>
                                                <div className="chart__legend-item">
                                                    <i className="chart__legend-color" style={chartColors[2]}/>
                                                    <span className="chart__legend-text">Achilles</span>
                                                </div>
                                            </div>*/}
                                        </div>
                                        <div className={"mdc-menu-surface--anchor card__more"}>
                                            <button ref="infoBtn" className="icon-button icon-button--dense card__info-btn" onClick={this._toggleInfo.bind(this)}>
                                                <InfoBtn/>
                                            </button>
                                        </div>
                                        <div className="card__back surface--with-text">
                                            <h4 className="mdc-typography--subtitle2 surface__title">{t('graphInfo')}</h4>
                                            <div className="card__back-scroll-content">
                                                <p className="mdc-typography--body2"><Trans ns="app" i18nKey="myInvestmentInfo"><br/></Trans></p>
                                            </div>
                                            <div className="card__more">
                                                <button ref="closeBtn" onClick={this._toggleInfo.bind(this)}
                                                        className="icon-button icon-button--dense icon-button--untouched card__info-btn">
                                                    <CloseBtn/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _cancelInvestment() {
        this.props.dispatch(cancelUserInvestment(this.props.id));
    }

    _downloadContract() {
        this.props.dispatch(downloadUserInvestmentContract(this.props.id));
    }

    componentDidMount() {
        this.refs.chartIcon.style.height = this.refs.chartIcon.offsetWidth.toString() + "px";

        // chart size
        this.props.dispatch(setActiveUserInvestment(this.props.id));
        this.setState({chartWidth: this.refs.content.clientWidth});
    }

    _toggleInfo() {
        this.refs.card.classList.toggle("card--is-flipped")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.redirectBack && prevProps.investment && prevProps.investment.Id && (!this.props.investment || !this.props.investment.Id)) {
            this.setState({redirectBack: true})
        }
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppMyInvestment));
