import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";
import {download} from "../components/utils";

export const GET_ACCOUNT_HISTORY = 'GET_ACCOUNT_HISTORY';

export const getAccountHistory = ({page = 0, replace = false}) => (dispatch, getState) => {
    const state = getState(),
        authenticationHeader = state.user.authenticationHeader,
        language = state.user.currentUser.settings.language || "en",
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/accountHistory?page=' + page + "&size=20", {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Accept-Language": language.toLowerCase(),
            "Authorization": authenticationHeader,
            "userId": userId
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then((accountHistory = {}) => {
        dispatch({
            type: GET_ACCOUNT_HISTORY,
            ...accountHistory, page, replace
        })
    }).catch(err => {
        console.log(err);
        /*dispatch({
            type: GET_ACCOUNT_HISTORY
        })*/
    })
};

export const downloadIncomeStatement = ({from, to}) => (dispatch, getState) => {
    const state = getState(),
        authenticationHeader = state.user.authenticationHeader,
        language = state.user.currentUser.settings.language || "en",
        userId = state.user.currentUser.Id;

    fetch(API_URL + '/incomeStatement?from=' + from + "&to=" + to, {
        method: 'get',
        headers: {
            "Accept-Language": language.toLowerCase(),
            "Authorization": authenticationHeader,
            "userId": userId
        },
    }).then(authMiddleware(dispatch)).then(res => {
        //content-disposition: inline;filename=income_statement_xxx_xxxxxxxxxxxx.pdf
        let contentDisposition = (res.headers.get("content-disposition") || "").split("filename="),
            filename = "income-statement-" + from + "-" + to + ".pdf"
        if(contentDisposition[1]) filename = contentDisposition[1]
        return res.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            download(filename, url)
        })
    }).catch(err => {
        console.log(err);
        /*dispatch({
            type: GET_ACCOUNT_HISTORY
        })*/
    })
};
