import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";


class ManualInvestmentsBanner extends Component {
    render() {
        const {t} = this.props;
        return (
            <div className={this.props.light ? 'banner banner--custom-light' : 'banner'}>
                <div className="banner__row">
                    <h3 className="mdc-typography--headline6 banner__title">{t('manualInvestingBannerTitle')}</h3>
                    <span className="mdc-typography--body1 banner__content">{t('manualInvestingBannerText')}</span>
                    <Link to="/u/investments" className="button button--action">{t('manualInvestingBannerCTA')}</Link>
                </div>
            </div>
        );
    }

}

export default withTranslation("app")(ManualInvestmentsBanner);
