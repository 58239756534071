import React, {Component} from 'react';

class DialogChangePassword extends Component {
    render() {
        return (
            <div className="mdc-dialog__surface">
                <h2 className="mdc-dialog__title" id="my-dialog-title">Reset password</h2>
                <div className="mdc-dialog__content" id="my-dialog-content">
                    <span>We will send you a confirmation email with a link where you can change your password.</span>
                </div>
                <footer className="mdc-dialog__actions">
                    <button type="button" className="mdc-button mdc-dialog__button"
                            data-mdc-dialog-action="close">
                        <span className="mdc-button__label">Cancel</span>
                    </button>
                    <button type="button" className="mdc-button mdc-dialog__button"
                            data-mdc-dialog-action="accept">
                        <span className="mdc-button__label">Send confirmation email</span>
                    </button>
                </footer>
            </div>
        );
    }

    componentDidMount() {

    }

}

export default DialogChangePassword;
