import {createSlice} from '@reduxjs/toolkit';
import {API_URL} from "../config";

const initialState = {
    funds: null,
    interests: null,
    ratios: null,
};

export const slice = createSlice({
    name: 'stats',
    initialState,
    reducers: {
        setFunds: (state, action) => ({...state, ...action.payload}),
        setInterests: (state, action) => ({...state, ...action.payload}),
        setRatios: (state, action) => ({...state, ...action.payload}),
    }
});

// ACTIONS

export const {setFunds, setInterests, setRatios} = slice.actions;

export const fetchFunds = () => async (dispatch, getState) => {
    const state = getState()
    if(state.stats.funds) return
    fetch(API_URL + '/statistics/funds_daily_stats', {
        method: 'get',
        headers: {
            "Accept": "application/json",
            //"Authorization": authenticationHeader,
        },
    }).then(res => {
        return res.json()
    }).then(funds => {
        dispatch(setFunds({funds}))
    }).catch(err => {
        console.error(err)
    })
};

export const fetchInterests = () => async (dispatch, getState) => {
    const state = getState()
    if(state.stats.interests) return
    fetch(API_URL + '/statistics/interests_daily_stats', {
        method: 'get',
        headers: {
            "Accept": "application/json",
            //"Authorization": authenticationHeader,
        },
    }).then(res => {
        return res.json()
    }).then(interests => {
        dispatch(setInterests({interests}))
    }).catch(err => {
        console.error(err)
    })
};

export const fetchRatios = () => async (dispatch, getState) => {
    const state = getState()
    if(state.stats.ratios) return
    fetch(API_URL + '/statistics/investment_type_ratios', {
        method: 'get',
        headers: {
            "Accept": "application/json",
            //"Authorization": authenticationHeader,
        },
    }).then(res => {
        return res.json()
    }).then(ratios => {
        dispatch(setRatios({ratios}))
    }).catch(err => {
        console.error(err)
    })
};

// SELECTORS

export const getFunds = (state) => state.stats.funds;
export const getInterests = (state) => state.stats.interests;
export const getRatios = (state) => state.stats.ratios;

export default slice.reducer;
