import React, {Component} from "react";
import AutoInvestBanner from "../components/AutoInvestBanner";
import {ReactComponent as PGIcon} from "../images/pg-icon.svg";
import {ReactComponent as BGIcon} from "../images/bg-icon.svg";
import {getAllInvestments} from "../actions/investment";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {formatMoney, getCountryIcon, isMobile} from "../components/utils";
import {withTranslation} from "react-i18next";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const mapStateToProps = state => {
    return {
        investments: state.investment.investments,
        investmentIds: state.investment.investmentIds,
        limit: state.investment.limit,
        offset: state.investment.offset,
        atEnd: state.investment.atEnd,
        direction: state.investment.direction,
        sort: state.investment.sort,
    };
};

class AppInvestments extends Component {
    state = {};

    render() {
        let {investmentIds = [], investments, sort, direction, atEnd, t} = this.props,
            showSortIcon = (
                <div>
                    {direction === 'asc' ? (
                        <ArrowUpwardIcon className="sort-header__icon"/>
                    ) : (
                        <ArrowDownwardIcon className="sort-header__icon"/>
                    )}
                </div>
            );

        return (
            <div>
                <AutoInvestBanner/>

                <div className="mdc-layout-grid">
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                            <div className="table-header">
                                <div className="table-header__title">
                                    <h2 className="mdc-typography--headline5 title title--border table-header__title-text">{t('investmentsTitle')}</h2>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table ref="sortableTable" className="sortable sort table table--no-wrap">
                                    <thead>
                                    <tr className="mdc-typography--body2 table__head-row">
                                        <th data-sort-method='none' className="no-sort cell-icon"/>
                                        <th className="no-sort">{t('loanOriginator')}</th>
                                        <th className="hide-on-mobile no-sort">{t('financingType')}</th>
                                        <th onClick={() => this.sortTable("amount")}
                                            className={sort === "amount" ? direction === "asc" ? "sort-header sort-header--asc" : "sort-header sort-header--desc" : "sort-header sort-header--unsorted"}>
                                            <span className="sort-header__text">
                                                {t("amount")}
                                                <span className="sort-header__icon">
                                                    <ArrowUpwardIcon className="sort-header__icon-asc"/>
                                                    <ArrowDownwardIcon className="sort-header__icon-desc"/>
                                                </span>
                                            </span>
                                        </th>
                                        <th onClick={() => this.sortTable("rate")}
                                            className={sort === "rate" ? direction === "asc" ? "sort-header sort-header--asc" : "sort-header sort-header--desc" : "sort-header sort-header--unsorted"}>
                                            <span className="sort-header__text">
                                                {t("roiPA")}
                                                <span className="sort-header__icon">
                                                    <ArrowUpwardIcon className="sort-header__icon-asc"/>
                                                    <ArrowDownwardIcon className="sort-header__icon-desc"/>
                                                </span>
                                            </span>
                                        </th>
                                        <th className="hide-on-mobile no-sort">{t('roi')}</th>
                                        <th onClick={() => this.sortTable("date")}
                                            className={sort === "date" ? direction === "asc" ? "sort-header sort-header--asc" : "sort-header sort-header--desc" : "sort-header sort-header--unsorted"}>
                                            <span className="sort-header__text">
                                                {t("maturityDate")}
                                                <span className="sort-header__icon">
                                                    <ArrowUpwardIcon className="sort-header__icon-asc"/>
                                                    <ArrowDownwardIcon className="sort-header__icon-desc"/>
                                                </span>
                                            </span>
                                        </th>
                                        <th className="hide-on-mobile no-sort">{t('financingDuration')}</th>
                                        <th className="hide-on-mobile no-sort">{t('availableAmount')}</th>
                                        <th className="hide-on-mobile no-sort">{t('failureScore')}</th>
                                        <th className="no-sort" title="Protection">{t('protection')}</th>
                                        <th className="no-sort"/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {investmentIds.map((key) => {
                                        let investment = investments[key];
                                        let id = (investment || {}).Id;
                                        if (!id) return;
                                        const countryIcon = getCountryIcon(investment.originator.countryCode);
                                        return (
                                            <tr className="mdc-typography--body1" key={id}>
                                                <td className="cell-flag">{countryIcon ? <img src={countryIcon} alt="" /> : null}</td>
                                                <td className="bold">{investment.originator.name}</td>
                                                <td className="hide-on-mobile">{t(investment.type)}</td>
                                                <td className="table-number bold">{formatMoney(investment.totalAmount)} €</td>
                                                <td className="bold hide-on-mobile">{formatMoney(investment.interestRate)} %</td>
                                                <td className="bold hide-on-mobile">{formatMoney(investment.roi)} %</td>
                                                <td className="hide-on-mobile">{investment.dateEndFormatted}</td>
                                                <td className="hide-on-mobile">{investment.termDays} {t('days')}</td>
                                                <td className="hide-on-mobile">{formatMoney(investment.availableAmount)} €</td>
                                                <td className="bold hide-on-mobile">{investment.originator.creditRating}</td>
                                                <td>{investment.protection === "PG" ?
                                                    <PGIcon/> : investment.protection === "BG" ?
                                                        <BGIcon/> : (investment.protection || t("N/A"))}</td>
                                                <td className="" align="right">
                                                    {investment.invested ? (
                                                        <Link to={"/u/investments/" + id}
                                                              className="button button--sized button--unelevated button-invest button--primary">
                                                            {t('invested')}
                                                        </Link>
                                                    ) : (
                                                        <Link to={"/u/investments/" + id}
                                                              className="button button--sized button--unelevated button-invest button--green">
                                                            {t('invest')}
                                                        </Link>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {investmentIds.length === 0 && <tr className="mdc-typography--body1"><td className="bold" colSpan="11">{t("noInvestments")}</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 flex flex--justify-center">
                            <button type="button" onClick={this._loadMore.bind(this)}
                                    className="button button--unelevated"
                                    hidden={atEnd}>{t('loadMore')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    sortTable = (columnName) => {
        this.props.dispatch(getAllInvestments({
            sort: columnName,
            direction: this.props.sort === columnName ? (this.props.direction === "asc" ? "desc" : "asc") : "asc"
        }));
    };

    _loadMore() {
        this.props.dispatch(getAllInvestments({
            offset: this.props.offset + 1,
            sort: this.props.sort,
            direction: this.props.direction
        }));
    }

    onRowClick(id) {
        if (isMobile()) {
            this.setState({redirect: id});
        }
    }

    componentDidMount() {
        this.props.dispatch(getAllInvestments());
    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppInvestments));
