import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";

export const GET_LATEST_INVESTMENTS = 'GET_LATEST_INVESTMENTS';
export const GET_LATEST_USER_INVESTMENTS = 'GET_LATEST_USER_INVESTMENTS';

export const getLatestInvestments = () => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader;

    fetch(API_URL + '/investments/latest', {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(investments => {
        //console.log("getLatestInvestments", data);
        /*const investments = data.reduce((obj, item) => {
            obj[item.Id] = item;
            return obj
        }, {});*/
        dispatch({
            type: GET_LATEST_INVESTMENTS,
            investments
        })
    }).catch(err => {
        console.log(err);
    });
};

export const getLatestUserInvestments = () => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader;

    fetch(API_URL + '/user_investments/latest', {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(userInvestments => {
        dispatch({
            type: GET_LATEST_USER_INVESTMENTS,
            userInvestments
        })
    }).catch(err => {
        console.log(err);
    });
};