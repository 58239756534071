export const formatMoney = (amount, decimalCount = 2, decimal = ",", thousands = ".") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

export const formatDate = (millis) => {
    const date = new Date(millis);
    return date.toLocaleDateString()
};

export const isMobile = () => {
    return window.outerWidth <= 479
};
export const download = (filename, url) => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function getCountryIcon(countryCode) {
    if (countryCode === undefined) return null;

    try {
        return require(`../images/country-flags/${countryCode.toLowerCase()}.png`).default;
    } catch (e) {
        console.log(`Error loading country icon: ${countryCode}.png`);
        return null;
    }
}
