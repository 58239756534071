import React, {Suspense, useEffect} from 'react';
import './i18n';
import './styles/global.scss';
import Register from "./pages/Register";

import {connect, useDispatch} from "react-redux";
import App from "./pages/App";
import {reauthUser} from "./actions/user";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const mapStateToProps = state => {
    if (isDev) console.log(state);
    return {
        _currentUser: state.user.currentUser,
        _error: state.user.error,
    };
};

function Root(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        const ps = new URLSearchParams(window.location.search);
        dispatch(reauthUser(ps.get("token"), ps.get("uid")));
    }, [dispatch])

    return (
        <Suspense fallback="loading">
            {props._currentUser ? <App/> : props._currentUser === false ? <div/> :
                <Register error={props._error}/>}
        </Suspense>
    );
}

/*class Root extends Component {
    render() {
        return (
            <Suspense fallback="loading">
                {this.props._currentUser ? <App/> : this.props._currentUser === false ? <div/> :
                    <Register error={this.props._error}/>}
            </Suspense>
        );
    }

    componentDidMount() {


        /!*const auth = authMiddleware(this.props.dispatch);
        window.expire = () => this.props.dispatch(auth({status: 401}))*!/
    }

}*/

export default connect(mapStateToProps)(Root);
