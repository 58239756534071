import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

class Analytics extends Component {
    componentWillUpdate({location, history}) {
        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }

        if (history.action === 'PUSH' && typeof (window.ga) === 'function') {
            window.ga('send', 'pageview', location.pathname + location.search);
        }
    }

    render() {
        return null;
    }
}

export default withRouter(Analytics);
