import React, {Component} from 'react';
import {connect} from "react-redux";
import {MDCDialog} from "@material/dialog/component";
import {closeDialog} from "../../actions/dialog";
import DialogChangePassword from "./DialogChangePassword";

export const DIALOG_CHANGE_PASSWORD = 'DIALOG_CHANGE_PASSWORD';

const mapStateToProps = state => {
    return {
        _activeDialog: state.dialog.dialog,
        _onAction: state.dialog.onAction,
    };
};


class Dialog extends Component {

    _renderDialog(dialog) {
        switch (dialog) {
            case DIALOG_CHANGE_PASSWORD:
            default:
                return (<DialogChangePassword/>)
        }
    }

    render() {
        return (
            <div ref="dialog" className="mdc-dialog"
                 role="alertdialog"
                 aria-modal="true"
                 aria-labelledby="my-dialog-title"
                 aria-describedby="my-dialog-content">
                <div className="mdc-dialog__container">
                    {this._renderDialog(this.props._activeDialog)}
                </div>
                <div className="mdc-dialog__scrim"/>
            </div>
        );
    }

    componentDidMount() {
        this._dialog = new MDCDialog(this.refs.dialog);
        this._dialog.listen('MDCDialog:closed', e => {
            if(this.props._onAction) {
                this.props._onAction(e.detail.action)
            }
            this.props.dispatch(closeDialog());
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!!this.props._activeDialog !== this._dialog.isOpen) {
            this._dialog.open()
        }
    }

}

export default connect(mapStateToProps)(Dialog);
