import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export const getNotifications = () => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, userId = state.user.currentUser.Id;

    fetch(API_URL + '/notifications/' + userId, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(notifications => {
        //console.log("notifications", notifications);
        dispatch({
            type: GET_NOTIFICATIONS,
            notifications
        })
    }).catch(err => {
        console.error(err);
    });
};