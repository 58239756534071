import {GET_LATEST_INVESTMENTS, GET_LATEST_USER_INVESTMENTS} from '../actions/feeds';
import {RESET_STATE} from "../actions/root";

const INITIAL_STATE = {
    investments: [],
    userInvestments: [],
};

const feeds = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LATEST_INVESTMENTS:
            return {
                ...state,
                investments: action.investments
            };
        case GET_LATEST_USER_INVESTMENTS:
            return {
                ...state,
                userInvestments: action.userInvestments
            };
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default feeds;
