import * as storage from 'redux-storage'
import {composeWithDevTools} from 'redux-devtools-extension';
import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from 'redux-thunk';
import root from "./reducers/root";
import user from "./reducers/user";
import investment from "./reducers/investment";
import deposits from "./reducers/deposits";
import withdrawals from "./reducers/withdrawals";
import accountBalance from "./reducers/accountBalance";
import tabs from "./reducers/tabs";
import autoinvest from "./reducers/autoinvest";
import transactions from "./reducers/transactions";
import feeds from "./reducers/feeds";
import graph from "./reducers/graph";
import notifications from "./reducers/notifications";
import dialog from "./reducers/dialog";
import accountHistory from "./reducers/accountHistory";
import stats from './store/stats';

const reducer = storage.reducer(combineReducers({
    root,
    user,
    investment,
    deposits,
    withdrawals,
    stats,
    accountBalance,
    tabs,
    transactions,
    feeds,
    accountHistory,
    autoinvest,
    notifications,
    dialog,
    graph
}));


//const engine = createEngine('my-save-key');

//const middleware = storage.createMiddleware(engine);


//const createStoreWithMiddleware = applyMiddleware(middleware)(createStore);
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

/*const load = storage.createLoader(engine);
load(store)
    .then((newState) => console.log('Loaded state:', newState))
    .catch(() => console.log('Failed to load previous state'));*/

export default store;
