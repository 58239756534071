import {authMiddleware} from "../authMiddleware";
import {API_URL} from "../config";

export const GET_BALANCE_HISTORY = 'GET_BALANCE_HISTORY';
export const GET_AVAILABLE_FUNDS_HISTORY = 'GET_AVAILABLE_FUNDS_HISTORY';
export const GET_EARNINGS_HISTORY = 'GET_EARNINGS_HISTORY';
export const GET_ROI_HISTORY = 'GET_ROI_HISTORY';

export const getBalanceHistory = (balanceHistoryType = 'alltime') => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, userId = state.user.currentUser.Id;

    fetch(API_URL + '/balanceHistory?type=' + balanceHistoryType, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId,
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(balanceHistory => {
        dispatch({
            type: GET_BALANCE_HISTORY,
            balanceHistoryType, balanceHistory
        })
    }).catch(err => {

    })
};

export const getAvailableFundsHistory = (availableFundsHistoryType = 'alltime') => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, userId = state.user.currentUser.Id;

    fetch(API_URL + '/availableFundsHistory?type=' + availableFundsHistoryType, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId,
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(availableFundsHistory => {
        dispatch({
            type: GET_AVAILABLE_FUNDS_HISTORY,
            availableFundsHistoryType, availableFundsHistory
        })
    }).catch(err => {

    })
};

export const getEarningsHistory = (earningsHistoryType = 'alltime') => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, userId = state.user.currentUser.Id;

    fetch(API_URL + '/earningsHistory?type=' + earningsHistoryType, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId,
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(earningsHistory => {
        dispatch({
            type: GET_EARNINGS_HISTORY,
            earningsHistoryType, earningsHistory
        })
    }).catch(err => {

    })
};

export const getROIHistory = (roiHistoryType = 'alltime') => (dispatch, getState) => {
    const state = getState(), authenticationHeader = state.user.authenticationHeader, userId = state.user.currentUser.Id;

    fetch(API_URL + '/roiHistory?type=' + roiHistoryType, {
        method: 'get',
        headers: {
            "Accept": "application/json",
            "Authorization": authenticationHeader,
            "userId": userId,
        },
    }).then(authMiddleware(dispatch)).then(res => {
        return res.json()
    }).then(roiHistory => {
        dispatch({
            type: GET_ROI_HISTORY,
            roiHistoryType, roiHistory
        })
    }).catch(err => {

    })
};